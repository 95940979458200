import { DatePipe } from '@angular/common';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Form, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { forkJoin } from 'rxjs';
import { SortableHeaderDirective } from '../../directive/sortable.directive';
import { ProviderCreditCards } from '../../models/provider-credit-cards.model';
import { Provider } from '../../models/providers.model';
import { States } from '../../models/states.model';
import { SubscriptionAdvertisingLevelMatrix } from '../../models/subscription-advertising-level-matrix.model';
import { UserInformation } from '../../models/user-Information.model';
import { CountiesService } from '../../services/counties.service';
import { CreditCardService } from '../../services/credit-card.service';
import { ProviderCreditCardsService } from '../../services/provider-credit-cards.service';
import { ProviderOfferingsService } from '../../services/provider-offerings.service';
import { ProvidersService } from '../../services/providers.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { StatesService } from '../../services/states.service';
import { SubscriptionAdvertisingLevelMatrixService } from '../../services/subscription-advertising-level-matrix.service';
import { UserInformationService } from '../../services/user-information.service';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-credit-card-payment-information',
  templateUrl: './credit-card-payment-information.component.html',
  styleUrls: ['./credit-card-payment-information.component.scss']
})
export class CreditCardPaymentInformationComponent implements OnInit {

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  loading: boolean;
  creditCardForm: UntypedFormGroup;
  searchState: string;
  statesData: States[] = [];
  years = [];
  provider: Provider = new Provider();
  userInformation: UserInformation = new UserInformation();
  errorCode: string;

  constructor(
    private dialogRef: MatDialogRef<CreditCardPaymentInformationComponent>,
    private providersService: ProvidersService,
    private userInformationService: UserInformationService,
    private formBuilder: UntypedFormBuilder,
    private statesService: StatesService,
    private utilityService: UtilityService,
    private creditCardService: CreditCardService,
    private datePipe: DatePipe,
    private snackBarService: SnackBarService,
    private countyService: CountiesService,
    private subscriptionAdvertisingLevelMatrix: SubscriptionAdvertisingLevelMatrixService,
    private providerOfferingsService: ProviderOfferingsService,
    private providerCreditCardsService: ProviderCreditCardsService
  ) { }

  ngOnInit(): void {
    this.provider = this.providersService.provider || new Provider();
    this.userInformation = this.userInformationService.userInformation || new UserInformation();
    this.getAllFormDropdowns();
    this.creditCardForm = this.formBuilder.group({
      cardNumber: ['', [Validators.required]],
      cvvNumber: ['', [Validators.required]],
      expiryMonth: ['', [Validators.required]],
      expiryYear: ['', [Validators.required]],
      cardFirstName: ['', [Validators.required]],
      cardLastName: ['', [Validators.required]],
      cardBillingAddress: ['', [Validators.required]],
      cardBillingCity: ['', [Validators.required]],
      cardBillingState: ['', [Validators.required]],
      cardBillingZip: ['', [Validators.required]],
      cardBillingCountry: [''],
    });
  }

  getAllFormDropdowns(): void {
    this.years = this.getYearsArray();
    this.loading = true;
    this.statesService.getStates(new States()).subscribe((response) => {
      this.loading = false;
      this.statesData = this.utilityService.sortData(this.headers, 'StateDescription', 'asc', response);
    }, (error) => {
      this.loading = false;
    });
  }

  getYearsArray(): any {
    const currentYear = new Date().getFullYear();
    const years = [];
    let startYear = new Date().getFullYear() - 1;
    while (startYear <= (currentYear + 50)) {
      years.push(startYear++);
    }
    return years;
  }

  public getDetailedAddress(address: Address): void {
    address.address_components.forEach(element => {
      element.types.forEach(data => {
        switch (data) {
          case 'administrative_area_level_1':
            this.creditCardForm.controls.cardBillingState.setValue(element.short_name);
            break;
          case 'postal_code':
            this.creditCardForm.controls.cardBillingZip.setValue(element.long_name);
            break;
          case 'locality':
            this.creditCardForm.controls.cardBillingCity.setValue(element.long_name);
            break;
          case 'country':
            this.creditCardForm.controls.cardBillingCountry.setValue(element.short_name);
            break;
        }
      });
    });
    this.creditCardForm.controls.cardBillingAddress.setValue(address.name);
  }

  saveCreditCardDetails(): any {
    if (this.creditCardForm.invalid) {
      this.creditCardForm.markAllAsTouched();
      return false;
    }

    const data = { inProviderPK: this.provider.providerPK };
    this.loading = true;
    this.providerOfferingsService.getProviderOfferings(data).subscribe((result) => {
      const matrixFilter = new SubscriptionAdvertisingLevelMatrix().deserialize({
        serviceGroupKey: this.getServiceGroup(result.body),
        advertisingLevelKey: this.provider.plan,
      });
      this.subscriptionAdvertisingLevelMatrix.getSubscriptionAdvertisingLevelMatrix(matrixFilter).subscribe(res => {
        const zone = 'Zone' + this.provider.zoneKey;
        const filter = JSON.stringify(this.getCreditCardRequestJson(res[0][zone]));
        this.loading = true;
        this.creditCardService.creditCardSubscriptionRequest(filter).subscribe((response: any) => {
          this.loading = false;
          const ccResponse = response;
          if (ccResponse.authnet.AuthNet_Transactions_Sub_Create.messages.resultCode === 'Error') {
            this.snackBarService.warning('Card Subscription Failed');
            this.errorCode = ccResponse.authnet.AuthNet_Transactions_Sub_Create.messages.message[0].code;
          } else {
            this.snackBarService.success('Card Subscription successfully');
            this.errorCode = '';
            this.insertCreditCardResponse(ccResponse.authnet);
          }
        }, (error) => {
          this.loading = false;
          this.snackBarService.warning('Card Subscription Failed');
        });
      }, (error) => {
        this.loading = false;
      });
    });
  }

  getServiceGroup(serviceData): string {
    if (serviceData.some(service => service.key === '63b3f760-4167-11eb-85f5-02e544ff179e' && service.deleted === 0)) {
      return 'Tow Group';
    } else if (serviceData.some(service => service.key === '638382bb-4167-11eb-85f5-02e544ff179e' && service.deleted === 0)) {
      return 'Mobile Repair Group';
    } else {
      return 'Other Group';
    }
  }

  getCreditCardRequestJson(pricingAmount): any {
    return {
      order: {
        invoiceNumber: '',
        description: 'BreakdownNow',
      },
      customer: {
        id: this.userInformation.userInformationID.toString(),
        email: this.userInformation.email,
        phoneNumber: this.userInformation.phone,
        faxNumber: '',
      },
      creditCard: {
        cardNumber: this.creditCardForm.controls.cardNumber.value,
        expirationDate: this.creditCardForm.controls.expiryYear.value + '-' + this.creditCardForm.controls.expiryMonth.value,
        billTo: {
          firstName: this.creditCardForm.controls.cardFirstName.value,
          lastName: this.creditCardForm.controls.cardLastName.value,
          company: this.provider.companyName,
          address: this.creditCardForm.controls.cardBillingAddress.value,
          city: this.creditCardForm.controls.cardBillingCity.value,
          state: this.creditCardForm.controls.cardBillingState.value,
          zip: this.creditCardForm.controls.cardBillingZip.value,
          country: this.creditCardForm.controls.cardBillingCountry.value,
        },
      },
      subscription: {
        name: this.provider.plan,
        startDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
        amount: pricingAmount > 0 ? pricingAmount : 0,
        trialOccurrences: '0',
        trialAmount: '0.00',
      },
    };
  }

  insertCreditCardResponse(ccResponse): void {
    const cardNumer = this.creditCardForm.controls.cardNumber.value.trim();
    const filter = new ProviderCreditCards().deserialize({
      authorizeNetsubscriptionId: ccResponse.AuthNet_Transactions_Sub_Create.subscriptionId,
      deleted: false,
      expireDate: this.creditCardForm.controls.expiryYear.value + '-' + this.creditCardForm.controls.expiryMonth.value + '-01',
      insertedUserKey: this.userInformation.userInformationID.toString(),
      lastFourDigitsOfCard: cardNumer.substr(cardNumer.length - 4).toString(),
      providerKey: this.provider.providerPK,
      systemDefault: false,
      updatedUserKey: '',
    });

    this.provider.authorizeNetcustomerProfileId = ccResponse.AuthNet_Transactions_Sub_Create.profile.customerProfileId;
    this.provider.authorizeNetcustomerPaymentProfileId = ccResponse.AuthNet_Transactions_Sub_Create.profile.customerPaymentProfileId;

    const providerCCRequest = this.providerCreditCardsService.insertProviderCreditCards(filter);
    const providerRequest = this.providersService.updateProvider(this.provider);

    this.loading = true;
    forkJoin([providerRequest, providerCCRequest]).subscribe(([providerResponse, providerCCResponse]) => {
      this.loading = false;
      this.snackBarService.success(providerResponse.message);
      this.providersService.provider = this.provider;
      this.dialogRef.close();
    }, (error) => {
      this.loading = false;
    });
  }


  closeDialog(): void {
    this.dialogRef.close();
  }

  handleError = (control: string, error: string) => {
    return this.creditCardForm.controls[control].hasError(error);
  }

}
