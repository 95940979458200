import { Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { ConfirmDeleteComponent } from 'src/app/common/confirm-delete/confirm-delete.component';
import { SortableHeaderDirective, SortEvent } from '../../directive/sortable.directive';
import { Makes } from '../../models/makes.model';
import { ProviderAdditionalAddresses } from '../../models/provider-additional-addresses.model';
import { ProviderCapabilities } from '../../models/provider-capabilities.model';
import { Provider } from '../../models/providers.model';
import { MakesService } from '../../services/makes.service';
import { ProviderAdditionalAddressesService } from '../../services/provider-additional-addresses.service';
import { ProviderCapabilitiesService } from '../../services/provider-capabilities.service';
import { ProvidersService } from '../../services/providers.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { UserInformationService } from '../../services/user-information.service';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-add-provider-capabilities-makes',
  templateUrl: './add-provider-capabilities-makes.component.html',
  styleUrls: ['./add-provider-capabilities-makes.component.scss']
})
export class AddProviderCapabilitiesMakesComponent implements OnInit {

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  loading = false;
  searchString: string;
  searchCtrl: string;
  makes: Makes[] = [];
  selectedMakes: any = [];
  makesList: any = [];
  providerServiceCapabilityKey: string;
  existingMakes: any = [];
  isMakeDependent = false;
  makeNames: any = [];
  providerServiceCapabilities: ProviderCapabilities[] = [];
  provider: Provider = new Provider();
  providerAdditionalAddresses: ProviderAdditionalAddresses = new ProviderAdditionalAddresses();

  constructor(
    public dialogRef: MatDialogRef<AddProviderCapabilitiesMakesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private makesService: MakesService,
    private utilityService: UtilityService,
    private providersService: ProvidersService,
    private userInformationService: UserInformationService,
    private providerAdditionalAddressesService: ProviderAdditionalAddressesService,
    private snackBarService: SnackBarService,
    private providerCapabilitiesService: ProviderCapabilitiesService,
  ) { }

  ngOnInit(): void {
    this.provider = this.providersService.provider || new Provider();
    this.providerAdditionalAddresses = this.providerAdditionalAddressesService.providerAdditionalAddresses
      || new ProviderAdditionalAddresses();
    this.isMakeDependent = this.data.providerCapability.makeDependent;
    this.initDropdown();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  initDropdown(): void {
    this.loading = true;
    this.makesService.getMakes().subscribe(result => {
      this.makes = result;
      this.makeNames = result.map(obj => obj.MakeKey);
      this.getProviderCapabilitiesMake();
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  getProviderCapabilitiesMake(): void {
    this.providerServiceCapabilities = [];
    this.selectedMakes = [];
    this.existingMakes = [];

    const filter = new ProviderCapabilities().deserialize({
      additionalAddressPK: this.data.parent ? null : this.providerAdditionalAddresses.additionalAddressPK,
      providerPK: this.provider.providerPK,
      providerCapabilityKey: this.data.providerCapability.providerCapabilityKey
    });

    this.loading = true;
    this.providerCapabilitiesService.getProviderCapabilities(filter).subscribe(result => {
      this.loading = false;
      const providerServiceCapabilities = result.filter(x => x.makeKey != null && x.makeKey !== '');
      this.existingMakes = result.map(obj => obj.makeKey);
      this.providerServiceCapabilities = this.utilityService.sortData(this.headers, 'makeKey', 'asc', providerServiceCapabilities);
    }, (error) => {
      this.loading = false;
    });
  }

  onSort({ column, direction }: SortEvent): void {
    this.makesList = this.utilityService.sortData(this.headers, column, direction, this.makesList);
  }

  insertProviderCapabilitiesMake(): void {
    const insertUpdateRequest = [];
    this.selectedMakes.forEach((make, index) => {
      if (this.providerServiceCapabilities.length === 0 && index === 0) {
        const updateRequestData = new ProviderCapabilities().deserialize({
          providerCapabilityPK: this.data.providerCapability.providerCapabilityPK,
          makeKey: make,
          updatedUserKey: this.userInformationService.userInformation.userInformationID.toString(),
          deleted: false,
        });
        insertUpdateRequest.push(this.providerCapabilitiesService.updateProviderCapabilities(updateRequestData));
      } else {
        const insertRequestData = new ProviderCapabilities().deserialize({
          additionalAddressPK: this.data.parent ? null : this.providerAdditionalAddresses.additionalAddressPK,
          providerPK: this.provider.providerPK,
          makeKey: make,
          updatedUserKey: '',
          systemDefault: false,
          deleted: false,
          insertedUserKey: this.userInformationService.userInformation.userInformationID.toString(),
          providerCapabilityKey: this.data.providerCapability.providerCapabilityKey
        });
        insertUpdateRequest.push(this.providerCapabilitiesService.insertProviderCapabilities(insertRequestData));
      }
    });

    this.loading = true;
    forkJoin([...insertUpdateRequest]).subscribe(() => {
      this.loading = false;
      this.snackBarService.success('Capability Make inserted successfully', 'Success');
      this.getProviderCapabilitiesMake();
    }, (error) => {
      this.loading = false;
    });
  }

  deleteUpdateMake(filterKey): void {
    this.dialog.open(ConfirmDeleteComponent).afterClosed().subscribe((isDelete) => {
      if (isDelete) {
        if (this.providerServiceCapabilities.length === 1) {
          this.updateProviderCapabilityMake();
        } else {
          this.deleteProviderCapabilityMake(filterKey);
        }
      }
    });
  }

  updateProviderCapabilityMake(): void {
    const updateRequestData = new ProviderCapabilities().deserialize({
      providerCapabilityPK: this.data.providerCapability.providerCapabilityPK,
      makeKey: '',
      updatedUserKey: this.userInformationService.userInformation.userInformationID.toString(),
      deleted: false,
    });
    this.providerCapabilitiesService.updateProviderCapabilities(updateRequestData).subscribe(() => {
      this.loading = false;
      this.snackBarService.success('Capability Make deleted successfully', 'Success');
      this.getProviderCapabilitiesMake();
    }, (error) => {
      this.loading = false;
    });
  }

  deleteProviderCapabilityMake(filterKey): void {
    this.loading = true;
    this.providerCapabilitiesService.deleteProviderCapabilities(filterKey).subscribe(() => {
      this.loading = false;
      this.snackBarService.success('Capability Make deleted successfully', 'Success');
      this.getProviderCapabilitiesMake();
    }, (error) => {
      this.loading = false;
    });
  }

}
