import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CreditCardPaymentInformationComponent, ProviderAdditionalAddresses, ProviderAdditionalAddressesService, ProvidersService, SnackBarService, SubscriptionAdvertisingLevelMatrix, UserInformation, UserInformationService } from '@share';
import { forkJoin } from 'rxjs';
import { ProviderDetailsService } from 'src/app/pages/provider-setup/services/provider-details.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DifferenceInHoursBetweenTwoTimesPipe } from '../../pipes/difference-in-hours-between-two-times.pipe';
import { ProviderOfferingsService } from '../../services/provider-offerings.service';
import { SubscriptionAdvertisingLevelMatrixService } from '../../services/subscription-advertising-level-matrix.service';
import { SignUpSuccessComponent } from 'src/app/common/sign-up-success/sign-up-success.component';
import { environment } from 'src/environments/environment';
import { SortableHeaderDirective, SortEvent } from '../../directive/sortable.directive';
import { UtilityService } from '../../services/utility.service';
import { CurrencyPipe } from '@angular/common';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-application-review',
  templateUrl: './application-review.component.html',
  styleUrls: ['./application-review.component.scss']
})
export class ApplicationReviewComponent implements OnInit {

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  loading: boolean;
  panelOpenState = false;
  providers: any = [];
  provider: any = [];
  days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  providerPK: string;
  locations: any = [];
  pricingGrid: any[];
  hideColumns: boolean;
  totalMonthlyFee: number;
  userInformation: UserInformation = new UserInformation();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private providerAdditionalAddressesService: ProviderAdditionalAddressesService,
    private providersService: ProvidersService,
    private userInformationService: UserInformationService,
    private snackBarService: SnackBarService,
    private providerDetailsService: ProviderDetailsService,
    private hoursPipe: DifferenceInHoursBetweenTwoTimesPipe,
    private providerOfferingsService: ProviderOfferingsService,
    private subscriptionAdvertisingLevelMatrix: SubscriptionAdvertisingLevelMatrixService,
    private dialog: MatDialog,
    private utilityService: UtilityService,
    private currencyPipe: CurrencyPipe
  ) { }

  ngOnInit(): void {
    this.providerPK = this.providersService?.provider?.providerPK || '';
    this.userInformation = this.userInformationService.userInformation;
    this.getAllDetailsByProviderKey();
  }

  getAllDetailsByProviderKey(): void {
    if (this.providerPK) {
      const filter = {
        locationKey: null,
        providerKey: this.providerPK
      };

      this.loading = true;
      this.providerDetailsService.getAllDetailsByProviderKey(filter).subscribe(response => {
        this.loading = false;
        this.provider = response.body ? response.body[0] : [];
        this.provider.additionalAddressPK = '';
        const locations = (this.provider?.locations) ? (this.provider.locations.split('#')) : [];
        this.setProviderDetails();

        if (locations.length) {
          this.loadLocationDetails(locations);
        } else {
          this.providers.push(this.provider);
          this.getPricingGrid();
        }
      }, (error) => {
        this.loading = false;
      });
    }
  }

  setProviderDetails(): void {
    this.provider.nationalAccounts = this.provider.nationalAccounts?.split('#') || [];
    this.provider.capabilities = this.provider.providerCapabilities?.split('#') || [];
    this.provider.services = this.provider.providerOfferings?.split('#') || [];
    this.provider.electronicDispatchPhones = this.provider.providerElectronicDispatchPhoneNumbers?.split('#') || [];
    this.provider.electronicDispatchEmails = this.provider.providerElectronicDispatchEmails?.split('#') || [];
    this.provider.hrsOfOperation = this.getHrsOfOperations(this.provider.providerHoursOfOperations) || [];
  }

  loadLocationDetails(locations): void {
    const reqs = [];
    locations.forEach(locationKey => {
      const filter = {
        locationKey,
        providerKey: this.providerPK
      };

      reqs.push(this.providerDetailsService.getAllDetailsByProviderKey(filter));
    });

    if (reqs.length) {
      this.loading = true;
      forkJoin(reqs).subscribe((response: any) => {
        this.loading = false;

        const locationRes = response || [];
        locationRes.forEach((result: any) => {
          const location = result.body ? result.body[0] : [];
          this.setLocationDetails(location);
        });

        this.providers = [...[this.provider], ...this.locations];
        this.getPricingGrid();
      }, (error) => {
        this.loading = false;
      });
    }
  }

  setLocationDetails(location): void {
    if (location.additionalAddressPK) {
      this.locations.push({
        additionalAddressPK: location.additionalAddressPK,
        address1: location.address1,
        address2: location.address2,
        address3: location.address3,
        afterHoursPhone: this.provider?.useCentralDispatch ? this.provider?.afterHoursPhone : location.afterHoursPhone,
        billingEmail: this.provider?.useCentralBilling ? this.provider.billingEmail : location.billingEmail,
        billingPhone: this.provider?.useCentralBilling ? this.provider.billingPhone : location.billingPhone,
        businessHoursPhone: this.provider?.useCentralDispatch ? this.provider?.businessHoursPhone : location.businessHoursPhone,
        city: location.city,
        companyName: location.companyName,
        dispatchEmail: this.provider?.useCentralDispatch ? this.provider?.dispatchEmail : location.dispatchEmail,
        dispatchFax: this.provider?.useCentralDispatch ? this.provider?.dispatchFax : location.dispatchFax,
        postalCode: location.postalCode,
        hrsOfOperation: this.getHrsOfOperations(location.providerHoursOfOperations) || [],
        providerPK: location.providerPK,
        state: location.state,
        tollFreePhone: this.provider?.useCentralDispatch ? this.provider?.tollFreePhone : location.tollFreePhone,
        capabilities: location.providerCapabilities?.split('#') || [],
        services: location.providerOfferings?.split('#') || [],
        electronicDispatchPhones: this.getElectronicDispatchPhones(location.providerElectronicDispatchPhoneNumbers?.split('#') || []) || [],
        electronicDispatchEmails: this.getElectronicDispatchEmails(location.providerElectronicDispatchEmails?.split('#') || []) || [],
      });
    }
  }

  getHrsOfOperations(providerHoursOfOperations): any {
    const sorter = { Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6, Sunday: 7 };
    const hrsOfOperation = providerHoursOfOperations?.split('#') || [];
    const finalHrsOfOperations = [];
    hrsOfOperation.forEach(weekday => {
      const data = weekday?.split('@') || [];
      finalHrsOfOperations.push({
        daysOfWeekKey: data[0] || '',
        openTime: data[1] || '',
        closeTime: data[2] || '',
        closed: data[3] || 0,
      });
    });

    finalHrsOfOperations.sort((a, b) => {
      const day1 = a.daysOfWeekKey;
      const day2 = b.daysOfWeekKey;
      return sorter[day1] - sorter[day2];
    });

    return finalHrsOfOperations || [];
  }

  redirectToAdditionalInfo(): void {
    this.router.navigate(['../additional-information'], { relativeTo: this.route });
  }

  addProviderAdditionalAddress(): void {
    this.loading = true;
    const providerAdditionalAddresses: ProviderAdditionalAddresses = this.prepareSave();
    providerAdditionalAddresses.providerPK = this.providersService.provider.providerPK;
    this.providerAdditionalAddressesService.insertProviderAdditionalAddresses(providerAdditionalAddresses).subscribe((response) => {
      this.loading = false;
      this.snackBarService.success(response.message);
      providerAdditionalAddresses.additionalAddressPK = response[0].additionalAddressPK;
      this.providerAdditionalAddressesService.providerAdditionalAddresses = providerAdditionalAddresses;
      this.router.navigate(['../location-profile'], { relativeTo: this.route });
    }, (error) => {
      this.loading = false;
    });
  }

  prepareSave(): ProviderAdditionalAddresses {
    const providerAdditionalAddresses: ProviderAdditionalAddresses = new ProviderAdditionalAddresses();
    providerAdditionalAddresses.providerPK = this.providersService.provider.providerPK;
    providerAdditionalAddresses.insertedUserKey = this.userInformationService.userInformation.userInformationID.toString();
    providerAdditionalAddresses.updatedUserKey = '';
    providerAdditionalAddresses.deleted = false;
    providerAdditionalAddresses.systemDefault = false;
    return providerAdditionalAddresses;
  }

  getElectronicDispatchEmails(item): any {
    let electronicDispatchEmails = item.providerElectronicDispatchEmails;
    if (this.provider.useCentralDispatch) {
      electronicDispatchEmails = this.provider.electronicDispatchEmails;
    }

    return electronicDispatchEmails || [];
  }

  getElectronicDispatchPhones(item): any {
    let electronicDispatchPhones = item.providerElectronicDispatchPhoneNumbers;
    if (this.provider.useCentralDispatch) {
      electronicDispatchPhones = this.provider.electronicDispatchPhones;
    }

    return electronicDispatchPhones || [];
  }

  getPricingGrid() {
    this.pricingGrid = [];
    this.totalMonthlyFee = 0;
    this.providers.forEach(element => {
      const data = { inProviderPK: element.providerPK };
      this.loading = true;
      this.providerOfferingsService.getProviderOfferings(data).subscribe((result) => {
        const matrixFilter = new SubscriptionAdvertisingLevelMatrix().deserialize({
          serviceGroupKey: this.getServiceGroup(result.body),
          advertisingLevelKey: this.provider.plan,
        });
        this.subscriptionAdvertisingLevelMatrix.getSubscriptionAdvertisingLevelMatrix(matrixFilter).subscribe(res => {
          const zone = 'Zone' + this.provider.zoneKey;
          const price = res[0][zone];
          this.pricingGrid.push(this.getCalculatedData(element, price, this.provider.zoneKey, res[0] || []));
          this.hideColumns = this.pricingGrid.some(e => e.Annual == 0 && e.Monthly == 0);
          this.totalMonthlyFee = this.pricingGrid.reduce((acc, curr) => acc + (curr.DiscountedMonthlyTotal || 0), 0);
          this.pricingGrid = this.utilityService.sortData(this.headers, 'Zone', 'asc', this.pricingGrid);
          this.loading = false;
        }, (error) => {
          this.loading = false;
          this.snackBarService.warning('Something went wrong.');
        });
      }, (error) => {
        this.loading = false;
      });
    });
  }

  onSort({ column, direction }: SortEvent): void {
    this.pricingGrid = this.utilityService.sortData(this.headers, column, direction, this.pricingGrid);
  }

  getCalculatedData(data: any, price, zoneKey, advertisingPricingData: any): any {
    const type = data.additionalAddressPK ? 'LOC/' : 'CORP/';

    const rate = price || 0;
    const corporateDiscount = advertisingPricingData?.CorporateDiscount || 0;
    const annualSubscriptionDiscount = corporateDiscount > 0 ? 0 : advertisingPricingData?.AnnualSubscriptionDiscount || 0;
    const corporateDiscountTotal = (rate * corporateDiscount) / 100;
    const annualSubscriptionDiscountTotal = (rate * annualSubscriptionDiscount) / 100;
    const discountedMonthlyTotal = rate - annualSubscriptionDiscountTotal - corporateDiscountTotal;

    return {
      Location: `${data.address1} ${data.address2 ?? ''} ${data.address3}, ${data.city}, ${data.state}, ${data.postalCode}`,
      Zone: type + `${zoneKey ?? 'NA'}`,
      Annual: rate * 12,
      Monthly: rate,
      Discount1stYear: annualSubscriptionDiscountTotal,
      CorporateDiscount: corporateDiscount,
      DiscountedMonthlyTotal: discountedMonthlyTotal,
      AnnualSubscriptionDiscount: annualSubscriptionDiscount
    }
  }

  getServiceGroup(serviceData): string {
    if (serviceData.some(service => service.key === '63b3f760-4167-11eb-85f5-02e544ff179e' && service.deleted === 0)) {
      return 'Tow Group';
    } else if (serviceData.some(service => service.key === '638382bb-4167-11eb-85f5-02e544ff179e' && service.deleted === 0)) {
      return 'Mobile Repair Group';
    } else {
      return 'Other Group';
    }
  }

  createPdf(): void {
    const docDefinition = {
      content: [],
      styles: {
        header: {
          bold: true,
          fontSize: 14,
          color: '#aa353e',
          margin: [0, 10, 0, 10]
        },
        sectionHeader: {
          bold: true,
          color: '#aa353e',
          margin: [0, 10, 0, 10]
        },
        tableHeader: {
          bold: true,
        },
        bold: {
          bold: true
        },
        boldMargin: {
          bold: true,
          margin: [0, 10, 0, 0],
          decoration: 'underline',
          fontSize: 16
        },
        pageHeader: {
          bold: true,
          fontSize: 16,
          color: '#aa353e',
          margin: [0, 0, 0, 10],
          alignment: 'center'
        },
        customMargin: { margin: [5, 0, 0, 5], width: 'auto' },
        listInline: {
          display: 'inline'
        }
      }
    };

    const providersPdf = JSON.parse(JSON.stringify(this.providers)); // ! Prevent Original List from updating

    providersPdf.forEach((item, index) => {
      let pageBreak = index < this.providers?.length - 1 ? 'after' : 'none';
      docDefinition.content.push(
        {
          columns: [
            { text: item.companyName, width: '100%', style: 'boldMargin' },
          ]
        },

        // ! Company Details / Location Details
        this.getElementHeader(item.additionalAddressPK ? 'Location Details:' : 'Company Details:', 1),
        {
          columns: [
            { text: 'Company Name: ', width: '20%', style: 'bold' },
            { text: item.companyName, width: '80%', style: ['customMargin', 'bold'] }
          ]
        },
        {
          columns: [
            { text: item.additionalAddressPK ? 'Physical Address: ' : 'Address: ', style: 'bold', width: '20%' },
            { text: `${item.address1} ${item.address2 ?? ''} ${item.address3}, ${item.city}, ${item.state}, ${item.postalCode}`, style: 'customMargin', width: '80%' }
          ]
        },
        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }] },

        // ! Dispatch
        this.getElementHeader('Dispatch', 1),
        {
          columns: [
            { text: 'Dispatch Email: ', style: 'bold', width: '20%' },
            { text: item.dispatchEmail, style: 'customMargin', width: '80%' }
          ]
        },
        {
          columns: [
            { text: 'Toll Free Phone: ', style: 'bold', width: '20%' },
            { text: item.tollFreePhone, style: 'customMargin', width: '30%' },
            { text: 'Business Phone: ', width: '20%', style: 'bold' },
            { text: item.businessHoursPhone, width: '30%', style: 'customMargin' }
          ]
        },
        {
          columns: [
            { text: 'Dispatch Fax: ', style: 'bold', width: '20%' },
            { text: item.dispatchFax, style: 'customMargin', width: '30%' },
            { text: 'After Hours Phone: ', style: 'bold', width: '20%' },
            { text: item.afterHoursPhone, style: 'customMargin', width: '30%' }
          ]
        },
        {
          columns: [
            { text: 'Electronic Dispatch Emails', width: '50%', style: 'bold' },
            { text: 'Electronic Dispatch Phones', width: '50%', style: 'bold' },
          ]
        },
        {
          columns: [
            { ol: item.electronicDispatchEmails, margin: [0, 0, 0, 10], width: '50%' },
            { ol: item.electronicDispatchPhones, margin: [0, 0, 0, 10], width: '50%' },
          ]
        },
        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1, margin: [10, 10, 10, 0] }] },

        // ! Billing
        this.getElementHeader('Billing', 1),
        {
          columns: [
            { text: 'Do you use Central Billing: ', style: 'bold', width: '30%' },
            { text: item.useCentralBilling ? 'Yes' : 'No', style: 'customMargin', width: '20%' },
            { text: 'Billing Phone: ', style: 'bold', width: '20%' },
            { text: item.billingPhone, style: 'customMargin', width: '30%' },
          ]
        },
        {
          columns: [
            { text: 'Billing Email: ', style: 'bold', width: '15%' },
            { text: item.billingEmail, style: 'customMargin', width: '85%' }
          ]
        },
        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }] },

        // ! National Accounts
        this.getElementHeader('National Accounts', item.nationalAccounts),
        this.getListElementContent(item.nationalAccounts),
        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }] },

        // ! Description
        this.getElementHeader('Description that will display under your name in Breakdown Now search result', item.nationalAccounts),
        this.getTextElementContent(item.description),
        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }] },

        // ! Hours of Operation
        this.getElementHeader('Hours of Operation', 1),
        this.getHoursOfOperations(item.hrsOfOperation),
        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }] },

        // ! Capabilities
        this.getElementHeader('Capabilities', item.capabilities),
        this.getListElementContent(item.capabilities),
        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }] },

        // ! Services
        this.getElementHeader('Services', item.services),
        this.getListElementContent(item.services),
        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }] },

        // ! New Page
        {
          columns: [
            { text: '', pageBreak: pageBreak },
          ]
        }
      );
    });
    docDefinition.content.push(
      this.getElementHeader('ServicePlus is PCI compliant and does not store Credit Card information!', 1),
      this.addPricingDetailsToPdf(),
      {
        columns: [
          { text: `Total Monthly Fee: ${this.currencyPipe.transform(this.totalMonthlyFee.toFixed(2))}`, width: '100%', style: 'bold', alignment: 'right', margin: [0, 0, 15, 10] },
        ]
      },
      {
        columns: [
          { text: `Total Annual Fee: ${this.currencyPipe.transform((this.totalMonthlyFee * 12).toFixed(2))}`, width: '100%', style: 'bold', alignment: 'right', margin: [0, 0, 15, 10] },
        ]
      },
      { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }] },
    );
    this.downloadPdf(docDefinition);
  }

  downloadPdf(pdfContent): void {
    const date = new Date();
    pdfMake.createPdf(pdfContent).open();
  }

  getElementHeader(heading, data) {
    if (!data) return;
    return {
      columns: [
        { text: `${heading}: `, width: 'auto', style: 'sectionHeader' },
      ]
    }
  }

  getListElementContent(data) {
    if (!data) return;
    return {
      columns: [
        { ul: data || [], margin: [0, 0, 0, 10], style: 'listInline', width: '100%' }
      ]
    }
  }

  getTextElementContent(data) {
    if (!data) return;
    return {
      columns: [{ text: data, style: 'customMargin', width: '100%', margin: [10, 0, 0, 10] }]
    }
  }

  getHoursOfOperations(hoursOfOperations) {
    const tableHeader = [];
    const tableContents = [];
    this.days.forEach(day => tableHeader.push({ text: day, style: 'tableHeader' }));
    const body = hoursOfOperations.map(hour => hour.closed == "1" ? 'Closed' : this.hoursPipe.transform(hour.openTime, hour.closeTime));
    tableContents.push(tableHeader);
    tableContents.push(body);
    return {
      table: {
        headerRows: 1,
        body: tableContents
      }, width: '100', margin: [10, 0, 0, 10]
    }
  }

  getAccordionTitle(additionalAddressPK, name, index) {
    return additionalAddressPK ? `Location ${index} Info - ${name}` : `Company Info - ${name}`;
  }

  addPricingDetailsToPdf() {
    const tableHeader = [];
    const tableContents = [];
    const body = [];
    tableContents.push([
      { text: 'Locations', style: 'tableHeader' },
      { text: 'Type / Zone', style: 'tableHeader' },
      { text: 'Annual', style: 'tableHeader' },
      { text: 'Monthly', style: 'tableHeader' },
      { text: 'Discount 1st Year', style: 'tableHeader' },
      { text: 'Corporate Discount', style: 'tableHeader' },
      { text: 'Discounted Monthly Total', style: 'tableHeader' }
    ]);
    this.pricingGrid.forEach(element => {
      tableContents.push([
        element.Location,
        element.Zone,
        this.currencyPipe.transform(element.Annual),
        this.currencyPipe.transform(element.Monthly),
        element.AnnualSubscriptionDiscount > 0 ? element.AnnualSubscriptionDiscount + '%' : 0,
        element.CorporateDiscount > 0 ? element.CorporateDiscount + '%' : 0,
        this.currencyPipe.transform(element.DiscountedMonthlyTotal)
      ])
    });
    return {
      table: {
        headerRows: 1,
        body: tableContents
      }, width: '100', margin: [10, 0, 0, 10]
    }
  }

  // ? Open Credit Dialog
  openPaymentInformationDialog() {
    this.dialog.open(CreditCardPaymentInformationComponent, {
      hasBackdrop: false,
      disableClose: true,
      width: '50%',
    }).afterClosed().subscribe(() => {
      this.onSignUpSuccess();
    });
  }

  onSignUpSuccess(): void {
    const details = {
      name: this.userInformation.firstName,
      plan: this.providersService.providerPlan
    };
    this.dialog.open(SignUpSuccessComponent, {
      hasBackdrop: false,
      disableClose: true,
      data: details,
      width: '50%'
    }).afterClosed().subscribe(() => {
      this.userInformation.active = true;
      this.userInformation.updatedBy = this.userInformation.userInformationID;
      const emailody = `Logon using this link to review the application <a href='${environment.servicePlusAdminURL}'>click here</a>`;
      const adminEmailData = {
        body: emailody,
        emailTo: 'members@serviceplusnetwork.com',
        subject: 'BreakdownNOW Provider Application submitted for your review'
      };
      const updateUserReq = this.userInformationService.updateUserInformation(this.userInformation);
      const sendEmailToAdmin = this.userInformationService.sendEmailToAdmin(adminEmailData);
      this.loading = true;
      forkJoin([updateUserReq, sendEmailToAdmin]).subscribe((result) => {
        this.loading = false;
        this.snackBarService.success(result[0].message);
        this.userInformationService.userInformation = this.userInformation;
        this.providersService.providerPlan = null;
        this.router.navigate(['/pages/home/dashboard'], { relativeTo: this.route });
      }, (error) => {
        this.loading = false;
      });
    });
  }

}
