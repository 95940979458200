import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  @Input() latitude = 33.013438;
  @Input() longitude = -96.772337;

  constructor() { }

  ngOnInit(): void {
  }

}
