<ngx-loading [show]="loading"></ngx-loading>
<h2 mat-dialog-title class="text-center bgCCpopup pt-2 pb-2">
    <div class="pull-right p-1 text-danger" nbTooltip="close">
        <a class="boxclose" (click)="closeDialog()"></a>
    </div>
</h2>
<div class="modal-body">
    <div class="banner_form2">
        <form role="form" [formGroup]="creditCardForm">
            <div class="row">
                <div class="col col-12 pl-0">
                    <h6>Enter Billing Information for Advertising Level Invoices</h6>
                </div>
            </div>
            <div class="row border mt-2 p-2">
                <div class="col col-12 pl-0 font-weight-bold">
                    <label>Payment Method</label>
                </div>
                <div *ngIf="errorCode === 'E00012'" class="alert alert-warning text-center">
                    Credit/Debit Card is already Subscribed.
                </div>
                <div *ngIf="errorCode === 'E00013'" class="alert alert-warning text-center">
                    Credit/Debit Card Number is invalid.
                </div>
                <div class="col col-md-9 pl-0 mt-2">
                    <div class="input-group mb-3">
                        <input class="form-control rounded-0" type="text" placeholder="Credit/Debit Card Number"
                            formControlName="cardNumber" required>
                        <div class="input-group-append">
                            <span class="input-group-text text-danger"><i class="fa fa-credit-card fa-fw"></i></span>
                        </div>
                    </div>
                    <span class="text-danger error"
                        *ngIf="handleError('cardNumber', 'required') && creditCardForm.controls.cardNumber.touched">
                        Credit/Debit Card Number is required
                    </span>
                </div>
                <div class="col col-md-3 pl-0 mt-2">
                    <input type="password" class="form-control rounded-0" formControlName="cvvNumber"
                        placeholder="CVV number" maxlength="4" required>
                    <span class="text-danger error"
                        *ngIf="handleError('cvvNumber', 'required') && creditCardForm.controls.cvvNumber.touched">
                        CVV Number is required
                    </span>
                </div>
                <div class="col col-md-6 pl-0 mt-2">
                    <select class="form-control rounded-0" formControlName="expiryMonth" placeholder="CVV Number"
                        required>
                        <option value="" disabled>Expiration Month</option>
                        <option value="01">January</option>
                        <option value="02">February</option>
                        <option value="03">March</option>
                        <option value="04">April</option>
                        <option value="05">May</option>
                        <option value="06">June</option>
                        <option value="07">July</option>
                        <option value="08">August</option>
                        <option value="09">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                    <span class="text-danger error"
                        *ngIf="handleError('expiryMonth', 'required') && creditCardForm.controls.expiryMonth.touched">
                        Expiration Month is required
                    </span>
                </div>
                <div class="col col-md-6 pl-0 mt-2">
                    <select class="form-control rounded-0" formControlName="expiryYear" placeholder="CVV number"
                        required>
                        <option value="" disabled>Expiration Year</option>
                        <option *ngFor="let year of years" [value]="year">
                            {{year}}
                        </option>
                    </select>
                    <span class="text-danger error"
                        *ngIf="handleError('expiryYear', 'required') && creditCardForm.controls.expiryYear.touched">
                        Expiration Year is required
                    </span>
                </div>
                <div class="col col-md-6 pl-0 mt-2">
                    <input type="text" class="form-control rounded-0" formControlName="cardFirstName"
                        placeholder="First Name" required>
                    <span class="text-danger error"
                        *ngIf="handleError('cardFirstName', 'required') && creditCardForm.controls.cardFirstName.touched">
                        First name is required
                    </span>
                </div>
                <div class="col col-md-6 pl-0 mt-2">
                    <input type="text" class="form-control rounded-0" formControlName="cardLastName"
                        placeholder="Last Name" required>
                    <span class="text-danger error"
                        *ngIf="handleError('cardLastName', 'required') && creditCardForm.controls.cardLastName.touched">
                        Last name is required
                    </span>
                </div>
                <div class="col col-md-12 pl-0 mt-2">
                    <input type="text" class="form-control rounded-0" formControlName="cardBillingAddress"
                        placeholder="Billing Address" ngx-google-places-autocomplete
                        (onAddressChange)="getDetailedAddress($event)" required>
                    <span class="text-danger error"
                        *ngIf="handleError('cardBillingAddress', 'required') && creditCardForm.controls.cardBillingAddress.touched">
                        Billing address is required
                    </span>
                </div>
                <div class="col col-md-4 pl-0 mt-2">
                    <input type="text" class="form-control rounded-0" formControlName="cardBillingCity"
                        placeholder="City" required>
                    <span class="text-danger error"
                        *ngIf="handleError('cardBillingCity', 'required') && creditCardForm.controls.cardBillingCity.touched">
                        City is required
                    </span>
                </div>
                <div class="col col-md-4 pl-0 mt-2">
                    <select class="form-control rounded-0" formControlName="cardBillingState" required>
                        <option value="" disabled>State</option>
                        <option *ngFor="let state of statesData" [value]="state.StateKey">
                            {{state.StateDescription}}
                        </option>
                    </select>
                    <span class="text-danger error"
                        *ngIf="handleError('cardBillingState', 'required') && creditCardForm.controls.cardBillingState.touched">
                        State is required
                    </span>
                </div>
                <div class="col col-md-4 pl-0 mt-2">
                    <input type="text" class="form-control rounded-0" formControlName="cardBillingZip" placeholder="Zip"
                        required>
                    <span class="text-danger error"
                        *ngIf="handleError('cardBillingZip', 'required') && creditCardForm.controls.cardBillingZip.touched">
                        Zip is required
                    </span>
                </div>
                <div class="col-12 pl-0 mt-2">
                    <button type="button" class="btn common-btn rounded-0 col-md-12" (click)="saveCreditCardDetails()">
                        Save
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>