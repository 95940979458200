import { Deserializable } from './deserializable.model';

export class AdvertisingLevels implements Deserializable {

    AdvertisingLevelKey: string;
    Deleted: boolean;
    InsertedTimestamp: string;
    InsertedUserKey: string;
    SortOrder: number;
    SystemDefault: boolean;
    UpdatedTimestamp: string;
    UpdatedUserKey: string;

    deserialize(input: any): any {
        Object.assign(this, input);
        return this;
    }
}
