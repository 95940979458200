import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProviderHoursOfOperations } from '../models/provider-hours-of-operations.model';

@Injectable({
  providedIn: 'root'
})
export class ProviderHoursOfOperationsService {

  constructor(private http: HttpClient) { }

  getProviderHoursOfOperationsService(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}providerHoursOfOperationsController/getProviderHoursOfOperationByKeys`, data);
  }

  selectProviderHoursOfOperationsService(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}providerHoursOfOperationsController/providerHoursOfOperationsSelect/get`, data);
  }

  insertProviderHoursOfOperationsService(data: ProviderHoursOfOperations): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.baseUrl}providerHoursOfOperationsController/providerHoursOfOperationsInsert`, data).pipe(
      map((res: any) => res.body.map((providerHoursOfOperations: ProviderHoursOfOperations) => new ProviderHoursOfOperations().deserialize(providerHoursOfOperations))));
  }

  updateProviderHoursOfOperationsService(data): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.put<any>(`${environment.baseUrl}providerHoursOfOperationsController/providerHoursOfOperationsUpdate`, data);
  }

  copyProviderHoursOfOperation(data): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.baseUrl}providerHoursOfOperationsController/copyProviderHoursOfOperation`, data);
  }

}
