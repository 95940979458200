import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProviderNationalAccounts } from '../models/provider-national-accounts.model';

@Injectable({
  providedIn: 'root'
})
export class ProviderNationalAccountsService {

  constructor(private http: HttpClient) { }

  getProviderNationalAccounts(data): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.baseUrl}providerMembershipApplicationsController/getProviderNationalAccounts`, data).pipe(
      map((res: any) => res.body.map(
        (providerNationalAccounts: ProviderNationalAccounts) => new ProviderNationalAccounts().deserialize(providerNationalAccounts))));
  }

  insertProviderNationalAccounts(data: ProviderNationalAccounts): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.baseUrl}providerMembershipApplicationsController/insertProviderNationalAccounts`, data).pipe(
      map((res: any) => res.body.map(
        (providerNationalAccounts: ProviderNationalAccounts) => new ProviderNationalAccounts().deserialize(providerNationalAccounts))));
  }

  updateProviderNationalAccounts(data: ProviderNationalAccounts): Observable<any> {
    return this.http.put<any>(`${environment.baseUrl}providerMembershipApplicationsController/updateProviderNationalAccounts`, data);
  }

  deleteProviderNationalAccounts(filter): Observable<any> {
    return this.http.delete<any>(`${environment.baseUrl}providerMembershipApplicationsController/deleteProviderNationalAccounts/${filter}`);
  }
}
