<ngx-loading [show]="loading"></ngx-loading>
<div class="row">
  <div class="col-12 text-center mb-2">
    <h6>Select the capabilities you support</h6>
  </div>
  <div class="row px-2 ml-4 mr-4 bg-dark p-2 pr-3 mb-2 searchbox">
    <label class="col-12 bg-gradient-light text-danger">
      Note: Do not select capabilities you cannot support; doing so is violation of the Terms & Conditions of
      membership in the Service Plus Network.
    </label>
    <div class="col-md-12">
      <input class="col-md-6 float-right" [(ngModel)]="searchString" placeholder="Search by Keyword" />
    </div>
  </div>
  <div class="row mt-2 px-5 mb-5">
    <ng-container *ngFor="let data of capabilitiesData | recursiveFilter: searchString :  'description' : 'children'">
      <tree-root [nodes]="[data]" #tree [options]="options" class="col-md-4 mt-2">
        <ng-template #treeNodeTemplate let-node="node" let-index="index">
          <mat-checkbox [checked]="node.data.isSelected" (change)="onSelectedChange(node, $event)">
            <strong>{{ node.data.description }}</strong>
          </mat-checkbox>
        </ng-template>
      </tree-root>
    </ng-container>
  </div>
</div>
