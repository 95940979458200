import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  public isUserLoggedIn = new Subject<any>();

  constructor(private datePipe: DatePipe,
    private _snackBar: MatSnackBar) { }

  checkIfUserIsLoggedIn(): any {
    this.isUserLoggedIn.next();
  }

  sortData(headers, column, direction, arrList): any {
    headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    let data;
    if (direction === '') {
      data = [...arrList];
    } else {
      data = [...arrList].sort((a, b) => {
        const res = this.compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
    return data;
  }

  compare(v1, v2): any {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }

  getCurrentTimestamp() {
    const formattedDate = this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss.SSS');
    const currentTimestamp = (new Date(formattedDate)).getTime();
    return currentTimestamp;
  }

  uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

}
