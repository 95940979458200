import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { SortableHeaderDirective } from '../../directive/sortable.directive';
import { ProviderNationalAccounts } from '../../models/provider-national-accounts.model';
import { Provider } from '../../models/providers.model';
import { ProviderNationalAccountsService } from '../../services/provider-national-accounts.service';
import { ProvidersService } from '../../services/providers.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { UserInformationService } from '../../services/user-information.service';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-provider-national-accounts',
  templateUrl: './provider-national-accounts.component.html',
  styleUrls: ['./provider-national-accounts.component.scss']
})
export class ProviderNationalAccountsComponent implements OnInit {

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  loading = false;
  provider: Provider = new Provider();
  providerNationalAccounts: ProviderNationalAccounts[] = [];

  constructor(
    private utilityService: UtilityService,
    private providersService: ProvidersService,
    private userInformationService: UserInformationService,
    private snackBarService: SnackBarService,
    private providerNationalAccountsService: ProviderNationalAccountsService
  ) { }

  ngOnInit(): void {
    this.provider = this.providersService.provider || new Provider();
    this.getProviderNationalAccounts();
  }

  getProviderNationalAccounts(): void {
    const filter = new ProviderNationalAccounts().deserialize({
      providerPK: this.provider.providerPK
    });

    this.loading = true;
    this.providerNationalAccountsService.getProviderNationalAccounts(filter).subscribe(response => {
      this.providerNationalAccounts = this.utilityService.sortData(this.headers, 'nationalAccountKey', 'asc', response);
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  addProviderNationalAccount(account): void {
    if (account.providerNationalAccountKey) {
      this.updateProviderNationalAccounts(account);
    } else {
      this.insertProviderNationalAccounts(account);
    }
  }

  updateProviderNationalAccounts(account): void {
    const filter = new ProviderNationalAccounts().deserialize({
      providerNationalAccountKey: account.providerNationalAccountKey,
      deleted: !account.deleted,
      updatedUserKey: this.userInformationService.userInformation.userInformationID.toString(),
    });

    this.loading = true;
    this.providerNationalAccountsService.updateProviderNationalAccounts(filter).subscribe(() => {
      this.getProviderNationalAccounts();
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  insertProviderNationalAccounts(account): void {
    const filter = new ProviderNationalAccounts().deserialize({
      providerPK: this.provider.providerPK,
      nationalAccountKey: account.nationalAccountKey,
      insertedUserKey: this.userInformationService.userInformation.userInformationID.toString(),
      deleted: false,
      systemDefault: false,
    });

    this.loading = true;
    this.providerNationalAccountsService.insertProviderNationalAccounts(filter).subscribe(() => {
      this.getProviderNationalAccounts();
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

}
