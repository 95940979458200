import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProviderElectronicDispatchEmail } from '../models/provider-electronic-dispatch-email.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProviderElectronicDispatchEmailService {

  constructor(private http: HttpClient) { }

  getProviderElectronicDispatchEmails(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post(`${environment.baseUrl}providerMembershipApplicationsController/getProviderElectronicDispatchEmailDetails`, data)
      .pipe(
        map((res: any) => res.body.map(
          (providerElectronicDispatchEmail: ProviderElectronicDispatchEmail) => new ProviderElectronicDispatchEmail()
            .deserialize(providerElectronicDispatchEmail))));
  }

  deleteProviderElectronicDispatchEmails(data: any = {}): Observable<any> {
    const providerElectronicDispatchEmailKey = data.providerElectronicDispatchEmailKey;
    return this.http.delete(`${environment.baseUrl}providerMembershipApplicationsController/deleteProviderElectronicDispatchEmails/${providerElectronicDispatchEmailKey}`);
  }
}
