import { forkJoin } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { ProviderAdditionalAddresses } from '../../models/provider-additional-addresses.model';
import { Provider } from '../../models/providers.model';
import { ProviderAdditionalAddressesService } from '../../services/provider-additional-addresses.service';
import { ProvidersService } from '../../services/providers.service';
import { UserInformationService } from '../../services/user-information.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { ProviderHoursOfOperationsService } from './../../services/provider-hours-of-operations.service'; // ../../index'
import { ProviderHoursOfOperations } from '../../models/provider-hours-of-operations.model';

@Component({
  selector: 'app-hours-of-operation',
  templateUrl: './hours-of-operation.component.html',
  styleUrls: ['./hours-of-operation.component.scss']
})
export class HoursOfOperationComponent implements OnInit {

  @Input() parent: boolean;
  @Output() saveComplete = new EventEmitter<any>();

  providerProfileForm: UntypedFormGroup;
  provider: Provider = new Provider();
  providerAdditionalAddresses: ProviderAdditionalAddresses = new ProviderAdditionalAddresses();
  loading = false;

  providerHoursOfOperations: ProviderHoursOfOperations;
  weekData: any = [];
  editDayFlag = true;
  Days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  openTime: any;
  closeTime: any;
  providerHoursOfOperationKey: any;
  currentTimezone: any;
  getChildData: any;
  parentData = false;
  allLocationsSameHours = false;

  constructor(
    private providersService: ProvidersService,
    private userInformationService: UserInformationService,
    private providerAdditionalAddressesService: ProviderAdditionalAddressesService,
    private snackBarService: SnackBarService,
    private providerHoursOfOperationsService: ProviderHoursOfOperationsService
  ) { }

  ngOnInit(): void {
    this.provider = this.providersService.provider || new Provider();
    this.providerAdditionalAddresses = this.providerAdditionalAddressesService.providerAdditionalAddresses
      || new ProviderAdditionalAddresses();

    this.getHoursOfOperation();
  }

  setFormData(): void {
    if (this.parent) {
      this.providerProfileForm.patchValue({
        providerPK: this.provider.providerPK || '',
        allLocationsSameHours: this.provider.allLocationsSameHours,
        hoursOpen: this.provider.hoursOpen || '',
        hoursClose: this.provider.hoursClose || '',
      });
    } else {
      this.providerProfileForm.patchValue({
        additionalAddressPK: this.providerAdditionalAddresses.additionalAddressPK || '',
        providerPK: this.providerAdditionalAddresses.providerPK || '',
        hoursOpen: this.providerAdditionalAddresses.hoursOpen || '',
        hoursClose: this.providerAdditionalAddresses.hoursClose || '',
      });
    }
    this.setFormValidation();
  }

  setFormValidation(): void {
    if (this.parent) {
      this.providerProfileForm.controls.allLocationsSameHours.setValidators([Validators.required]);
    } else {
      this.providerProfileForm.controls.allLocationsSameHours.setValidators(null);
      if (this.provider.allLocationsSameHours) {
        this.providerProfileForm.controls.hoursOpen.setValue(this.provider.hoursOpen);
        this.providerProfileForm.controls.hoursClose.setValue(this.provider.hoursClose);
        this.providerProfileForm.controls.hoursOpen.setValidators(null);
        this.providerProfileForm.controls.hoursClose.setValidators(null);
        this.providerProfileForm.controls.hoursOpen.updateValueAndValidity();
        this.providerProfileForm.controls.hoursClose.updateValueAndValidity();
      }
    }
    this.providerProfileForm.controls.allLocationsSameHours.updateValueAndValidity();
    this.setFormData();
  }

  handleError = (control: string, error: string) => {
    return this.providerProfileForm.controls[control].hasError(error);
  }

  updateProviderDetails(): any {
    this.saveComplete.emit(true);
  }

  updateProviderAdditionalAddressDetails(): any {
    this.saveComplete.emit(true);
  }

  getHoursOfOperation(): void {
    this.loading = true;
    this.Days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    this.weekData = [];
    const data = {
      providerKey: this.provider.providerPK,
      additionalAddressPK: this.parent ? null : this.providerAdditionalAddresses.additionalAddressPK,
      deleted: false
    };

    this.providerHoursOfOperationsService.selectProviderHoursOfOperationsService(data).subscribe(res => {
      this.loading = false;
      if (res.body.length > 0) {
        this.parentData = false;
        this.Days.forEach(obj => {
          res.body.forEach(weekday => {
            if (obj === weekday.DaysOfWeekKey) {
              this.weekData.push(weekday);
            }
          });
        });
      } else {
        this.setHoursOfOperation();
      }
    }, (error) => {
      this.loading = false;
      console.log(error);
    });

  }

  editDay(data): void {
    this.providerHoursOfOperationKey = data.ProviderHoursOfOperationKey;
    this.editDayFlag = data.DaysOfWeekKey;
    this.openTime = data.OpenTime;
    this.closeTime = data.CloseTime;
  }

  resetHoursOfOperation(): void {
    this.openTime = '12:00 AM';
    this.closeTime = '12:00 AM';
  }

  closeDayData(dayOfWeek): void {
    this.editDayFlag = !dayOfWeek;
  }

  updateHoursOfOperation(openTimeValue, closeTimeValue): void {
    this.editDayFlag = false;
    const jsonData = {
      openTime: openTimeValue,
      closeTime: closeTimeValue,
      providerHoursOfOperationKey: this.providerHoursOfOperationKey,
      closed: false
    };

    this.loading = true;
    this.providerHoursOfOperationsService.updateProviderHoursOfOperationsService(jsonData).subscribe((result: any) => {
      if (result.success) {
        this.snackBarService.success(result.message);
        this.getHoursOfOperation();
      } else {
        this.snackBarService.success(result.message);
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  setHoursOfOperation(): void {
    if (this.providerAdditionalAddresses.additionalAddressPK) {
      const insertHrsOperationRequest = [];
      this.Days.forEach(day => {
        const data = {
          openTime: '8:00 AM',
          closeTime: '8:00 AM',
          daysOfWeekKey: day,
          deleted: false,
          systemDefault: false,
          providerKey: this.provider.providerPK,
          additionalAddressPK: this.providerAdditionalAddresses.additionalAddressPK,
          insertedUserKey: this.userInformationService.userInformation.userInformationID.toString(),
          updatedUserKey: ''
        };
        insertHrsOperationRequest.push(this.providerHoursOfOperationsService
          .insertProviderHoursOfOperationsService(new ProviderHoursOfOperations().prepareSave(data)));
      });

      this.loading = true;
      forkJoin(insertHrsOperationRequest).subscribe((res: any) => {
        this.loading = false;
        this.getHoursOfOperation();
      }, (error) => {
        this.loading = false;
        console.log(error);
      });
    }
  }

  updateTo24Hours(openTimeValue, closeTimeValue, isClosed): void {
    const operationData = [];
    this.weekData.forEach(item => {
      let closeFlag = false;
      if ((item.DaysOfWeekKey === 'Saturday' || item.DaysOfWeekKey === 'Sunday') && isClosed) {
        closeFlag = true;
      }
      const data = {
        openTime: openTimeValue,
        closeTime: closeTimeValue,
        daysOfWeekKey: item.DaysOfWeekKey,
        deleted: false,
        systemDefault: false,
        providerHoursOfOperationKey: item.ProviderHoursOfOperationKey,
        providerKey: this.provider.providerPK,
        updatedUserKey: this.userInformationService.userInformation.userInformationID.toString(),
        closed: closeFlag
      };
      if ((isClosed && (item.DaysOfWeekKey === 'Saturday' || item.DaysOfWeekKey === 'Sunday')) || !isClosed) {
        operationData.push(this.providerHoursOfOperationsService.updateProviderHoursOfOperationsService(data));
      }
    });

    this.loading = true;
    forkJoin(operationData).subscribe(() => {
      this.loading = false;
      this.getHoursOfOperation();
    }, (error) => {
      this.loading = false;
    });
  }
}
