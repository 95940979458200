import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ProviderOfferings } from '../../models/provider-offerings.model';
import { Provider } from '../../models/providers.model';
import { ProviderOfferingsService } from '../../services/provider-offerings.service';
import { ProvidersService } from '../../services/providers.service';
import { ProviderAdditionalAddressesService } from '../../services/provider-additional-addresses.service';
import { ITreeOptions } from '@circlon/angular-tree-component';
import { ProviderAdditionalAddresses } from '../../models/provider-additional-addresses.model';
import { UserInformationService } from '../../services/user-information.service';
import { SnackBarService } from '../../services/snack-bar.service';

@Component({
  selector: 'app-provider-service-offerings',
  templateUrl: './provider-service-offerings.component.html',
  styleUrls: ['./provider-service-offerings.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProviderServiceOfferingsComponent implements OnInit {

  @Input() parent: boolean;
  @Output() isServiceSelected = new EventEmitter<boolean>();
  provider: Provider = new Provider();
  providerAdditionalAddresses: ProviderAdditionalAddresses = new ProviderAdditionalAddresses();
  providerOfferings: ProviderOfferings = new ProviderOfferings();
  serviceData: any;
  selectedCount = 0;
  selectedServices = [];
  loading = false;
  service: string;

  options: ITreeOptions = {
    animateExpand: true,
    isExpandedField: 'expanded'
  };
  selectedKey: any;

  constructor(
    private providersService: ProvidersService,
    private providerAdditionalAddressesService: ProviderAdditionalAddressesService,
    private providerOfferingsService: ProviderOfferingsService,
    private userInformationService: UserInformationService,
    private snackBarService: SnackBarService
  ) { }

  ngOnInit(): void {
    this.provider = this.providersService.provider || new Provider();
    this.providerAdditionalAddresses = this.providerAdditionalAddressesService.providerAdditionalAddresses
      || new ProviderAdditionalAddresses();
    this.initProviderServiceOffering();
  }

  initProviderServiceOffering(): void {
    const data = {
      additionalAddressPK: this.parent ? null : this.providerAdditionalAddresses.additionalAddressPK,
      inProviderPK: this.provider.providerPK
    };
    this.loading = true;
    this.providerOfferingsService.getProviderOfferings(data).subscribe((response) => {
      this.loading = false;
      this.serviceData = response.body;
      if (this.selectedKey) this.expandSelectedSearchNode(this.serviceData, this.selectedKey);
      this.countAndListServices();
      this.isServiceSelected.emit(this.selectedCount ? true : false);
    }, (error) => {
      this.loading = false;
    });
  }

  expandSelectedSearchNode(array, value) {
    let found = false;
    array.forEach(element => {
      element.expanded = this.expandSelectedSearchNode(element.children || [], value) || element.key === value;
      found = found || element.expanded;
    });
    return found;
  }

  onSelectedChange(serviceData, event): void {
    this.updateChildNodeCheckbox(serviceData.data, event.checked);
    this.updateParentNodeCheckbox(serviceData);
    this.countAndListServices();

    const data = {
      additionalAddressPK: this.parent ? null : this.providerAdditionalAddresses.additionalAddressPK,
      deleted: event.checked ? 0 : 1,
      offeringKey: serviceData.data.key,
      providerKey: this.provider.providerPK,
      userKey: this.userInformationService.userInformation.userInformationID,
    };
    this.loading = true;

    this.providerOfferingsService.addDeleteProviderOfferings(data).subscribe(() => {
      this.loading = false;
      if (event.checked) serviceData.treeModel.expandAll();
      else serviceData.treeModel.collapseAll();
      this.countAndListServices();
      this.isServiceSelected.emit(this.selectedCount ? true : false);
      if (this.service) {
        this.selectedKey = serviceData.data.key;
        this.service = '';
        this.initProviderServiceOffering();
      }
      this.snackBarService.success('Offerings updated successfully.');
    }, (error) => {
      this.loading = false;
    });
  }

  countAndListServices(): void {
    this.selectedCount = 0;
    this.selectedServices = [];
    this.serviceData.forEach(service => {
      if (service.deleted === 0) {
        this.selectedCount += 1;
        this.selectedServices.push(service.name);
      }
      if (service.children?.length > 0) {
        this.addItemRecursively(service.children);
      }
    });
  }

  addItemRecursively(data): void {
    data.forEach(service => {
      if (service.deleted === 0) {
        this.selectedServices.push(service.name);
      }
      if (service.children?.length > 0) {
        this.addItemRecursively(service.children);
      }
    });
  }

  public updateChildNodeCheckbox(node, checked): void {
    node.deleted = checked ? 0 : 1;
    if (node.children) {
      node.children.forEach((child) => this.updateChildNodeCheckbox(child, checked));
    }
  }

  public updateParentNodeCheckbox(node): void {
    if (!node) { return; }

    let allChildrenChecked = true;
    let noChildChecked = true;

    if (node.children && node.children.length > 0) {
      for (const child of node?.children) {
        if (child.data.deleted === 1) {
          allChildrenChecked = false;
        }
        if (child.data.deleted === 0) {
          noChildChecked = false;
        }
      }

      if (allChildrenChecked) {
        node.data.deleted = 0;
      } else if (noChildChecked) {
        node.data.deleted = 1;
      } else {
        node.data.deleted = 0;
      }
    }
    this.updateParentNodeCheckbox(node.parent);
  }
}
