import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProviderCreditCards } from '../models/provider-credit-cards.model';

@Injectable({
  providedIn: 'root'
})
export class ProviderCreditCardsService {

  constructor(private http: HttpClient) { }

  insertProviderCreditCards(data: ProviderCreditCards): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.baseUrl}providers/insertProviderCreditCards`, data);
  }
}
