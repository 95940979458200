// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  google_map_api_key: 'AIzaSyAujtG72JeylCc94YqqV0tkd3_FWSdaJnQ',
  google_analytics: 'G-GY9P54ZCB9',
  baseUrl: 'https://apiaccess.breakdownnow.com/api/',
  spApiUrl: 'https://slim.auxiliumworldwide.com/api/',
  restServicesURL: 'https://v0mrfzh373.execute-api.us-east-2.amazonaws.com/prod/',
  serviceProviderURL: 'https://serviceplusnetwork.com/',
  fleetAssistURL: 'https://fleetassist.com/#/',
  cognitoSignupUrl: 'https://admin.auxiliumworldwide.com/api/aws/idp',
  servicePlusAdminURL: 'https://serviceplus.auxiliumworldwide.com'
};

/*
 * For easier debugging in development mode, you can import the following file

 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
