<ngx-loading [show]="loading"></ngx-loading>
<form [formGroup]="providerProfileForm">
    <div class="row">
        <div class="col-12 col-md-3">
            <div class="container">
                <div class="imagePreview"><img [src]="imgURL" height="185" *ngIf="imgURL"></div>
                <label class="col-12 btn common-btn mt-4">
                    Upload Logo
                    <input type="file" accept='image/*' #file (change)="uploadLogo(file.files, $event)"
                        class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden; display: none;">
                </label>
                <span class="small text-warning">File must be of image type(png, jpeg etc.)</span>
            </div>
        </div>
        <div class="col-12 col-md-9">
            <mat-form-field>
                <textarea rows="7" matInput formControlName="description"
                    placeholder="Enter description that will display under your name in Breakdown Now search result"
                    required>
                </textarea>
                <mat-error *ngIf="handleError('description', 'required')">
                    Description is required.
                </mat-error>
            </mat-form-field>
        </div>
    </div>
</form>