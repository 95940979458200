import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OutlawService {

  constructor(private http: HttpClient) { }

  getoutlaw(OutlawDealID): Observable<any> {
    const headerOptions = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('x-api-key', '22e93d66-61f2-4442-9854-fc27f13e0c95');
    return this.http.get<any>(`https://app.getoutlaw.com/api/v1/deals/${OutlawDealID}/pdf?base64=true`, { headers: headerOptions });
  }

  getoutlawCustomerPDF(OutlawDealID): Observable<any> {
    const headerOptions = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('x-api-key', 'd21b6483-5483-4195-ba3b-c7840e56a4af');
    return this.http.get<any>(`https://app.getoutlaw.com/api/v1/deals/${OutlawDealID}/pdf?base64=true`, { headers: headerOptions });
  }
}
