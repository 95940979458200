<ngx-loading [show]="loading"></ngx-loading>
<h2 mat-dialog-title class="text-center bgDialogHeader">
    Select the Make your shop supports
    <div class="pull-right p-1" nbTooltip="close">
        <a class="boxclose" (click)="closeDialog()"></a>
    </div>
</h2>

<div class="modal-body">
    <div class="banner_form2">
        <div class="row p-2">
            <div class="col-md-5">
                <mat-form-field>
                    <mat-select multiple [(ngModel)]="selectedMakes" placeholder="Select Make to add"
                        [ngModelOptions]="{standalone: true}" #selectionMake="ngModel">
                        <mat-option>
                            <ngx-mat-select-search [ngModelOptions]="{standalone: true}" [(ngModel)]="searchCtrl"
                                [placeholderLabel]="'Search...'" [noEntriesFoundLabel]="'Not found'" name="search">
                            </ngx-mat-select-search>
                        </mat-option>
                        <ngx-select-check-all [model]="selectionMake" [values]="makeNames">
                        </ngx-select-check-all>
                        <mat-option *ngFor="let make of makes | orderBy : 'MakeKey' | filter:searchCtrl:'MakeKey'"
                            [value]="make.MakeKey" [disabled]="existingMakes.includes(make.MakeKey)">
                            {{ make.MakeKey }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error class="mt-n2 custom-font" *ngIf="selectedMakes.length === 0">
                    Make is required.
                </mat-error>
            </div>
            <div class="col-md-3 text-right">
                <button class="btn btn-sm common-btn mt-2" (click)="insertProviderCapabilitiesMake()">Add Make</button>
            </div>
        </div>
        <div class="row p-2">
            <div *ngIf="isMakeDependent" class="col-md-12 text-center">
                <div class="row m-2" *ngIf="providerServiceCapabilities.length === 0">
                    <div class="col-md-12 alert alert-danger">
                        Atleast one make is required.
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="table-responsive table-wrapper-scroll-y my-custom-scrollbar">
                    <table class="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th sortable="makeKey" (sort)="onSort($event)" class="align-middle" direction="asc">
                                    Makes
                                </th>
                                <th class="w-25 align-middle text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="providerServiceCapabilities.length > 0; else noData">
                            <tr *ngFor="let item of providerServiceCapabilities | filter : searchString;">
                                <td>{{ item.makeKey }}</td>
                                <td class="text-center">
                                    <button class="btn btn-default btn-sm btnAnimation mr-1" title="Delete"
                                        (click)="deleteUpdateMake(item.providerCapabilityPK)">
                                        <h6><i class="fa fa-trash text-danger"></i></h6>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <ng-template #noData>
                            <tbody>
                                <tr>
                                    <td colspan="2" class="text-center">No Data available</td>
                                </tr>
                            </tbody>
                        </ng-template>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>