import { Deserializable } from './deserializable.model';

export class ProviderCapabilities implements Deserializable {

    additionalAddressPK: string;
    deleted: boolean;
    insertedTimestamp: string;
    insertedUserKey: string;
    makeKey: string;
    providerCapabilityKey: string;
    providerCapabilityPK: string;
    providerPK: string;
    systemDefault: boolean;
    updatedTimestamp: string;
    updatedUserKey: string;
    makeDependent: boolean;

    deserialize(input: any): any {
        Object.assign(this, input);
        return this;
    }
}
