import { Deserializable } from './deserializable.model';

export class Makes implements Deserializable {

    axlePointMakeKey: number;
    deleted: boolean;
    insertedUserKey: string;
    makeKey: string;
    systemDefault: boolean;
    updatedUserKey: string;

    deserialize(input: any): any {
        Object.assign(this, input);
        return this;
    }
}
