import { Deserializable } from './deserializable.model';

export class CustomerTermsAndConditions implements Deserializable {

  customerKey: string;
  description: string;
  insertedUserKey: string;
  updatedUserKey: string;

  deserialize(input: any): any {
    Object.assign(this, input);
    return this;
  }
}
