import { Deserializable } from './deserializable.model';

export class Attachments implements Deserializable {

    associationKey: string;
    associationTypeKey: string;
    attachmentKey: string;
    attachmentTypeKey: string;
    deleted: boolean;
    insertedTimestamp: string;
    insertedUserKey: string;
    serviceRequestKey: string;
    systemDefault: boolean;
    updatedTimestamp: string;
    updatedUserKey: string;
    url: string;
    documentTypeKey: string;

    deserialize(input: any): any {
        Object.assign(this, input);
        return this;
    }
}
