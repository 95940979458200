<ngx-loading [show]="loading"></ngx-loading>
<form [formGroup]="providerProfileForm">
    <div class="row">
        <div class="col-12 col-md-3" *ngIf="parent">
            <mat-form-field>
                <mat-select matNativeControl formControlName="useCentralBilling"
                    placeholder="Do you use Central Billing" required>
                    <mat-option [value]="true">Yes</mat-option>
                    <mat-option [value]="false">No</mat-option>
                </mat-select>
                <mat-error *ngIf="handleError('useCentralBilling', 'required')">
                    is Central Billing is required.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-12 col-md-3" *ngIf="(!parent && !provider.useCentralBilling) || parent">
            <mat-form-field>
                <input type="text" matInput formControlName="billingPhone" placeholder="Billing Phone" required
                    mask="000-000-0000">
                <mat-error *ngIf="handleError('billingPhone', 'required')">
                    Billing Phone is required.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-12 col-md-3" *ngIf="(!parent && !provider.useCentralBilling) || parent">
            <mat-form-field>
                <input type="text" matInput formControlName="billingEmail" placeholder="Billing Email" required>
                <mat-error *ngIf="handleError('billingEmail', 'required')">
                    Billing Email is required.
                </mat-error>
                <mat-error *ngIf="handleError('billingEmail', 'pattern')">
                    Enter valid Email Address.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-12 text-center" *ngIf="(!parent && provider.useCentralBilling)">
            <div class="alert alert-info" role="alert">
                Central Billing
              </div>
        </div>
    </div>
</form>