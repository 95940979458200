import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProviderElectronicDispatchPhoneNumber } from '../models/provider-electronic-dispatch-phone-number.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProviderElectronicDispatchPhoneNumberService {

  constructor(private http: HttpClient) { }

  getProviderElectronicDispatchPhoneNumbers(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post(`${environment.baseUrl}providerMembershipApplicationsController/getProviderElectronicDispatchPhoneNumberDetails`
      , data)
      .pipe(
        map((res: any) => res.body.map(
          (providerElectronicDispatchPhoneNumber: ProviderElectronicDispatchPhoneNumber) => new ProviderElectronicDispatchPhoneNumber()
            .deserialize(providerElectronicDispatchPhoneNumber))));
  }

  deleteProviderElectronicDispatchPhoneNumbers(data: any = {}): Observable<any> {
    const providerElectronicDispatchPhoneNumberKey = data.providerElectronicDispatchPhoneNumberKey;
    return this.http.delete(`${environment.baseUrl}providerMembershipApplicationsController/deleteProviderElectronicDispatchPhoneNumbers/${providerElectronicDispatchPhoneNumberKey}`);
  }
}
