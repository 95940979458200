import { Deserializable } from './deserializable.model';

export class ProviderOfferings implements Deserializable {

    additionalAddressPK: string;
    cargo_DryStorage: boolean;
    cargo_LoadShift_Transfer_Dock: boolean;
    cargo_LoadShift_Transfer_Transfer: boolean;
    cargo_TemperatureControlledStorage: boolean;
    cleanPrepStore_AbandonedUnitSecureandStore: boolean;
    cleanPrepStore_TruckExteriorDetail: boolean;
    cleanPrepStore_TruckInterior: boolean;
    deleted: boolean;
    insertedUserKey: string;
    mobileRepair_LD: boolean;
    mobileRepair_MDHD: boolean;
    mobileRepair_Trailer: boolean;
    noStart_JumpStart: boolean;
    noStart_PullStart: boolean;
    noStart_VehicleThawStart: boolean;
    parking_Trailer: boolean;
    parking_TruckTrailer: boolean;
    parking_Truck_Auto: boolean;
    providerOfferingPK: string;
    providerPK: string;
    recovery_LD: boolean;
    recovery_MDHD: boolean;
    scheduledMainenance_DOT: boolean;
    scheduledMainenance_FilterRegen: boolean;
    scheduledMainenance_LDOil_Filter: boolean;
    scheduledMainenance_PMA: boolean;
    scheduledMainenance_PMB: boolean;
    scheduledMainenance_TireCheck: boolean;
    shopRepair_LD: boolean;
    shopRepair_MDHD: boolean;
    shopRepair_Trailer: boolean;
    systemDefault: boolean;
    tires_Auto: boolean;
    tires_Commerical: boolean;
    towing_AfterTowSecuredStorage: boolean;
    towing_Bus: boolean;
    towing_ComplexWinchout: boolean;
    towing_DeckingServices: boolean;
    towing_HD: boolean;
    towing_LD: boolean;
    towing_MD: boolean;
    towing_MotorCoach: boolean;
    towing_SimpleWinchout: boolean;
    towing_TrailerOnly: boolean;
    updatedUserKey: string;

    deserialize(input: any): any {
        Object.assign(this, input);
        return this;
    }

}
