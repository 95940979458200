import { Deserializable } from './deserializable.model';

export class UserInformation implements Deserializable {

    userInformationID: number;
    firstName: string;
    lastName: string;
    password: string;
    email: string;
    phone: string;
    officePhone: string;
    active: boolean;
    addedBy: number;
    updatedBy: number;
    deleteFlag: boolean;

    deserialize(input: any): any {
        Object.assign(this, input);
        return this;
    }

    prepareSave(data): any {
        this.deserialize(data);
        this.deleteFlag = false;
        this.active = false;
        this.addedBy = 0;
        this.updatedBy = 0;
        return this;
    }
}
