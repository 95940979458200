import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { patterns } from 'src/assets/patterns';
import { ProviderAdditionalAddresses } from '../../models/provider-additional-addresses.model';
import { Provider } from '../../models/providers.model';
import { States } from '../../models/states.model';
import { ProviderAdditionalAddressesService } from '../../services/provider-additional-addresses.service';
import { ProvidersService } from '../../services/providers.service';
import { StatesService } from '../../services/states.service';
import { UserInformationService } from '../../services/user-information.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { UtilityService } from '../../services/utility.service';
import { SortableHeaderDirective } from '../../directive/sortable.directive';
import { ProviderOfferingsService } from '../../services/provider-offerings.service';
import { forkJoin } from 'rxjs';
import { CountiesService } from '../../services/counties.service';
import { ProviderHoursOfOperationsService } from '../../services/provider-hours-of-operations.service';
import { ProviderCapabilitiesService } from '../../services/provider-capabilities.service';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent implements OnInit {

  @Input() parent: boolean;
  @Output() saveComplete = new EventEmitter<any>();

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  providerProfileForm: UntypedFormGroup;
  provider: Provider = new Provider();
  providerAdditionalAddresses: ProviderAdditionalAddresses = new ProviderAdditionalAddresses();
  searchState: string;
  addrLableText: string;
  latitude = 33.013438;
  longitude = -96.772337;
  loading = false;
  statesData: States[] = [];
  zoneKey: number;
  countyKey: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private statesService: StatesService,
    private providersService: ProvidersService,
    private userInformationService: UserInformationService,
    private providerAdditionalAddressesService: ProviderAdditionalAddressesService,
    private snackBarService: SnackBarService,
    private utilityService: UtilityService,
    private providerOfferingsService: ProviderOfferingsService,
    private countyService: CountiesService,
    private hoursOfOperationService: ProviderHoursOfOperationsService,
    private providerCapabilitiesService: ProviderCapabilitiesService,
  ) { }

  ngOnInit(): void {
    this.loadDetails();
  }

  loadDetails(): void {
    this.getAllFormDropdowns();
    this.provider = this.providersService.provider || new Provider();
    this.providerAdditionalAddresses = this.providerAdditionalAddressesService.providerAdditionalAddresses
      || new ProviderAdditionalAddresses();
    this.addrLableText = this.parent ? '' : 'Physical';

    this.providerProfileForm = this.formBuilder.group({
      providerPK: [''],
      additionalAddressPK: [''],
      companyName: [''],
      name: [''],
      ownerFirstName: [''],
      ownerLastName: [''],
      address1: ['', [Validators.required]],
      address2: [''],
      address3: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      postalCode: ['', [Validators.required, Validators.pattern(patterns.zipCodePattern)]],
      performsSameService: [false],
      allLocationsSameHours: [false],
      isSameCapabilities: [false],
    });

    this.setFormValidation();
    this.setFormData();
  }

  setFormData(): void {
    if (this.parent) {
      this.providerProfileForm.patchValue({
        providerPK: this.provider.providerPK || '',
        companyName: this.provider.companyName || '',
        ownerFirstName: this.provider.ownerFirstName || '',
        ownerLastName: this.provider.ownerLastName || '',
        address1: this.provider.address1 || '',
        address2: this.provider.address2 || '',
        address3: this.provider.address3 || '',
        city: this.provider.city || '',
        state: this.provider.state || '',
        postalCode: this.provider.postalCode || '',
      });
    } else {
      this.providerProfileForm.patchValue({
        additionalAddressPK: this.providerAdditionalAddresses.additionalAddressPK || '',
        providerPK: this.providerAdditionalAddresses.providerPK || '',
        name: this.providerAdditionalAddresses.name || '',
        address1: this.providerAdditionalAddresses.address1 || '',
        address2: this.providerAdditionalAddresses.address2 || '',
        address3: this.providerAdditionalAddresses.address3 || '',
        city: this.providerAdditionalAddresses.city || '',
        state: this.providerAdditionalAddresses.state || '',
        postalCode: this.providerAdditionalAddresses.postalCode || '',
        performsSameService: this.providerAdditionalAddresses.performsSameService || false,
        allLocationsSameHours: this.providerAdditionalAddresses.allLocationsSameHours || false,
        isSameCapabilities: this.providerAdditionalAddresses.isSameCapabilities || false
      });
    }
  }

  setFormValidation(): void {
    if (this.parent) {
      this.providerProfileForm.controls.companyName.setValidators([Validators.required, Validators.pattern(patterns.textPattern)]);
      this.providerProfileForm.controls.name.setValidators(null);
      this.providerProfileForm.controls.ownerFirstName.setValidators([Validators.required, Validators.pattern(patterns.namePattern)]);
      this.providerProfileForm.controls.ownerLastName.setValidators([Validators.required, Validators.pattern(patterns.lastNamePattern)]);
    } else {
      this.providerProfileForm.controls.companyName.setValidators(null);
      this.providerProfileForm.controls.name.setValidators([Validators.required, Validators.pattern(patterns.textPattern)]);
      this.providerProfileForm.controls.ownerFirstName.setValidators(null);
      this.providerProfileForm.controls.ownerLastName.setValidators(null);
    }
    this.providerProfileForm.controls.companyName.updateValueAndValidity();
    this.providerProfileForm.controls.name.updateValueAndValidity();
    this.providerProfileForm.controls.ownerFirstName.updateValueAndValidity();
    this.providerProfileForm.controls.ownerLastName.updateValueAndValidity();
  }

  getAllFormDropdowns(): void {
    this.loading = true;
    this.statesService.getStates(new States()).subscribe((response) => {
      this.loading = false;
      this.statesData = this.utilityService.sortData(this.headers, 'StateDescription', 'asc', response);
    }, (error) => {
      this.loading = false;
    });
  }

  handleError = (control: string, error: string) => {
    return this.providerProfileForm.controls[control].hasError(error);
  }

  public getDetailedAddress(address: Address): void {
    this.latitude = address.geometry.location.lat();
    this.longitude = address.geometry.location.lng();
    address.address_components.forEach(element => {
      element.types.forEach(data => {
        switch (data) {
          case 'neighborhood':
            this.providerProfileForm.controls.address2.setValue(element.long_name);
            break;
          case 'administrative_area_level_2':
            this.providerProfileForm.controls.address3.setValue(element.long_name);
            break;
          case 'administrative_area_level_1':
            this.providerProfileForm.controls.state.setValue(element.short_name);
            break;
          case 'postal_code':
            this.providerProfileForm.controls.postalCode.setValue(element.long_name);
            break;
          case 'locality':
            this.providerProfileForm.controls.city.setValue(element.long_name);
            break;
        }
      });
    });
    this.providerProfileForm.controls.address1.setValue(address.name);
  }

  updateProviderDetails(): any {
    if (this.providerProfileForm.invalid) {
      this.providerProfileForm.markAllAsTouched();
      return false;
    }

    const zoneReq = {
      county: this.providerProfileForm.controls.address3.value,
      stateKey: this.providerProfileForm.controls.state.value,
    };
    this.loading = true;
    this.countyService.getCounties(zoneReq).subscribe(res => {
      this.loading = false;
      this.zoneKey = res[0]?.ZoneKey;
      this.countyKey = res[0]?.CountyKey;
      this.provider = Object.assign(this.provider, new Provider().prepareSave(this.providerProfileForm.value));
      this.provider.updatedUserKey = this.userInformationService.userInformation.userInformationID.toString();
      this.provider.zoneKey = this.zoneKey ? this.zoneKey : 0;
      this.provider.countyKey = this.countyKey ? this.countyKey : null;
      this.provider.latitude = this.latitude;
      this.provider.longitude = this.longitude;
      this.loading = true;
      this.providersService.updateProvider(this.provider).subscribe((result) => {
        this.loading = false;
        this.snackBarService.success(result.message);
        this.providersService.provider = this.provider;
        this.saveComplete.emit(true);
      }, (error) => {
        this.loading = false;
        this.saveComplete.emit(false);
      });
    });
  }

  updateProviderAdditionalAddressDetails(): any {
    if (this.providerProfileForm.invalid) {
      this.providerProfileForm.markAllAsTouched();
      return false;
    }
    const zoneReq = {
      county: this.providerProfileForm.controls.address3.value,
      stateKey: this.providerProfileForm.controls.state.value,
    };
    this.loading = true;
    this.countyService.getCounties(zoneReq).subscribe(res => {
      this.loading = false;
      this.zoneKey = res[0]?.ZoneKey;
      this.countyKey = res[0]?.CountyKey;
      this.providerAdditionalAddresses = Object.assign(
        this.providerAdditionalAddresses, new ProviderAdditionalAddresses().prepareSave(this.providerProfileForm.value)
      );
      this.providerAdditionalAddresses.zoneKey = this.zoneKey ? this.zoneKey : 0;
      this.providerAdditionalAddresses.countyKey = this.countyKey ? this.countyKey : null;
      this.providerAdditionalAddresses.latitude = this.latitude;
      this.providerAdditionalAddresses.longitude = this.longitude;
      this.provider.updatedUserKey = this.userInformationService.userInformation.userInformationID.toString();
      const request = [];
      request.push(this.providerAdditionalAddressesService.updateProviderAdditionalAddresses(this.providerAdditionalAddresses));

      if (this.providerProfileForm.controls.performsSameService.value) {
        const data = {
          additionalAddressPK: this.providerAdditionalAddresses.additionalAddressPK,
          providerKey: this.provider.providerPK,
          userInformationId: this.userInformationService.userInformation.userInformationID.toString()
        };
        request.push(this.providerOfferingsService.copyProviderOfferingsToPAA(data));
      }

      if (this.providerProfileForm.controls.allLocationsSameHours.value) {
        const jsonData = {
          locationKey: this.providerAdditionalAddresses.additionalAddressPK,
          providerKey: this.provider.providerPK
        };
        request.push(this.hoursOfOperationService.copyProviderHoursOfOperation(jsonData));
      }

      if (this.providerProfileForm.controls.isSameCapabilities.value) {
        const insertData = {
          providerKey: this.provider.providerPK,
          locationKey: this.providerAdditionalAddresses.additionalAddressPK,
          userInformationId: this.userInformationService.userInformation.userInformationID.toString()
        };

        request.push(this.providerCapabilitiesService.copyProviderCapabilitiesToLocation(insertData));
      }

      this.loading = true;
      forkJoin([...request]).subscribe((result: any) => {
        this.loading = false;
        this.snackBarService.success(result[0].message);
        this.providerAdditionalAddressesService.providerAdditionalAddresses = this.providerAdditionalAddresses;
        this.saveComplete.emit(true);
      }, (error) => {
        this.loading = false;
        this.saveComplete.emit(false);
      });
    });
  }
}
