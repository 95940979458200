import { Deserializable } from './deserializable.model';

export class Provider implements Deserializable {

    acceptsAmEx: boolean;
    acceptsBusinessChecks: boolean;
    acceptsComCheck: boolean;
    acceptsDiscover: boolean;
    acceptsMasterCard: boolean;
    acceptsPersonalChecks: boolean;
    acceptsTCheck: boolean;
    acceptsVisa: boolean;
    address1: string;
    address2: string;
    address3: string;
    allLocationsSameHours: boolean;
    authorizeNetcustomerPaymentProfileId: string;
    authorizeNetcustomerProfileId: string;
    billingEmail: string;
    billingPhone: string;
    brochureUploadURL: string;
    city: string;
    companyName: string;
    deleted: boolean;
    description: string;
    dispatchEmail: string;
    dispatchFax: string;
    dispatchPhone: string;
    facebook: string;
    hoursClose: string;
    hoursOpen: string;
    insertedUserKey: string;
    instagram: string;
    linkedIn: string;
    locationMatchesCompleted: boolean;
    logoURL: string;
    ownerFirstName: string;
    ownerLastName: string;
    postalCode: string;
    providerPK: string;
    providerKey: string;
    plan: string;
    state: string;
    systemDefault: boolean;
    twitter: string;
    updatedUserKey: string;
    useCentralBilling: boolean;
    useCentralDispatch: boolean;
    website: string;
    userInformationID: number;
    zoneKey: number;
    countyKey: string;
    latitude: number;
    longitude: number;
    dispatchSolution: boolean;
    tollFreePhone: string;
    afterHoursPhone: string;
    businessHoursPhone: string;
    providerDispatchSoftwareKey: string;
    otherDispatchSoftware: string;
    respondEmailServiceRequest: boolean;
    respondPhoneServiceRequest: boolean;
    electronicDispatchEmail: string;
    electronicDispatchPhone: string;

    deserialize(input: any): any {
        Object.assign(this, input);
        return this;
    }

    prepareSave(data: any): any {
        this.deserialize(data);
        this.systemDefault = false;
        this.deleted = false;
        return this;
    }

}

