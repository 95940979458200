import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProviderOfferingsService {

  constructor(private http: HttpClient) { }

  getProviderOfferings(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}providerOfferings/getProviderOfferings`, data);
  }

  addDeleteProviderOfferings(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}providerOfferings/addDeleteProviderOfferings`, data);
  }

  copyProviderOfferingsToPAA(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}providerOfferings/copyProviderOfferingsToPAA`, data);
  }

}
