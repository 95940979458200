import { Deserializable } from './deserializable.model';

export class ProviderNationalAccounts implements Deserializable {

    deleted: boolean;
    insertedTimestamp: string;
    insertedUserKey: string;
    nationalAccountKey: string;
    providerNationalAccountKey: string;
    providerPK: string;
    systemDefault: boolean;
    updatedTimestamp: string;
    updatedUserKey: string;

    deserialize(input: any): any {
        Object.assign(this, input);
        return this;
    }
}
