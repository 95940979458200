import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Provider } from '../models/providers.model';

@Injectable({
  providedIn: 'root'
})
export class ProvidersService {

  // tslint:disable-next-line: variable-name
  private _provider: Provider;
  // tslint:disable-next-line: variable-name
  private _providerPlan: string;

  public get providerPlan(): string {
    return this._providerPlan;
  }

  public set providerPlan(value: string) {
    this._providerPlan = value;
  }

  constructor(private http: HttpClient) { }

  public get provider(): Provider {
    if (!this._provider) {
      this.provider = JSON.parse(sessionStorage.getItem('provider')) || null;
    }
    return this._provider;
  }

  public set provider(value: Provider) {
    this._provider = value;
    if (value) {
      sessionStorage.setItem('provider', JSON.stringify(value));
    }
  }

  getProvider(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.baseUrl}providers/providersSelect/get`, data).pipe(
      map((res: any) => res.body.map((provider: Provider) => new Provider().deserialize(provider))));
  }

  insertProvider(data: Provider): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.baseUrl}providers/providersInsert`, data).pipe(
      map((res: any) => res.body.map((provider: Provider) => new Provider().deserialize(provider))));
  }

  updateProvider(data: Provider): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.put<any>(`${environment.baseUrl}providers/providersUpdate`, data);
  }

  uploadBrochure(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}uploadBrochure`, data);
  }

  uploadProfilePicture(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}uploadProfilePicture`, data);
  }

  getBreakdownLogo(data: any): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.spApiUrl}fleetassist/licensedowners/get`, data);
  }

  getDispatchSoftwares(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}keyValuePairState/getProviderDispatchSoftwares`);
  }

  addElectronicDispatchPhones(data: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}providerMembershipApplicationsController/addProviderElectronicDispatchPhoneNumberKey`, data);
  }

  addElectronicDispatchEmails(data: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}providerMembershipApplicationsController/addProviderElectronicDispatchEmails`, data);
  }

  addDispatchSoftwareKey(data: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}keyValuePairState/addProviderDispatchSoftwares`, data);
  }
}
