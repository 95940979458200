import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CreditCardService {

  headerOptions = new HttpHeaders()
    .set('content-type', 'application/json')
    .set('x-api-key', '1bgmSCJzJz7v9Q9aUi1K17lplyVdT8IB5OXFkrMK');

  constructor(private http: HttpClient) { }

  creditCardSubscriptionRequest(body: any = {}): Observable<any> {
    return this.http.post<any>(`${environment.restServicesURL}authnet/subscription`, body, { headers: this.headerOptions });
  }

}
