import { Injectable } from '@angular/core';
import S3 from 'aws-sdk/clients/s3';

@Injectable({
  providedIn: 'root'
})
export class UploadToS3Service {

  constructor() { }

  private getS3Bucket(): any {
    return new S3(
      {
        accessKeyId: 'AKIA4WOS3WV2L7ORALET',
        secretAccessKey: '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',
        region: 'us-east-1'
      }
    );
  }

  uploadFile(file: File, fileName: string, path: string, resolve: any): any {
    const contentType = file.type;
    const params = {
      Bucket: 'auxilium-attachments',
      Key: path + '/' + fileName,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType,
    };
    this.getS3Bucket().upload(params, (err, data) => {
      if (err) {
        resolve(null);
      }
      resolve(data);
    });
  }

  deleteFile(fileName: string, path: string, resolve: any): any {
    const params = {
      Bucket: 'auxilium-attachments',
      Key: path + '/' + fileName
    };

    this.getS3Bucket().deleteObject(params, () => {
      resolve();
    });
  }
}
