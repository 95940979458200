import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Attachments } from '../../models/attachments.model';
import { CustomerTermsAndConditions } from '../../models/customer-terms-and-conditions.model';
import { ProviderTermsConditions } from '../../models/provider-terms-conditions.model';
import { Provider } from '../../models/providers.model';
import { UserInformation } from '../../models/user-Information.model';
import { AttachmentsService } from '../../services/attachments.service';
import { OutlawService } from '../../services/outlaw.service';
import { ProviderTermsConditionsService } from '../../services/provider-terms-conditions.service';
import { ProvidersService } from '../../services/providers.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { UploadToS3Service } from '../../services/upload-to-s3.service';
import { UserInformationService } from '../../services/user-information.service';

@Component({
  selector: 'app-electronic-service-offering',
  templateUrl: './electronic-service-offering.component.html',
  styleUrls: ['./electronic-service-offering.component.scss']
})
export class ElectronicServiceOfferingComponent implements OnInit {

  @Output() saveComplete = new EventEmitter<any>();
  @Input() userType = 'provider';
  @Input() baseURL = '';
  @Input() contractDescription = '';

  providerProfileForm: UntypedFormGroup;
  provider: Provider = new Provider();
  userInformation: UserInformation = new UserInformation();
  loading = false;
  iFrameUrl: any;
  serviceAgreement: string;
  outlawDealID: string;
  customerAgreementDetails: any;

  constructor(
    private providersService: ProvidersService,
    private userInformationService: UserInformationService,
    public sanitizer: DomSanitizer,
    private snackBarService: SnackBarService,
    private datePipe: DatePipe,
    private outlawService: OutlawService,
    private uploadToS3Service: UploadToS3Service,
    private attachmentsService: AttachmentsService,
    private providerTermsConditionsService: ProviderTermsConditionsService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.provider = this.providersService.provider || new Provider();
    this.userInformation = this.userInformationService.userInformation || new UserInformation();
    this.generateIframeURL();
  }

  generateIframeURL(): void {
    const url = this.getBaseURL();
    if (url) {
      this.iFrameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(encodeURI(url));
    }
  }

  getBaseURL(): string {
    if (this.userType === 'provider') {
      return this.baseURL
        + '&user.fullName=' + this.userInformation.firstName + ' ' + this.userInformation.lastName
        + '&user.address=' + this.provider.address1 + ' ' + this.provider.city + ' ' + this.provider.state + ' ' + this.provider.postalCode
        + '&user.email=' + this.userInformation.email
        + '&user.org=' + this.provider.companyName
        + '&user.phone=' + this.userInformation.phone
        + '&ProviderKey=' + this.provider.providerPK
        + '&Effective-Date=' + this.datePipe.transform(new Date(), 'MM/dd/yyyy');
    } else {
      this.customerAgreementDetails = JSON.parse(sessionStorage.getItem('customer-agreement'));
      if (!this.customerAgreementDetails) { return; }
      return this.baseURL
        + '&user.fullName=' + this.customerAgreementDetails.fullName
        + '&user.email=' + this.customerAgreementDetails.email
        + '&user.org=' + this.customerAgreementDetails.companyName
        + '&CustomerKey=' + this.customerAgreementDetails.customerKey
        + '&Effective-Date=' + this.datePipe.transform(new Date(), 'MM/dd/yyyy');
    }
  }


  @HostListener('window:message', ['$event'])
  onPostMessage(event): void {
    this.outlawDealID = event.data.args.dealID || null;
    if (this.outlawDealID) {
      this.loading = true;

      const getOutLawPDF = this.userType === 'provider' ?
        this.outlawService.getoutlaw(this.outlawDealID) : this.outlawService.getoutlawCustomerPDF(this.outlawDealID);

      getOutLawPDF.subscribe(result => {
        const fileName = this.getFileName();
        const path = this.getPath();
        const imageBlob = this.dataURItoBlob(result.data);
        const imageFile = new File([imageBlob], fileName, { type: 'application/pdf' });
        const promise = new Promise((resolve) => {
          this.loading = true;
          this.uploadToS3Service.uploadFile(imageFile, fileName, path, resolve);
        });

        promise.then((response: any) => {
          if (response) {
            this.loading = false;
            this.snackBarService.success('service agreement uploaded successfully');
            if (this.serviceAgreement) {
              const lastFileData = this.serviceAgreement.split('/');
              this.deleteFileFromS3(lastFileData[lastFileData.length - 1], path);
            }
            this.serviceAgreement = response.Location;
            if (this.userType === 'provider') {
              this.saveServiceAgreement();
            } else {
              this.saveCustomerServiceAgreement();
            }
          } else {
            this.snackBarService.error('failed to upload service agreement');
            this.loading = false;
          }
        });
      }, (error) => {
        this.loading = false;
      });
    }
  }

  getPath(): any {
    return this.userType === 'provider' ? 'OtherDocuments/ProviderDocuments/ProviderAgreements' : 'OtherDocuments/ClientDocuments';
  }

  getFileName(): string {
    if (this.userType === 'provider') {
      return `TandC_${this.datePipe.transform(new Date(), 'MM_dd_yyyy_HH_mm_ss')}_ProviderKey_${this.provider.providerPK}.pdf`;
    } else {
      return `TandC_${this.datePipe.transform(new Date(), 'MM_dd_yyyy_HH_mm_ss')}_CustomerKey_${this.customerAgreementDetails.customerKey}.pdf`;
    }
  }

  deleteFileFromS3(lastFileName, path): void {
    const promise = new Promise((resolve) => {
      this.uploadToS3Service.deleteFile(lastFileName, path, resolve);
    });

    promise.then(() => {
      this.loading = false;
    });
  }

  dataURItoBlob(dataURI): Blob {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/pdf' });
    return blob;
  }

  saveServiceAgreement(): void {
    const attachmentFilter = new Attachments().deserialize({
      associationKey: this.provider.providerPK,
      associationTypeKey: 'c9dc716d-b1fe-45e6-8cd7-78a7c21c8fd4',
      attachmentTypeKey: 'Terms Conditions',
      url: this.serviceAgreement,
      insertedUserKey: this.userInformation.userInformationID,
      updatedUserKey: this.userInformation.userInformationID,
    });

    const attachmentRequest = this.attachmentsService.insertUpdateAttachments(attachmentFilter);

    const tandcFilter = new ProviderTermsConditions().deserialize({
      outlawDealID: this.outlawDealID,
      providerKey: this.provider.providerPK,
      url: this.serviceAgreement,
      insertedUserKey: this.userInformation.userInformationID,
      updatedUserKey: this.userInformation.userInformationID
    });

    const tandcRequest = this.providerTermsConditionsService.insertUpdateProviderTermsAndConditions(tandcFilter);
    this.loading = true;
    forkJoin([attachmentRequest, tandcRequest]).subscribe(() => {
      this.loading = false;
      this.saveComplete.emit(true);
    }, () => {
      this.loading = false;
      this.saveComplete.emit(false);
    });
  }

  saveCustomerServiceAgreement(): void {
    const attachmentFilter = new Attachments().deserialize({
      associationKey: this.customerAgreementDetails.customerKey,
      documentTypeKey: 'Terms Conditions',
      url: this.serviceAgreement,
      insertedUserKey: this.customerAgreementDetails.userKey,
      updatedUserKey: this.customerAgreementDetails.userKey
    });

    const attachmentRequest = this.attachmentsService.insertUpdateCustomerAttachments(attachmentFilter);

    const tandcFilter = new CustomerTermsAndConditions().deserialize({
      description: this.contractDescription,
      customerKey: this.customerAgreementDetails.customerKey,
      insertedUserKey: this.customerAgreementDetails.userKey,
      updatedUserKey: this.customerAgreementDetails.userKey
    });

    const tandcRequest = this.providerTermsConditionsService.insertUpdateCustomerTermsAndConditions(tandcFilter);
    this.loading = true;
    forkJoin([attachmentRequest, tandcRequest]).subscribe((res) => {
      this.loading = false;
      this.saveComplete.emit(true);
    }, () => {
      this.loading = false;
      this.saveComplete.emit(false);
    });
  }

  cancelAgreement(): void {
    this.loading = true;
    this.providerTermsConditionsService.cancelCustomerAgreement(this.customerAgreementDetails.customerKey).subscribe(res => {
      this.loading = false;
      sessionStorage.removeItem('customer-agreement');
      this.snackBarService.success('SuccessFully canceled agreement', 'Success');
      this.router.navigate(['/pages/home/dashboard'], { relativeTo: this.route });
    }, () => {
      this.loading = false;
      this.snackBarService.success('Failed to canceled', 'Error');
    });
  }
}