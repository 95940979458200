import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Makes } from '../models/makes.model';

@Injectable({
  providedIn: 'root'
})
export class MakesService {

  constructor(private http: HttpClient) { }

  getMakes(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.baseUrl}keyValuePairState/makesSelect/get`, data).pipe(
      map((res: any) => res.body.map((make: Makes) => new Makes().deserialize(make))));
  }
}
