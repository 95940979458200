import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProvidersService } from '@share';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-matching-provider',
  templateUrl: './matching-provider.component.html',
  styleUrls: ['./matching-provider.component.scss']
})
export class MatchingProviderComponent implements OnInit {

  providerData: any;
  parentData: any = false;
  loading = false;

  constructor(
    protected dialogRef: MatDialogRef<MatchingProviderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private providersService: ProvidersService
  ) { }

  ngOnInit(): void {
    this.providerData = this.data.provider;
    this.parentData = this.data.parent;
  }

  close(): void {
    this.dialogRef.close(false);
  }

  goToServiceProvider(): void {
    const provider = this.providersService.provider;
    provider.deleted = true;
    this.loading = true;
    this.providersService.updateProvider(provider).subscribe(() => {
      this.loading = false;
      this.dialogRef.close(true);
      window.open(environment.serviceProviderURL, '_blank');
    }, (error) => {
      this.loading = false;
    });
  }

}
