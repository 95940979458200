<div class="welcomeScreen">
    <div class="container">
        <div class="row" style="margin-top: 15%;">
            <div class="col-md-12">
                <div class="loader6">
                    <span class="loader-inner"></span>
                </div>
            </div>
        </div>
    </div>
</div>