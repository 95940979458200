import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserInformationService } from '@share';
import { EmailNotificationService } from './services/email-notification.service';

@Component({
  selector: 'app-sign-up-success',
  templateUrl: './sign-up-success.component.html',
  styleUrls: ['./sign-up-success.component.scss']
})
export class SignUpSuccessComponent implements OnInit {

  loading = false;

  constructor(
    protected ref: MatDialogRef<SignUpSuccessComponent>,
    @Inject(MAT_DIALOG_DATA) public user: any,
    private userInformationService: UserInformationService,
    private emailNotificationService: EmailNotificationService,
  ) { }

  ngOnInit(): void {
  }

  sendNotificationEmail(): void {
    const user: any = this.userInformationService?.userInformation || [];
    const params = {
      emailTo: user.email,
      emailFrom: 'NoReply@breakdownnow.com',
      subject: 'ServicePlus Network Application Receipt Acknowledgement',
      body: `<p>Your ServicePlus Network application has been received, bringing you one step closer to joining the nation’s premier network of transportation maintenance service providers and advertising on BreakdownNOW.
      <br><br>
      Once your application is approved, we’ll send you an email with a link to your account.
      <br><br>
      Processing may take up to 24 hours based on volume.
      <br><br>
      Thank you
      <br>
      ServicePlus Network Administration
      </p>`,
      communicationMethodKey: 'Email',
      insertedUserKey: user.userInformationID,
    };

    this.loading = true;
    this.emailNotificationService.sendEmailNotification(params).subscribe((res: any) => {
      this.loading = false;
      this.submit(true);
    }, (error) => {
      this.loading = false;
      this.submit(true);
    });
  }

  submit(val): void {
    this.ref.close(val);
  }
}
