import { Deserializable } from '@share';

export class ProviderElectronicDispatchPhoneNumber implements Deserializable {
    additionalAddressPK: string;
    deleted: boolean;
    insertedTimestamp: string;
    insertedUserKey: string;
    providerElectronicDispatchPhoneNumberKey: string;
    providerPK: string;
    systemDefault: boolean;
    updatedTimestamp: string;
    updatedUserKey: string;

    deserialize(input: any): any {
        Object.assign(this, input);
        return this;
    }
}
