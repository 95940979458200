import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { NationalAccounts } from '../models/national-accounts.model';

@Injectable({
  providedIn: 'root'
})
export class KvpNationalAccountsService {

  constructor(private http: HttpClient) { }

  getKVPNationalAccounts(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.baseUrl}keyValuePairs/nationalAccounts/get`, data).pipe(
      map((res: any) => res.body.map((accounts: NationalAccounts) => new NationalAccounts().deserialize(accounts))));
  }
}
