<ngx-loading [show]="loading"></ngx-loading>
<div class="row mt-1 px-md-2 mb-2 text-md-center">
    <h6 class="col-md-12">
        Select the National Billing Accounts you support
    </h6>
    <label class="col-12 bg-gradient-light text-danger">
        Do not select any that you cannot support; doing so is a violation of the Terms & Conditions of membership in
        the Service Plus Network
    </label>
</div>
<div class="row mt-2 px-md-5 mb-5">
    <ng-container *ngFor="let account of providerNationalAccounts">
        <div class="col-md-3 mt-2">
            <mat-checkbox [labelPosition]="labelPosition" [(ngModel)]="!account.deleted"
                (change)="addProviderNationalAccount(account)">
                <strong>{{ account.nationalAccountKey }}</strong>
            </mat-checkbox>
        </div>
    </ng-container>
</div>
