import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Provider } from '../../models/providers.model';
import { ProvidersService } from '../../services/providers.service';
import { UserInformationService } from '../../services/user-information.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { UploadToS3Service } from '../../services/upload-to-s3.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-company-description',
  templateUrl: './company-description.component.html',
  styleUrls: ['./company-description.component.scss']
})
export class CompanyDescriptionComponent implements OnInit {

  @Output() saveComplete = new EventEmitter<any>();

  providerProfileForm: UntypedFormGroup;
  provider: Provider = new Provider();
  loading = false;
  imgURL: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private providersService: ProvidersService,
    private userInformationService: UserInformationService,
    private snackBarService: SnackBarService,
    private uploadToS3Service: UploadToS3Service,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.provider = this.providersService.provider || new Provider();
    this.providerProfileForm = this.formBuilder.group({
      providerPK: [this.provider.providerPK],
      description: [this.provider.description || '', [Validators.required]],
      logoURL: [this.provider.logoURL || ''],
    });
    this.imgURL = this.provider.logoURL || '';
  }

  handleError = (control: string, error: string) => {
    return this.providerProfileForm.controls[control].hasError(error);
  }

  updateProviderDetails(): any {
    if (this.providerProfileForm.invalid) {
      this.providerProfileForm.markAllAsTouched();
      return false;
    }

    this.provider = Object.assign(this.provider, new Provider().prepareSave(this.providerProfileForm.value));
    this.provider.updatedUserKey = this.userInformationService.userInformation.userInformationID.toString();
    this.loading = true;
    this.providersService.updateProvider(this.provider).subscribe((result) => {
      this.loading = false;
      this.snackBarService.success(result.message);
      this.providersService.provider = this.provider;
      this.saveComplete.emit(true);
    }, (error) => {
      this.loading = false;
      this.saveComplete.emit(false);
    });
  }

  uploadLogo(files: any, event: any): boolean {
    const fileData: File = event.target.files[0] as File;

    if (files.length === 0) {
      return false;
    }

    const mimeType = fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      this.snackBarService.warning('Only image format is supported.');
      return false;
    }

    const size = fileData.size;
    if (Math.round(size / 1024) > 5120) {
      this.snackBarService.warning('Size should not exceed 5MB.');
      return false;
    }

    const extention = fileData.name.split('.');
    const fileName = `Logo_${this.datePipe.transform(new Date(), 'MM_dd_yyyy_HH_mm_ss')}_ProviderKey_${this.provider.providerPK}.${extention[extention.length - 1]}`;
    const path = 'OtherDocuments/ProviderDocuments/ProviderLogo';
    const lastFileData = this.imgURL.split('/');
    const promise = new Promise((resolve) => {
      this.loading = true;
      this.uploadToS3Service.uploadFile(fileData, fileName, path, resolve);
    });

    promise.then((response: any) => {
      if (response) {
        this.snackBarService.success('logo uploaded successfully');
        this.providerProfileForm.controls.logoURL.setValue(response.Location);
        this.imgURL = response.Location;
        if (lastFileData.length > 0) {
          this.deleteFileFromS3(lastFileData[lastFileData.length - 1], path);
        }
      } else {
        this.snackBarService.error('failed to upload logo');
        this.loading = false;
      }
    });
  }

  deleteFileFromS3(lastFileName, path): void {
    const promise = new Promise((resolve) => {
      this.uploadToS3Service.deleteFile(lastFileName, path, resolve);
    });

    promise.then(() => {
      this.loading = false;
    });
  }
}
