<ngx-loading [show]="loading"></ngx-loading>
<div class="row px-2 ml-4 mr-4 bg-dark p-2 pr-3 mb-2">
  <label class="col-md-6 text-left text-white"> </label>
  <input class="col-md-6" [(ngModel)]="service" placeholder="Search by Keyword" />
</div>
<div class="row mt-2 px-5 mb-5">
  <ng-container *ngFor="let data of serviceData | recursiveFilter: service :  'name' : 'children' ">
    <tree-root [nodes]="[data]" #tree [options]="options" class="col-md-4 mt-2">
      <ng-template #treeNodeTemplate let-node="node" let-index="index">
        <mat-checkbox [checked]="node.data.deleted === 0" (change)="onSelectedChange(node, $event)">
          <strong>{{ node.data.name }}</strong>
        </mat-checkbox>
      </ng-template>
    </tree-root>
  </ng-container>
</div>