import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProviderAdditionalAddresses } from '../models/provider-additional-addresses.model';

@Injectable({
  providedIn: 'root'
})
export class ProviderAdditionalAddressesService {

  // tslint:disable-next-line: variable-name
  private _providerAdditionalAddresses: ProviderAdditionalAddresses;

  constructor(private http: HttpClient) { }

  public get providerAdditionalAddresses(): ProviderAdditionalAddresses {
    if (!this._providerAdditionalAddresses) {
      this.providerAdditionalAddresses = JSON.parse(sessionStorage.getItem('providerAdditionalAddresses')) || null;
    }
    return this._providerAdditionalAddresses;
  }

  public set providerAdditionalAddresses(value: ProviderAdditionalAddresses) {
    this._providerAdditionalAddresses = value;
    if (value) {
      sessionStorage.setItem('providerAdditionalAddresses', JSON.stringify(value));
    }
  }

  insertProviderAdditionalAddresses(data: ProviderAdditionalAddresses): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.baseUrl}providerAddressess/providerAdditionalAddressessInsert`, data).pipe(
      map((res: any) => res.body.map((providerAdditionalAddresses: ProviderAdditionalAddresses) =>
        new ProviderAdditionalAddresses().deserialize(providerAdditionalAddresses))));
  }

  updateProviderAdditionalAddresses(data: ProviderAdditionalAddresses): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.put<any>(`${environment.baseUrl}providerAddressess/providerAdditionalAddressessUpdate`, data);
  }
}
