import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserInformation } from '../models/user-Information.model';

@Injectable({
  providedIn: 'root'
})
export class UserInformationService {

  // tslint:disable-next-line: variable-name
  private _userInformation: UserInformation;

  constructor(private http: HttpClient) { }

  public get userInformation(): UserInformation {
    if (!this._userInformation) {
      this.userInformation = JSON.parse(sessionStorage.getItem('userInformation')) || null;
    }
    return this._userInformation;
  }

  public set userInformation(value: UserInformation) {
    this._userInformation = value;
    if (value) {
      sessionStorage.setItem('userInformation', JSON.stringify(value));
    }
  }

  insertUserInformation(data: UserInformation): Observable<any> {
    if (data.deleteFlag === undefined) {
      data.deleteFlag = false;
    }
    return this.http.post<any>(`${environment.baseUrl}userInformation/userInformationInsert`, data).pipe(
      map((res: any) => res.body.map((userInformation: UserInformation) => new UserInformation().deserialize(userInformation))));
  }

  updateUserInformation(data: UserInformation): Observable<any> {
    if (data.deleteFlag === undefined) {
      data.deleteFlag = false;
    }
    return this.http.put<any>(`${environment.baseUrl}userInformation/userInformationUpdate`, data);
  }

  getUserInformation(data: UserInformation): Observable<any> {
    if (data.deleteFlag === undefined) {
      data.deleteFlag = false;
    }
    return this.http.post<any>(`${environment.baseUrl}userInformation/userInformationSelect/get`, data).pipe(
      map((res: any) => res.body.map((userInformation: UserInformation) => new UserInformation().deserialize(userInformation))));
  }

  sendEmailToUser(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}sendEmail`, data);
  }

  getUserAndProviderDetailsByEmail(data: UserInformation): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}userInformation/getUserAndProviderDetailsByEmail`, data);
  }

  sendEmailToAdmin(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}sendMailtoAdmin`, data);
  }

}
