import { Deserializable } from './deserializable.model';

export class NationalAccounts implements Deserializable {

    deleted: boolean;
    nationalAccountKey: string;
    tireAccount: boolean;
    systemDefault: boolean;

    deserialize(input: any): any {
        Object.assign(this, input);
        return this;
    }
}
