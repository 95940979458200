<ngx-loading [show]="loading"></ngx-loading>
<mat-dialog-content class="mat-typography">
  <h2 class="font-weight-bold">{{user.name}}</h2>
  <h3><span class="font-weight-bold">Congratulations!</span> You are one step closer to joining the nation’s premier
    network of transportation maintenance service providers and advertising on BreakdownNOW.
  </h3>
  <h4>Once your application is approved, we’ll send you an email with a link to your account. </h4>
  <h4>Processing may take up to 24 hours based on volume. </h4>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="m-1" mat-dialog-close mat-raised-button color="warn" (click)="sendNotificationEmail()">OK</button>
</mat-dialog-actions>