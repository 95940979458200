import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { patterns } from 'src/assets/patterns';
import { ProviderAdditionalAddresses } from '../../models/provider-additional-addresses.model';
import { Provider } from '../../models/providers.model';
import { ProviderAdditionalAddressesService } from '../../services/provider-additional-addresses.service';
import { ProvidersService } from '../../services/providers.service';
import { UserInformationService } from '../../services/user-information.service';
import { SnackBarService } from '../../services/snack-bar.service';

@Component({
  selector: 'app-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.scss']
})
export class BillingDetailsComponent implements OnInit {

  @Input() parent: boolean;
  @Output() saveComplete = new EventEmitter<any>();

  providerProfileForm: UntypedFormGroup;
  provider: Provider = new Provider();
  providerAdditionalAddresses: ProviderAdditionalAddresses = new ProviderAdditionalAddresses();
  addrLableText: string;
  loading = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private providersService: ProvidersService,
    private userInformationService: UserInformationService,
    private providerAdditionalAddressesService: ProviderAdditionalAddressesService,
    private snackBarService: SnackBarService
  ) { }

  ngOnInit(): void {
    this.provider = this.providersService.provider || new Provider();
    this.providerAdditionalAddresses = this.providerAdditionalAddressesService.providerAdditionalAddresses
      || new ProviderAdditionalAddresses();
    this.addrLableText = this.parent ? '' : 'Physical';

    this.providerProfileForm = this.formBuilder.group({
      providerPK: [''],
      additionalAddressPK: [''],
      useCentralBilling: ['', [Validators.required]],
      billingPhone: ['', [Validators.required]],
      billingEmail: ['', [Validators.required, Validators.pattern(patterns.emailPattern)]],
    });

    this.setFormData();
  }

  setFormData(): void {
    if (this.parent) {
      this.providerProfileForm.patchValue({
        providerPK: this.provider.providerPK || '',
        useCentralBilling: this.provider.useCentralBilling,
        billingPhone: this.provider.billingPhone || '',
        billingEmail: this.provider.billingEmail || '',
      });
    } else {
      this.providerProfileForm.patchValue({
        additionalAddressPK: this.providerAdditionalAddresses.additionalAddressPK || '',
        providerPK: this.providerAdditionalAddresses.providerPK || '',
        billingPhone: this.provider.useCentralBilling ? this.provider.billingPhone : (this.providerAdditionalAddresses.billingPhone || ''),
        billingEmail: this.provider.useCentralBilling ? this.provider.billingEmail : (this.providerAdditionalAddresses.billingEmail || ''),
      });
    }

    this.setFormValidation();
  }

  setFormValidation(): void {
    if (this.parent) {
      this.providerProfileForm.controls.useCentralBilling.setValidators([Validators.required]);
    } else {
      this.providerProfileForm.controls.useCentralBilling.setValidators(null);
      if (this.provider.useCentralBilling) {
        this.providerProfileForm.controls.billingPhone.setValue(this.provider.billingPhone);
        this.providerProfileForm.controls.billingEmail.setValue(this.provider.billingEmail);
        this.providerProfileForm.controls.billingPhone.setValidators(null);
        this.providerProfileForm.controls.billingEmail.setValidators(null);
        this.providerProfileForm.controls.billingPhone.updateValueAndValidity();
        this.providerProfileForm.controls.billingEmail.updateValueAndValidity();
      }
    }
    this.providerProfileForm.controls.useCentralBilling.updateValueAndValidity();
  }

  handleError = (control: string, error: string) => {
    return this.providerProfileForm.controls[control].hasError(error);
  }

  updateProviderDetails(): any {
    if (this.providerProfileForm.invalid) {
      this.providerProfileForm.markAllAsTouched();
      return false;
    }

    this.provider = Object.assign(this.provider, new Provider().prepareSave(this.providerProfileForm.value));
    this.provider.updatedUserKey = this.userInformationService.userInformation.userInformationID.toString();
    this.loading = true;
    this.providersService.updateProvider(this.provider).subscribe((result) => {
      this.loading = false;
      this.snackBarService.success(result.message);
      this.providersService.provider = this.provider;
      this.saveComplete.emit(true);
    }, (error) => {
      this.loading = false;
      this.saveComplete.emit(false);
    });
  }

  updateProviderAdditionalAddressDetails(): any {
    if (this.providerProfileForm.invalid) {
      this.providerProfileForm.markAllAsTouched();
      return false;
    }

    this.providerAdditionalAddresses = Object.assign(
      this.providerAdditionalAddresses, new ProviderAdditionalAddresses().prepareSave(this.providerProfileForm.value)
    );
    this.provider.updatedUserKey = this.userInformationService.userInformation.userInformationID.toString();
    this.loading = true;
    this.providerAdditionalAddressesService.updateProviderAdditionalAddresses(this.providerAdditionalAddresses).subscribe((result) => {
      this.loading = false;
      this.snackBarService.success(result.message);

      this.providerAdditionalAddressesService.providerAdditionalAddresses = this.providerAdditionalAddresses;
      this.saveComplete.emit(true);
    }, (error) => {
      this.loading = false;
      this.saveComplete.emit(false);
    });
  }

}
