<ngx-loading [show]="loading"></ngx-loading>
<form [formGroup]="providerProfileForm" *ngIf="parent || (!parent && !provider.useCentralDispatch)">
    <div class="row border shadow-sm pt-2 pb-2">
        <div class="col-12 col-md-4" *ngIf="parent">
            <mat-form-field>
                <mat-select matNativeControl formControlName="useCentralDispatch"
                    placeholder="Do you use Central Dispatch" required>
                    <mat-option [value]="true">Yes</mat-option>
                    <mat-option [value]="false">No</mat-option>
                </mat-select>
                <mat-error *ngIf="handleError('useCentralDispatch', 'required')">
                    is Central Dispatch is required.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-12 col-md-4" *ngIf="!parent">
            <mat-form-field>
                <mat-select matNativeControl formControlName="useCentralDispatch"
                    placeholder="Is your dispatch operation centralized?" disabled="true" required>
                    <mat-option [value]="true">Yes</mat-option>
                    <mat-option [value]="false">No</mat-option>
                </mat-select>
                <mat-error *ngIf="handleError('useCentralDispatch', 'required')">
                    is Central Dispatch is required.
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="row border shadow-sm pt-2 pb-2 mt-2">
        <div class="col-md-12" *ngIf="!parent">
            <label>How do you want to be contacted for dispatching?</label>
        </div>
        <div class="col-md-4">
            <mat-form-field>
                <input type="text" matInput formControlName="tollFreePhone" placeholder="Toll Free Phone"
                    mask="000-000-0000">
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field>
                <input type="text" matInput formControlName="dispatchEmail" placeholder="Dispatch Email">
                <mat-error *ngIf="handleError('dispatchEmail', 'pattern')">
                    Enter valid Email Address.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field>
                <input type="text" matInput formControlName="businessHoursPhone" placeholder="Business Phone"
                    mask="000-000-0000">
                <mat-error *ngIf="handleError('businessHoursPhone', 'required')">
                    Business Phone is required.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field>
                <input type="text" matInput formControlName="dispatchFax" placeholder="Dispatch Fax"
                    mask="000-000-0000">
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field>
                <input type="text" matInput formControlName="afterHoursPhone" placeholder="After Hours Phone"
                    mask="000-000-0000">
            </mat-form-field>
        </div>
    </div>

    <div class="row border shadow-sm pt-2 pb-2 mt-2" *ngIf="parent">
        <div class="col-md-4">
            <mat-form-field>
                <mat-select matNativeControl formControlName="dispatchSolution"
                    placeholder="Do you use a software-based, industry dispatching solution ?"
                    (selectionChange)="setFormValidation()">
                    <mat-option [value]="true">Yes</mat-option>
                    <mat-option [value]="false">No</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-4" *ngIf="providerProfileForm.controls['dispatchSolution'].value == true">
            <mat-form-field>
                <mat-select matNativeControl formControlName="providerDispatchSoftwareKey"
                    placeholder="Select Dispatch Software" (selectionChange)="setFormValidation()" required>
                    <mat-option *ngFor="let dispatchSoftware of dispatchSoftwares"
                        [value]="dispatchSoftware.ProviderDispatchSoftwareKey">
                        {{dispatchSoftware.ProviderDispatchSoftwareKey}}</mat-option>
                </mat-select>
                <mat-error *ngIf="handleError('providerDispatchSoftwareKey', 'required')">
                    Please select Dispatch software.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-4"
            *ngIf="providerProfileForm.controls['providerDispatchSoftwareKey'].value == 'Other' && providerProfileForm.controls['dispatchSolution'].value">
            <mat-form-field>
                <input type="text" matInput formControlName="otherDispatchSoftware"
                    placeholder="Please Enter Dispatching Software You Use" required>
                <mat-error *ngIf="handleError('otherDispatchSoftware', 'required')">
                    Please enter Other Dispatch software.
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <!-- <div class="row border shadow-sm pt-2 pb-2 mt-2" *ngIf="parent || showElectronicDispatch"> -->
    <div class="row border shadow-sm pt-2 pb-2 mt-2">
        <div class="col-md-12">
            <span>BreakdownNOW has an electonic dispatch feature where fleets can communicate with service providers who
                have electronic capabilities.</span>
        </div>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field>
                        <mat-select matNativeControl formControlName="respondEmailServiceRequest"
                            (selectionChange)="setFormValidation()"
                            placeholder="Can you respond to emailed service request within 5 min's or less ?">
                            <mat-option [value]="true">Yes</mat-option>
                            <mat-option [value]="false">No</mat-option>
                        </mat-select>
                        <mat-error *ngIf="handleError('respondEmailServiceRequest', 'required')">
                            Please select email service request.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field>
                        <mat-select matNativeControl formControlName="respondPhoneServiceRequest"
                            (selectionChange)="setFormValidation()"
                            placeholder="Can you respond to texted service request within 5 min's or less ?">
                            <mat-option [value]="true">Yes</mat-option>
                            <mat-option [value]="false">No</mat-option>
                        </mat-select>
                        <mat-error *ngIf="handleError('respondPhoneServiceRequest', 'required')">
                            Please select phone service request.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field *ngIf="providerProfileForm.controls['respondEmailServiceRequest'].value">
                        <input type="text" matInput formControlName="electronicDispatchEmail"
                            placeholder="Please Enter Electronic Dispatch Email">

                        <button matSuffix mat-button color="primary" class="add_to_list"
                            (click)="addEmailAndPhone('email')" matTooltip="Add" [disabled]="(!parent && provider.useCentralDispatch)">
                            Add To List
                        </button>

                        <button mat-button matSuffix mat-icon-button (click)="clearEmailAndPhone('email')"
                            *ngIf="providerProfileForm.controls['electronicDispatchEmail'].value" matTooltip="Clear"
                            [disabled]="(!parent && provider.useCentralDispatch)">
                            <mat-icon class="font-larger">clear</mat-icon>
                        </button>
                        <a href=""></a>
                        <mat-error *ngIf="handleError('electronicDispatchEmail', 'required')">
                            Please enter email.
                        </mat-error>
                        <mat-error *ngIf="handleError('electronicDispatchEmail', 'pattern')">
                            Enter valid Email Address.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field *ngIf="providerProfileForm.controls['respondPhoneServiceRequest'].value">
                        <input type="text" matInput formControlName="electronicDispatchPhone"
                            placeholder="Please Enter Electronic Dispatch Phone" mask="000-000-0000">
                        <button matSuffix mat-button color="primary" (click)="addEmailAndPhone('phone')"
                            class="add_to_list" matTooltip="Add" [disabled]="(!parent && provider.useCentralDispatch)">
                            Add To List
                        </button>
                        <button mat-button matSuffix mat-icon-button (click)="clearEmailAndPhone('phone')"
                            matTooltip="Clear" *ngIf="providerProfileForm.controls['electronicDispatchPhone'].value"
                            [disabled]="(!parent && provider.useCentralDispatch)">
                            <mat-icon class="font-larger">clear</mat-icon>
                        </button>
                        <mat-error *ngIf="handleError('electronicDispatchPhone', 'required')">
                            Please enter phone service request.
                        </mat-error>
                        <mat-error *ngIf="handleError('electronicDispatchPhone', 'pattern')">
                            Enter valid Phone.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 custom-height">
                    <div class="row"
                        *ngIf="(electronicDispatchEmails.length > 0) && (providerProfileForm.controls['respondEmailServiceRequest'].value)">
                        <div class="col-md-6" *ngFor="let email of electronicDispatchEmails">
                            <div class="row border m-2">
                                <div class="col-md-8 mt-2">
                                    {{email.providerElectronicDispatchEmailKey}}
                                </div>
                                <div class="col-md-4 text-right">
                                    <button mat-button matSuffix mat-icon-button
                                        (click)="deleteProviderElectronicDispatchEmail(email)" matTooltip="Delete"
                                        [disabled]="(!parent && provider.useCentralDispatch)">
                                        <mat-icon class="font-larger">delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 custom-height">
                    <div class="row"
                        *ngIf="(electronicDispatchPhones.length > 0) && (providerProfileForm.controls['respondPhoneServiceRequest'].value)">
                        <div class="col-md-6" *ngFor="let phone of electronicDispatchPhones">
                            <div class="row border m-2">
                                <div class="col-md-8 mt-2">
                                    {{phone.providerElectronicDispatchPhoneNumberKey | mask: '000-000-0000'}}
                                </div>
                                <div class="col-md-4 text-right">
                                    <button mat-button matSuffix mat-icon-button
                                        (click)="deleteProviderDispatchPhoneNumber(phone)" matTooltip="Delete"
                                        [disabled]="(!parent && provider.useCentralDispatch)">
                                        <mat-icon class="font-larger">delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<div class="col-12 text-center" *ngIf="(!parent && provider.useCentralDispatch)">
    <div class="alert alert-info" role="alert">
        Central Dispatch
    </div>
</div>