<ngx-loading [show]="loading"></ngx-loading>
<div class="table-header asset-class mt-2">
  <div class="row mt-2">
    <label class="col text-center">
      <h5>Application Review</h5>
    </label>
  </div>
  <div class="row m-2">
    <div class="col-md-12 px-0 text-right">
      <button class="btn btn-sm common-btn" (click)="createPdf()">
        <i class="fa fa-download" aria-hidden="true"></i> Download PDF
      </button>
    </div>
  </div>
  <div class="col-md-12 px-2 expansion-div">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let item of providers; let index = index" (opened)="panelOpenState = true"
        (closed)="panelOpenState = false">
        <mat-expansion-panel-header class="bg-light text-dark">
          <mat-panel-title class="font-weight-bold">
            {{getAccordionTitle(item.additionalAddressPK, item.companyName, index)}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row expansion-content">
          <div class="col-md-12 py-1">
            <div class="row">
              <div class="col-md-12 font-weight-bold common-txt-clr">
                {{ item.additionalAddressPK ? 'Location Details:' : 'Company Details:' }}
              </div>
            </div>
            <div class="row" *ngIf="!item.additionalAddressPK">
              <div class="col-md-4">
                <span class="font-weight-bold">Company Name: </span>
                {{ item.companyName }}
              </div>
              <div class="col-md-4">
                <span class="font-weight-bold">Primary Contact First Name: </span>
                {{ item.ownerFirstName }}
              </div>
              <div class="col-md-4">
                <span class="font-weight-bold">Primary Contact Last Name: </span>
                {{ item.ownerLastName }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <span class="font-weight-bold">
                  {{ item.additionalAddressPK ? 'Physical Address 1: ' : 'Address 1: ' }}
                </span>
                {{ item.address1 }}
              </div>
              <div class="col-md-4">
                <span class="font-weight-bold">
                  {{ item.additionalAddressPK ? 'Physical Address 2: ' : 'Address 2: ' }}
                </span>
                {{ item.address2 }}
              </div>
              <div class="col-md-4">
                <span class="font-weight-bold">
                  {{ item.additionalAddressPK ? 'Physical Address 3: ' : 'Address 3: ' }}</span>
                {{ item.address3 }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <span class="font-weight-bold">
                  {{ item.additionalAddressPK ? 'Physical City: ' : 'City: ' }}</span>
                {{ item.city }}
              </div>
              <div class="col-md-4">
                <span class="font-weight-bold">
                  {{ item.additionalAddressPK ? 'Physical State/Province: ' : 'State/Province: ' }}</span>
                {{ item.state }}
              </div>
              <div class="col-md-4">
                <span class="font-weight-bold">
                  {{ item.additionalAddressPK ? 'Physical Postal Code: ' : 'Postal Code: ' }}</span>
                {{ item.postalCode }}
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12 font-weight-bold common-txt-clr">
                Dispatch:
              </div>
            </div>
            <div class="row">
              <div class="col-md-4" *ngIf="!item.additionalAddressPK">
                <span class="font-weight-bold">
                  {{ item.additionalAddressPK ? 'Is your dispatch operation centralized?' :
                  'Do you use Central Dispatch:' }}</span>
                {{ item.useCentralDispatch ? 'Yes':'No' }}
              </div>
              <div class="col-md-4">
                <span class="font-weight-bold">Toll Free Phone: </span>
                {{ item.tollFreePhone | mask: '000-000-0000' }}
              </div>
              <div class="col-md-4">
                <span class="font-weight-bold">Dispatch Email: </span>
                {{ item.dispatchEmail }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <span class="font-weight-bold">Business Phone: </span>
                {{ item.businessHoursPhone | mask: '000-000-0000' }}
              </div>
              <div class="col-md-4">
                <span class="font-weight-bold">Dispatch Fax: </span>
                {{ item.dispatchFax | mask: '000-000-0000' }}
              </div>
              <div class="col-md-4">
                <span class="font-weight-bold">After Hours Phone: </span>
                {{ item.afterHoursPhone | mask: '000-000-0000' }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4" *ngIf="item.providerDispatchSoftwareKey && !item.additionalAddressPK">
                <span class="font-weight-bold">Dispatch Software: </span>
                {{ item.providerDispatchSoftwareKey }}
              </div>
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-12 font-weight-bold">
                    Electronic Dispatch Emails
                  </div>
                  <div class="col-md-12">
                    <ul>
                      <li *ngFor="let electronicDispatchEmail of item.electronicDispatchEmails">
                        {{ electronicDispatchEmail }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-12 font-weight-bold">
                    Electronic Dispatch Phones
                  </div>
                  <div class="col-md-12">
                    <ul>
                      <li *ngFor="let electronicDispatchPhone of item.electronicDispatchPhones">
                        {{ electronicDispatchPhone | mask: '000-000-0000' }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12 font-weight-bold common-txt-clr">
                Billing:
              </div>
            </div>
            <div class="row">
              <div class="col-md-4" *ngIf="!item.additionalAddressPK">
                <span class="font-weight-bold">Do you use Central Billing: </span>
                {{ item.useCentralBilling ? 'Yes' : 'No' }}
              </div>
              <div class="col-md-4">
                <span class="font-weight-bold">Billing Phone: </span>
                {{ item.billingPhone | mask: '000-000-0000' }}
              </div>
              <div class="col-md-4">
                <span class="font-weight-bold">Billing Email: </span>
                {{ item.billingEmail }}
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row" *ngIf="!item.additionalAddressPK">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12 font-weight-bold common-txt-clr">
                National Accounts:
              </div>
            </div>
            <ul class="row">
              <li class="col-md-2" *ngFor="let nationalAccount of item.nationalAccounts">
                {{ nationalAccount }}
              </li>
            </ul>
          </div>
        </div>
        <hr *ngIf="!item.additionalAddressPK">
        <div class="row" *ngIf="!item.additionalAddressPK">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12 font-weight-bold common-txt-clr">
                Description:
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <span class="font-weight-bold">Enter description that will display under your name
                  in Breakdown Now search result: </span>
                {{ item.description }}
              </div>
            </div>
          </div>
        </div>
        <hr *ngIf="!item.additionalAddressPK">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12 font-weight-bold common-txt-clr">
                Hours of Operation:
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive mt-2">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th *ngFor="let day of days">{{day}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td *ngFor="let data of item?.hrsOfOperation">
                          <div>
                            <div class="d-inline-block m-w-60" *ngIf="(data?.closed == 0)">
                              {{ data?.openTime | differenceInHoursBetweenTwoTimes: data?.closeTime }}
                            </div>
                            <div class="d-inline-block m-w-60" *ngIf="(data?.closed == 1)">
                              Closed
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12 font-weight-bold common-txt-clr">
                Capabilities:
              </div>
            </div>
            <ul class="row">
              <li class="col-md-4" *ngFor="let capability of item.capabilities">
                {{ capability }}
              </li>
            </ul>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12 font-weight-bold common-txt-clr">
                Services:
              </div>
            </div>
            <ul class="row">
              <li class="col-md-2" *ngFor="let service of item.services">
                {{ service }}
              </li>
            </ul>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="px-3 my-2 font-weight-bold" role="alert">
    <span>
      <i class="fa fa-info-circle mx-2"></i>
      ServicePlus is PCI compliant and does not store Credit Card information! </span>
  </div>

  <div class="col-md-12">
    <div class="table-responsive table-responsive-sm custom-height">
      <table class="table table-sm table-bordered table-striped">
        <thead>
          <tr>
            <th class="align-middle">Locations</th>
            <th class="align-middle">Type / Zone</th>
            <th class="align-middle">Annual</th>
            <th class="align-middle">Monthly</th>
            <th *ngIf="!hideColumns" class=" text-center" sortable="Discount1stYear">
              Discount 1st Year
            </th>
            <th *ngIf="!hideColumns" class=" text-center" sortable="CorporateDiscount">
              Corporate Discount
            </th>
            <th class=" text-center" sortable="DiscountedMonthlyTotal">
              Discounted Monthly Total
            </th>
          </tr>
        </thead>
        <tbody *ngIf="pricingGrid?.length > 0; else noData" class="text-center">
          <tr *ngFor="let item of pricingGrid">
            <td class="text-left">{{ item.Location }}</td>
            <td>{{ item.Zone }}</td>
            <td>{{ item.Annual | currency}}</td>
            <td>{{ item.Monthly | currency}}</td>
            <td *ngIf="!hideColumns">{{ item.AnnualSubscriptionDiscount > 0 ? (item.AnnualSubscriptionDiscount) : ''}}
              {{ item.AnnualSubscriptionDiscount > 0 ? '%' : ''}}</td>
            <td *ngIf="!hideColumns">{{ item.CorporateDiscount > 0 ? (item.CorporateDiscount) : ''}} {{
              item.CorporateDiscount > 0 ? '%' : ''}}</td>
            <td>{{ item.DiscountedMonthlyTotal | currency}}</td>
          </tr>
        </tbody>
        <ng-template #noData>
          <tbody>
            <tr>
              <td colspan="8" class="text-center">No Data available</td>
            </tr>
          </tbody>
        </ng-template>
      </table>
    </div>
    <div class="row text-right font-weight-bold">
      <div class="col-md-12 ">
        <span>Total Monthly Fee: {{ totalMonthlyFee || '0' | currency}}</span>
      </div>
      <div class="col-md-12 ">
        <span>Total Annual Fee: {{ totalMonthlyFee * 12 || '0' | currency}}</span>
      </div>
    </div>
  </div>
  <div class="row m-2">
    <div class="col-md-12 px-0 text-right mt-2">
      <button class="btn btn-sm common-btn" (click)="openPaymentInformationDialog()">
        Next
      </button>
    </div>
  </div>
