import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AdvertisingLevels } from '../models/advertising-levels.model';

@Injectable({
  providedIn: 'root'
})
export class AdvertisingLevelsService {

  constructor(private http: HttpClient) { }

  getAdvertisingLevels(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.baseUrl}keyValuePairState/advertisingLevelsSelect/get`, data).pipe(
      map((res: any) => res.body.map((types: AdvertisingLevels) => new AdvertisingLevels().deserialize(types))));
  }
}
