import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';

const routes: Routes = [
    {
        path: 'welcome',
        component: SplashScreenComponent
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'signup',
        loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule)
    },
    {
        path: 'pages',
        loadChildren: () => import('./pages/pages.module').then(m => m.PageModule)
    },
    {
        path: '',
        component: SplashScreenComponent
    },    
    { path: '**', component: NotFoundComponent } // This line will remain down from the whole pages component list
];

const config: ExtraOptions = {
    useHash: true
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
