import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { patterns } from 'src/assets/patterns';
import { ProviderAdditionalAddresses } from '../../models/provider-additional-addresses.model';
import { Provider } from '../../models/providers.model';
import { ProviderAdditionalAddressesService } from '../../services/provider-additional-addresses.service';
import { ProvidersService } from '../../services/providers.service';
import { UserInformationService } from '../../services/user-information.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { forkJoin } from 'rxjs';
import { ProviderElectronicDispatchEmailService } from '../../services/provider-electronic-dispatch-email.service';
import { ProviderElectronicDispatchPhoneNumberService } from '../../services/provider-electronic-dispatch-phone-number.service';

@Component({
  selector: 'app-dispatch-details',
  templateUrl: './dispatch-details.component.html',
  styleUrls: ['./dispatch-details.component.scss']
})
export class DispatchDetailsComponent implements OnInit {

  @Input() parent: boolean;
  @Output() saveComplete = new EventEmitter<boolean>();

  providerProfileForm: UntypedFormGroup;
  provider: Provider = new Provider();
  providerAdditionalAddresses: ProviderAdditionalAddresses = new ProviderAdditionalAddresses();
  addrLableText: string;
  loading = false;
  showElectronicDispatch = false;
  dispatchSoftwares: any;
  electronicPhones: any = [];
  electronicDispatchEmails: any = [];
  electronicDispatchPhones: any = [];
  deletedElectronicDispatchEmails: any = [];
  deletedElectronicDispatchPhones: any = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private providersService: ProvidersService,
    private userInformationService: UserInformationService,
    private providerAdditionalAddressesService: ProviderAdditionalAddressesService,
    private snackBarService: SnackBarService,
    private providerElectronicDispatchEmailService: ProviderElectronicDispatchEmailService,
    private providerElectronicDispatchPhoneNumberService: ProviderElectronicDispatchPhoneNumberService,
  ) { }

  ngOnInit(): void {
    this.provider = this.providersService.provider || new Provider();
    this.providerAdditionalAddresses = this.providerAdditionalAddressesService.providerAdditionalAddresses
      || new ProviderAdditionalAddresses();
    this.addrLableText = this.parent ? '' : 'Physical';

    this.providerProfileForm = this.formBuilder.group({
      providerPK: [''],
      additionalAddressPK: [''],
      useCentralDispatch: ['', [Validators.required]],
      dispatchPhone: [''],
      dispatchEmail: ['', [Validators.pattern(patterns.emailPattern)]],
      dispatchFax: [''],
      dispatchSolution: [''],
      tollFreePhone: [''],
      afterHoursPhone: [''],
      businessHoursPhone: [''],
      providerDispatchSoftwareKey: [''],
      otherDispatchSoftware: [''],
      respondEmailServiceRequest: [false],
      respondPhoneServiceRequest: [false],
      electronicDispatchEmail: ['', [Validators.pattern(patterns.emailPattern)]],
      electronicDispatchPhone: ['', [Validators.pattern(patterns.mobnumPattern)]]
    });

    const parent = this.parent ? true : false;
    if (this.parent || (!this.parent && !this.provider.useCentralDispatch)) {
      this.getElectronicMailAndPhoneNumbers(parent, false);
    }

    this.setFormData(parent);
    if (this.parent) {
      this.getDispatchSoftwares();
    }
  }

  setFormData(parent: boolean): void {
    this.showElectronicDispatch = false;
    if (!this.parent && !this.provider.providerDispatchSoftwareKey && !this.provider.useCentralDispatch) {
      // check electronic dispatch email or elctronic dispatch phone populated
      // this.getElectronicMailAndPhoneNumbers(true, true);
    }

    if (!this.parent && this.provider.useCentralDispatch) {
      // this.getElectronicMailAndPhoneNumbers(true, false);
    }

    if (parent) {
      this.providerProfileForm.patchValue({
        providerPK: this.provider.providerPK || '',
        useCentralDispatch: this.provider.useCentralDispatch,
        dispatchPhone: this.provider.dispatchPhone || '',
        dispatchEmail: this.provider.dispatchEmail || '',
        dispatchFax: this.provider.dispatchFax || '',
        dispatchSolution: this.provider.dispatchSolution,
        tollFreePhone: this.provider.tollFreePhone || '',
        afterHoursPhone: this.provider.afterHoursPhone || '',
        businessHoursPhone: this.provider.businessHoursPhone || '',
        providerDispatchSoftwareKey: this.provider.providerDispatchSoftwareKey || '',
        otherDispatchSoftware: this.provider.otherDispatchSoftware || '',
      });
    } else {
      this.providerProfileForm.patchValue({
        additionalAddressPK: this.providerAdditionalAddresses.additionalAddressPK || '',
        providerPK: this.providerAdditionalAddresses.providerPK || '',
        dispatchPhone: this.providerAdditionalAddresses.dispatchPhone || '',
        dispatchEmail: this.providerAdditionalAddresses.dispatchEmail || '',
        dispatchFax: this.providerAdditionalAddresses.dispatchFax || '',
        tollFreePhone: this.providerAdditionalAddresses.tollFreePhone || '',
        afterHoursPhone: this.providerAdditionalAddresses.afterHoursPhone || '',
        businessHoursPhone: this.providerAdditionalAddresses.businessPhone || '',
        useCentralDispatch: this.provider.useCentralDispatch,
      });
    }
    this.setFormValidation();
  }

  getElectronicMailAndPhoneNumbers(parent: boolean = false, checkMailAndPhonePopulated: boolean = false, redirectToSave: boolean = false)
    : void {
    // copy tmp data addded in array but not present in table
    const electronicDispatchEmails = this.electronicDispatchEmails || [];
    const electronicDispatchPhones = this.electronicDispatchPhones || [];
    const filter = {
      providerPK: this.provider.providerPK,
      additionalAddressPK: !parent ? this.providerAdditionalAddresses.additionalAddressPK : null,
    };
    const electronicEmailReq = this.providerElectronicDispatchEmailService.getProviderElectronicDispatchEmails(filter);
    const electronicPhoneReq = this.providerElectronicDispatchPhoneNumberService.getProviderElectronicDispatchPhoneNumbers(filter);

    this.loading = true;
    forkJoin([electronicEmailReq, electronicPhoneReq]).subscribe((result) => {
      this.loading = false;
      if (checkMailAndPhonePopulated) {
        if (result[0].length && result[0].length) {
          this.showElectronicDispatch = true;
          this.setFormValidation();
        }
      } else {
        this.electronicDispatchEmails = result[0] || [];
        this.electronicDispatchPhones = result[1] || [];
      }

      // check provider or location and save
      if (redirectToSave) {
        // add tmp data in table data
        this.electronicDispatchEmails = [...this.electronicDispatchEmails, ...(electronicDispatchEmails.filter(obj => !obj.providerPK))];
        this.electronicDispatchPhones = [...this.electronicDispatchPhones, ...(electronicDispatchPhones.filter(obj => !obj.providerPK))];
        this.checkUserAndSave();
      } else {
        this.setRespondServiceRequestValues();
      }
    }, (error) => {
      this.loading = false;
    });
  }

  setRespondServiceRequestValues(): void {
    this.providerProfileForm.controls.respondEmailServiceRequest.setValue(this.electronicDispatchEmails.length > 0 ? true : false);
    this.providerProfileForm.controls.respondPhoneServiceRequest.setValue(this.electronicDispatchPhones.length > 0 ? true : false);
  }

  setFormValidation(): void {
    if (this.parent) {
      this.providerProfileForm.controls.useCentralDispatch.setValidators([Validators.required]);
      this.providerProfileForm.controls.respondEmailServiceRequest.setValidators([Validators.required]);
      this.providerProfileForm.controls.respondPhoneServiceRequest.setValidators([Validators.required]);

      this.providerProfileForm.controls.useCentralDispatch.updateValueAndValidity();
      this.providerProfileForm.controls.respondEmailServiceRequest.updateValueAndValidity();
      this.providerProfileForm.controls.respondPhoneServiceRequest.updateValueAndValidity();

      if (this.providerProfileForm.controls.dispatchSolution.value === false) {
        this.providerProfileForm.controls.businessHoursPhone.setValidators([Validators.required]);
        this.providerProfileForm.controls.providerDispatchSoftwareKey.setValidators(null);
      } else if (this.providerProfileForm.controls.dispatchSolution.value) {
        this.providerProfileForm.controls.businessHoursPhone.clearValidators();
        this.providerProfileForm.controls.providerDispatchSoftwareKey.setValidators([Validators.required]);
      }
      this.providerProfileForm.controls.providerDispatchSoftwareKey.updateValueAndValidity();
      this.providerProfileForm.controls.businessHoursPhone.updateValueAndValidity();

      if (this.providerProfileForm.controls.dispatchSolution.value &&
        this.providerProfileForm.controls.providerDispatchSoftwareKey.value === 'Other') {
        this.providerProfileForm.controls.otherDispatchSoftware.setValidators([Validators.required]);
      } else {
        this.providerProfileForm.controls.otherDispatchSoftware.clearValidators();
      }
      this.providerProfileForm.controls.otherDispatchSoftware.updateValueAndValidity();

    } else {
      if (this.provider.useCentralDispatch) {
        this.providerProfileForm.patchValue({
          providerPK: this.providerAdditionalAddresses.providerPK || '',
          additionalAddressPK: this.providerAdditionalAddresses.additionalAddressPK || '',
          dispatchPhone: this.provider.dispatchPhone || '',
          dispatchEmail: this.provider.dispatchEmail || '',
          dispatchFax: this.provider.dispatchFax || '',
          tollFreePhone: this.provider.tollFreePhone || '',
          afterHoursPhone: this.provider.afterHoursPhone || '',
          businessHoursPhone: this.provider.businessHoursPhone || ''
        });

        // tslint:disable-next-line:forin
        for (const key in this.providerProfileForm.controls) {
          this.providerProfileForm.get(key).clearValidators();
          this.providerProfileForm.get(key).updateValueAndValidity();
        }

        this.providerProfileForm.disable();
      } else {
        this.providerProfileForm.controls.respondEmailServiceRequest.setValidators([Validators.required]);
        this.providerProfileForm.controls.respondPhoneServiceRequest.setValidators([Validators.required]);
        this.providerProfileForm.controls.useCentralDispatch.clearValidators();
        this.providerProfileForm.controls.useCentralDispatch.updateValueAndValidity();
        this.providerProfileForm.controls.respondEmailServiceRequest.updateValueAndValidity();
        this.providerProfileForm.controls.respondPhoneServiceRequest.updateValueAndValidity();
      }
    }
  }

  handleError = (control: string, error: string) => {
    return this.providerProfileForm.controls[control].hasError(error);
  }

  updateDispatchDetails(): any {
    if (this.providerProfileForm.invalid) {
      this.providerProfileForm.markAllAsTouched();
      return false;
    }

    if (this.providerProfileForm.controls.respondEmailServiceRequest.value && this.providerProfileForm.controls.electronicDispatchEmail.value) {
      this.snackBarService.warning('Please Save the Electronic Dispatch Email');
      return false;
    }


    if (this.providerProfileForm.controls.respondPhoneServiceRequest.value && this.providerProfileForm.controls.electronicDispatchPhone.value) {
      this.snackBarService.warning('Please Save the Electronic Dispatch Phone');
      return false;
    }

    if (this.parent) {

      if (this.providerProfileForm.controls.respondEmailServiceRequest.value && this.electronicDispatchEmails.length === 0) {
        this.snackBarService.warning('Please Enter Atleast One Electronic Dispatch Email');
        return false;
      }

      if (this.providerProfileForm.controls.respondPhoneServiceRequest.value && this.electronicDispatchPhones.length === 0) {
        this.snackBarService.warning('Please Enter Atleast One Electronic Dispatch Phone');
        return false;
      }


    } else {
      if (!this.provider.useCentralDispatch) {
        if (!this.providerProfileForm.value.tollFreePhone && !this.providerProfileForm.value.businessHoursPhone) {
          this.snackBarService.warning('Toll Free Phone or Business Phone is required');
          return false;
        }

        if (this.providerProfileForm.controls.respondEmailServiceRequest.value
          && this.electronicDispatchEmails.length === 0) {
          this.snackBarService.warning('Please Enter Atleast One Electronic Dispatch Email');
          return false;
        }

        if (this.providerProfileForm.controls.respondPhoneServiceRequest.value
          && this.electronicDispatchPhones.length === 0) {
          this.snackBarService.warning('Please Enter Atleast One Electronic Dispatch Phone');
          return false;
        }
      }
    }

    if (this.parent || (!this.parent && !this.provider.useCentralDispatch)) {
      // delete deleted email and phones before save
      this.deleteDeletedElectronicEmailsAndPhones();
    } else {
      this.saveComplete.emit(true);
    }
  }

  checkUserAndSave(): void {
    // check is provider or location ui
    if (this.parent) {
      this.updateProviderDetails();
    } else {
      this.updateProviderAdditionalAddressDetails();
    }
  }

  updateProviderDetails(): any {
    if (this.providerProfileForm.controls.respondEmailServiceRequest.value && this.electronicDispatchEmails.length === 0) {
      this.snackBarService.warning('Please Enter Atleast One Electronic Dispatch Email');
      return false;
    }

    if (this.providerProfileForm.controls.respondPhoneServiceRequest.value && this.electronicDispatchPhones.length === 0) {
      this.snackBarService.warning('Please Enter Atleast One Electronic Dispatch Phone');
      return false;
    }

    if (this.providerProfileForm.controls.dispatchSolution.value && this.providerProfileForm.controls.providerDispatchSoftwareKey.value === 'Other') {
      this.addDispatchSoftwareKey();
    }

    if (this.providerProfileForm.controls.providerDispatchSoftwareKey.value === 'Other'
      && this.providerProfileForm.controls.dispatchSolution.value) {
      this.providerProfileForm.controls.providerDispatchSoftwareKey.setValue(this.providerProfileForm.controls.otherDispatchSoftware.value);
    }

    if (!this.providerProfileForm.controls.dispatchSolution.value) {
      this.providerProfileForm.controls.providerDispatchSoftwareKey.setValue(null);
      this.providerProfileForm.controls.otherDispatchSoftware.setValue(null);
    }
    const dispatchSoftwareKey = this.providerProfileForm.controls.providerDispatchSoftwareKey.value;

    if (this.providerProfileForm.controls.respondEmailServiceRequest.value) {
      this.addElectronicDispatchEmails();
    } else {
      this.deleteProviderElectronicDispatchEmailsAndPhones('email');
    }

    if (this.providerProfileForm.controls.respondPhoneServiceRequest.value) {
      this.addElectronicDispatchPhones();
    } else {
      this.deleteProviderElectronicDispatchEmailsAndPhones('phone');
    }


    this.provider = Object.assign(this.provider, new Provider().prepareSave(this.providerProfileForm.value));
    this.provider.updatedUserKey = this.userInformationService.userInformation.userInformationID.toString();

    this.loading = true;
    this.providersService.updateProvider(this.provider).subscribe((result) => {
      this.loading = false;
      this.providerProfileForm.controls.providerDispatchSoftwareKey.setValue(dispatchSoftwareKey);
      this.provider.providerDispatchSoftwareKey = dispatchSoftwareKey;

      this.snackBarService.success(result.message);
      this.providerProfileForm.controls.otherDispatchSoftware.setValue('');

      this.providersService.provider = this.provider;
      this.saveComplete.emit(true);
    }, (error) => {
      this.loading = false;
      this.saveComplete.emit(false);
    });
  }

  updateProviderAdditionalAddressDetails(): any {
    if (this.providerProfileForm.controls.respondEmailServiceRequest.value
      && this.electronicDispatchEmails.length === 0) {
      this.snackBarService.warning('Please Enter Atleast One Electronic Dispatch Email');
      return false;
    }

    if (this.providerProfileForm.controls.respondPhoneServiceRequest.value
      && this.electronicDispatchPhones.length === 0) {
      this.snackBarService.warning('Please Enter Atleast One Electronic Dispatch Phone');
      return false;
    }

    // if (this.showElectronicDispatch) {
    if (this.providerProfileForm.controls.respondEmailServiceRequest.value) {
      this.addElectronicDispatchEmails();
    } else {
      this.deleteProviderElectronicDispatchEmailsAndPhones('email');
    }

    if (this.providerProfileForm.controls.respondPhoneServiceRequest.value) {
      this.addElectronicDispatchPhones();
    } else {
      this.deleteProviderElectronicDispatchEmailsAndPhones('phone');
    }
    // }

    this.providerAdditionalAddresses = Object.assign(
      this.providerAdditionalAddresses, new ProviderAdditionalAddresses().prepareSave(this.providerProfileForm.value)
    );
    this.providerAdditionalAddresses.businessPhone = this.providerProfileForm.value.businessHoursPhone;
    this.providerAdditionalAddresses.updatedUserKey = this.userInformationService.userInformation.userInformationID.toString();

    this.loading = true;
    this.providerAdditionalAddressesService.updateProviderAdditionalAddresses(this.providerAdditionalAddresses).subscribe((result) => {
      this.loading = false;
      this.snackBarService.success(result.message);

      this.providerAdditionalAddressesService.providerAdditionalAddresses = this.providerAdditionalAddresses;
      this.saveComplete.emit(true);
    }, (error) => {
      this.loading = false;
      this.saveComplete.emit(false);
    });
  }

  getDispatchSoftwares(): void {
    this.loading = true;
    this.providersService.getDispatchSoftwares().subscribe((result) => {
      this.loading = false;
      this.dispatchSoftwares = result.body;
    }, (error) => {
      this.loading = false;
    });
  }

  addElectronicDispatchEmails(): void {
    const request = [];
    this.electronicDispatchEmails.forEach(element => {
      if (element.providerElectronicDispatchEmailKey) {
        const data = {
          deleted: false,
          insertedUserKey: this.userInformationService.userInformation.userInformationID.toString(),
          providerElectronicDispatchEmailKey: element.providerElectronicDispatchEmailKey,
          providerPK: this.provider.providerPK,
          additionalAddressPK: this.parent ? null : this.providerAdditionalAddresses.additionalAddressPK,
          systemDefault: false,
          updatedUserKey: ''
        };
        request.push(this.providersService.addElectronicDispatchEmails(data));
      }
    });

    this.loading = true;
    forkJoin([...request]).subscribe(() => {
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  addElectronicDispatchPhones(): void {
    const request = [];
    this.electronicDispatchPhones.forEach(phone => {
      if (phone.providerElectronicDispatchPhoneNumberKey) {
        const data = {
          deleted: false,
          insertedUserKey: this.userInformationService.userInformation.userInformationID.toString(),
          providerElectronicDispatchPhoneNumberKey: phone.providerElectronicDispatchPhoneNumberKey,
          providerPK: this.provider.providerPK,
          additionalAddressPK: this.parent ? null : this.providerAdditionalAddresses.additionalAddressPK,
          systemDefault: false,
          updatedUserKey: ''
        };
        request.push(this.providersService.addElectronicDispatchPhones(data));
      }
    });

    this.loading = true;
    forkJoin([...request]).subscribe(() => {
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  addEmailAndPhone(value): any {
    const newEmail = this.providerProfileForm.controls.electronicDispatchEmail.value;
    const newPhone = this.providerProfileForm.controls.electronicDispatchPhone.value;

    if (value === 'email' && !newEmail) {
      this.snackBarService.warning('Please enter Email Address');
      return false;
    }

    if (value === 'phone' && !newPhone) {
      this.snackBarService.warning('Please enter Phone Number');
      return false;
    }

    if (value === 'email') {
      if (this.providerProfileForm.controls.electronicDispatchEmail.valid) {
        if (!(this.electronicDispatchEmails.some(x => x.providerElectronicDispatchEmailKey.toLowerCase() ===
          newEmail.toLowerCase()))) {
          this.electronicDispatchEmails.push({
            providerElectronicDispatchEmailKey: newEmail
          });
          this.providerProfileForm.controls.electronicDispatchEmail.setValue(null);
        } else {
          this.snackBarService.warning('Email Already Exists');
        }
      }
    } else {
      if (this.providerProfileForm.controls.electronicDispatchPhone.valid) {
        if (!(this.electronicDispatchPhones.some(x => x.providerElectronicDispatchPhoneNumberKey ===
          newPhone))) {
          this.electronicDispatchPhones.push({
            providerElectronicDispatchPhoneNumberKey: newPhone
          });
          this.providerProfileForm.controls.electronicDispatchPhone.setValue(null);
        } else {
          this.snackBarService.warning('Phone Number Already Exists');
        }
      }
    }
  }

  clearEmailAndPhone(value): void {
    if (value === 'email') {
      this.providerProfileForm.controls.electronicDispatchEmail.setValue(null);
    } else {
      this.providerProfileForm.controls.electronicDispatchPhone.setValue(null);
    }
  }

  addDispatchSoftwareKey(): void {
    const data = {
      providerDispatchSoftwareKey: this.providerProfileForm.controls.otherDispatchSoftware.value,
      deleted: false,
      systemDefault: false,
      insertedUserKey: this.userInformationService.userInformation.userInformationID.toString()
    };

    this.loading = true;
    this.providersService.addDispatchSoftwareKey(data).subscribe(() => {
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  deleteProviderElectronicDispatchEmail(email): void {
    if (email.providerPK) {
      const deleteData = {
        providerElectronicDispatchEmailKey: email.providerElectronicDispatchEmailKey,
      };
      this.deletedElectronicDispatchEmails.push(deleteData);
    }
    this.electronicDispatchEmails = this.electronicDispatchEmails.filter(e => e.providerElectronicDispatchEmailKey !==
      email.providerElectronicDispatchEmailKey);
  }

  deleteProviderDispatchPhoneNumber(phone): void {
    if (phone.providerPK) {
      const deleteData = {
        providerElectronicDispatchPhoneNumberKey: phone.providerElectronicDispatchPhoneNumberKey,
      };
      this.deletedElectronicDispatchPhones.push(deleteData);
    }
    this.electronicDispatchPhones = this.electronicDispatchPhones.filter(e => e.providerElectronicDispatchPhoneNumberKey !==
      phone.providerElectronicDispatchPhoneNumberKey);
  }

  deleteDeletedElectronicEmailsAndPhones(): void {
    const deleteReqs: any = [];
    this.deletedElectronicDispatchEmails.forEach(element => {
      if (element.providerElectronicDispatchEmailKey) {
        const deleteData = {
          providerElectronicDispatchEmailKey: element.providerElectronicDispatchEmailKey,
        };
        deleteReqs.push(this.providerElectronicDispatchEmailService.deleteProviderElectronicDispatchEmails(deleteData));
      }
    });

    this.deletedElectronicDispatchPhones.forEach(element => {
      if (element.providerElectronicDispatchPhoneNumberKey) {
        const deleteData = {
          providerElectronicDispatchPhoneNumberKey: element.providerElectronicDispatchPhoneNumberKey,
        };
        deleteReqs.push(this.providerElectronicDispatchPhoneNumberService.deleteProviderElectronicDispatchPhoneNumbers(deleteData));
      }
    });

    if (deleteReqs.length === 0) {
      // load phone and emails and update dispatch details if valid
      this.getElectronicMailAndPhoneNumbers((this.parent ? true : false), false, true);
    } else {
      this.loading = true;
      forkJoin(deleteReqs).subscribe(() => {
        this.loading = false;
        this.deletedElectronicDispatchEmails = [];
        this.deletedElectronicDispatchPhones = [];
        // load phone and emails and update dispatch details if valid
        this.getElectronicMailAndPhoneNumbers((this.parent ? true : false), false, true);
      }, (error) => {
        this.loading = false;
      });
    }
  }

  deleteProviderElectronicDispatchEmailsAndPhones(type: string): void {
    if (type === 'email') {
      const deleteEmailReqs: any = [];
      this.electronicDispatchEmails.forEach(element => {
        if (element.providerPK && element.providerElectronicDispatchEmailKey) {
          const deleteData = {
            providerElectronicDispatchEmailKey: element.providerElectronicDispatchEmailKey,
          };
          deleteEmailReqs.push(this.providerElectronicDispatchEmailService.deleteProviderElectronicDispatchEmails(deleteData));
        }
      });

      if (deleteEmailReqs.length > 0) {
        this.loading = true;
        forkJoin(deleteEmailReqs).subscribe(() => {
          this.loading = false;
        }, (error) => {
          this.loading = false;
        });
      }
    } else {
      const deletePhoneReqs: any = [];
      this.electronicDispatchEmails.forEach(element => {
        if (element.providerPK && element.providerElectronicDispatchPhoneNumberKey) {
          const deleteData = {
            providerElectronicDispatchPhoneNumberKey: element.providerElectronicDispatchPhoneNumberKey,
          };
          deletePhoneReqs.push(this.providerElectronicDispatchPhoneNumberService.deleteProviderElectronicDispatchPhoneNumbers(deleteData));
        }
      });

      if (deletePhoneReqs.length > 0) {
        this.loading = true;
        forkJoin(deletePhoneReqs).subscribe(() => {
          this.loading = false;
        }, (error) => {
          this.loading = false;
        });
      }
    }
  }
}
