import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NotFoundComponent } from './not-found/not-found.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BasicAuthInterceptor } from './interceptors/basicauth.interceptor';
import { ConfirmDeleteComponent } from './common/confirm-delete/confirm-delete.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { SignUpSuccessComponent } from './common/sign-up-success/sign-up-success.component';
import { DatePipe } from '@angular/common';
import { AlertBoxComponent } from './common/alert-box/alert-box.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { MatchingProviderComponent } from './common/matching-provider/matching-provider.component';
import { NgxMaskModule } from 'ngx-mask';
import { RedirectUserComponent } from './common/redirect-user/redirect-user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    SplashScreenComponent,
    ConfirmDeleteComponent,
    SignUpSuccessComponent,
    AlertBoxComponent,
    MatchingProviderComponent,
    RedirectUserComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '14px',
      primaryColour: '#76ff03',
      secondaryColour: '#dd0031',
      tertiaryColour: '#000000',
      fullScreenBackdrop: true,
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.google_map_api_key,
      libraries: ['places']
    }),
    GooglePlaceModule,
    MatSnackBarModule,
    MatDialogModule,
    MatButtonModule,
    NgxGoogleAnalyticsModule.forRoot(environment.google_analytics),
    NgxGoogleAnalyticsRouterModule,
    NgxMaskModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true }, 
    DatePipe,
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}}],
  bootstrap: [AppComponent]
})
export class AppModule { }
