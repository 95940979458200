import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent implements OnInit {

  constructor(protected ref: MatDialogRef<ConfirmDeleteComponent>) { }

  ngOnInit(): void {
  }

  submit(val): void {
    this.ref.close(val);
  }

}
