import { Deserializable } from './deserializable.model';

export class ProviderTermsConditions implements Deserializable {

    deleted: boolean;
    insertedTimestamp: string;
    insertedUserKey: string;
    outlawDealID: string;
    providerKey: string;
    providerTermsConditionKey: string;
    systemDefault: boolean;
    updatedTImestamp: string;
    updatedUserKey: string;

    deserialize(input: any): any {
        Object.assign(this, input);
        return this;
    }
}
