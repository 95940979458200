import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ITreeOptions } from '@circlon/angular-tree-component';
import { SortableHeaderDirective } from '../../directive/sortable.directive';
import { ProviderAdditionalAddresses } from '../../models/provider-additional-addresses.model';
import { ProviderCapabilities } from '../../models/provider-capabilities.model';
import { Provider } from '../../models/providers.model';
import { CapabilitiesService } from '../../services/capabilities.service';
import { ProviderAdditionalAddressesService } from '../../services/provider-additional-addresses.service';
import { ProvidersService } from '../../services/providers.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { UserInformationService } from '../../services/user-information.service';

@Component({
  selector: 'app-provider-capabilities',
  templateUrl: './provider-capabilities.component.html',
  styleUrls: ['./provider-capabilities.component.scss']
})
export class ProviderCapabilitiesComponent implements OnInit {

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
  @Input() parent: boolean;
  @Output() isServiceSelected = new EventEmitter<boolean>();

  loading = false;
  provider: Provider = new Provider();
  providerAdditionalAddresses: ProviderAdditionalAddresses = new ProviderAdditionalAddresses();
  providerServiceCapabilities: ProviderCapabilities[] = [];
  providerAllServiceCapabilities: ProviderCapabilities[] = [];
  capabilities = [];
  searchCtrl = '';
  searchString: string;

  // For checkbox tree
  providerKey: string;
  selectedCount = 0;
  selectedCapabilities = [];
  capabilitiesData: any;

  options: ITreeOptions = {
    animateExpand: true,
    isExpandedField: 'expanded'
  };
  selectedKey: any;

  constructor(
    private providersService: ProvidersService,
    private providerAdditionalAddressesService: ProviderAdditionalAddressesService,
    private snackBarService: SnackBarService,
    private capabilitiesService: CapabilitiesService,
    private userInformationService: UserInformationService,
  ) { }

  ngOnInit(): void {
    this.provider = this.providersService.provider || new Provider();
    this.providerAdditionalAddresses = this.providerAdditionalAddressesService.providerAdditionalAddresses
      || new ProviderAdditionalAddresses();

    this.getProviderServiceCapabilities();
  }

  getProviderServiceCapabilities(): void {
    const filter = {
      providerKey: this.provider.providerPK,
      locationKey: this.parent ? null : this.providerAdditionalAddresses.additionalAddressPK,
    };

    this.loading = true;
    this.capabilitiesService.getProviderCapabilitiesByProvider(filter).subscribe(result => {
      this.loading = false;
      this.capabilitiesData = result;
      if (this.selectedKey) this.expandSelectedSearchNode(this.capabilitiesData, this.selectedKey);
      this.isServiceSelected.emit(this.selectedCount ? true : false);
    }, (error) => {
      this.loading = false;
    });
  }

  expandSelectedSearchNode(array, value) {
    let found = false;
    array.forEach(element => {
      element.expanded = this.expandSelectedSearchNode(element.children || [], value) || element.providerCapabilityKey === value;
      found = found || element.expanded;
    });
    return found;
  }

  onSelectedChange(data, event): void {
    this.updateChildNodeCheckbox(data.data, event.checked);
    this.updateParentNodeCheckbox(data);
    this.countCapability();

    const filter = {
      isSelected: event.checked,
      providerCapabilityKey: data.data.providerCapabilityKey,
      providerKey: this.provider.providerPK,
      locationKey: this.parent ? null : this.providerAdditionalAddresses.additionalAddressPK,
      userInformationId: this.userInformationService.userInformation.userInformationID.toString()
    };
    this.loading = true;
    this.capabilitiesService.addDeleteProviderCapabilities(filter).subscribe(() => {
      this.loading = false;

      if (event.checked) data.treeModel.expandAll();
      else data.treeModel.collapseAll();

      this.countCapability();

      if (this.searchString) {
        this.selectedKey = data.data.providerCapabilityKey;
        this.searchString = '';
        this.getProviderServiceCapabilities()
      }
      this.snackBarService.success('Capabilities updated successfully.');
    }, (error) => {
      this.loading = false;
    });
  }

  countCapability(): void {
    this.selectedCount = 0;
    this.selectedCapabilities = [];
    this.capabilitiesData.forEach(service => {
      if (service.isSelected === true) {
        this.selectedCount += 1;
        this.selectedCapabilities.push(service.name);
      }
      if (service?.children?.length > 0) {
        this.addItemRecursively(service.children);
      }
    });
  }

  addItemRecursively(data): void {
    data.forEach(service => {
      if (service.isSelected === true) {
        this.selectedCapabilities.push(service.name);
      }
      if (service.children.length > 0) {
        this.addItemRecursively(service.children);
      }
    });
  }

  public updateChildNodeCheckbox(node, checked): void {
    node.isSelected = checked;
    if (node.children) {
      node.children.forEach((child) => this.updateChildNodeCheckbox(child, checked));
    }
  }

  public updateParentNodeCheckbox(node): void {
    if (!node) { return; }

    let allChildrenChecked = true;
    let noChildChecked = true;

    if (node.children && node.children.length > 0) {
      for (const child of node?.children) {
        if (!child.data.isSelected) {
          allChildrenChecked = false;
        }
        if (child.data.isSelected) {
          noChildChecked = false;
        }
      }

      if (allChildrenChecked) {
        node.data.isSelected = true;
      } else if (noChildChecked) {
        node.data.isSelected = false;
      } else {
        node.data.isSelected = true;
      }
    }
    this.updateParentNodeCheckbox(node.parent);
  }

}
