import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'recursiveFilter'
})
export class RecursiveFilterPipe implements PipeTransform {

  transform(value: any, searchStr?: any, property?: any): any[] {
    if (!searchStr) return value;

    return this.searchNodes(value, searchStr, property);
  }


  searchNodes(data, searchStr, property): any {
    if (!searchStr) return data;

    const object = JSON.parse(JSON.stringify(data)) || [];
    object.map(obj => obj.isSearched = 1);
    const arr = this.getSearchedChild(object, property, searchStr.toLowerCase());
    const finalArray = arr.filter(obj => obj.isSearched === 0);
    return finalArray;
  }

  getSearchedChild(children: any, property, searchStr, isSearched = 1): any {
    children.map(obj => {
      obj.isSearched = isSearched;
      if (obj[property].toLowerCase().includes(searchStr)) {
        obj.isSearched = 0;
      }

      if (obj.children && obj.children.length) {
        const child = this.getSearchedChild(obj.children, property, searchStr, obj.isSearched);
        if (child.some(object => object.isSearched === 0)) {
          obj.isSearched = 0;
          obj.expanded = true;
          obj.children = child.filter(object => object.isSearched === 0);
        } else {
          obj.children = [];
        }
      }
    });
    return children;
  }


  // ! Two Level
  // searchNodes(data, searchStr, property) {
  //   if (!searchStr) return data;
  //   return data.reduce((acc, node) => {
  //     const childList = node.children && node.children.filter(b => b[property].toLowerCase().includes(searchStr));

  //     if (childList && childList.length)
  //       acc.push({
  //         ...node,
  //         children: childList,
  //         expanded: true
  //       });
  //     else if (node[property].toLowerCase().includes(searchStr)) {
  //       acc.push(node);
  //     }
  //     return acc;
  //   }, []);
  // }

  // ! Old Function
  // searchRecursive(value): any {
  //   value.forEach(val => {
  //     const lowerCaseName = val[this.property].toLowerCase();
  //     if (lowerCaseName.includes(this.searchStr)) {
  //       this.searchedItems.push(val);
  //     } else if (val[this.childrenPropName]) {
  //       if (val[this.childrenPropName].length > 0) {
  //         this.searchRecursive(val[this.childrenPropName]);
  //       }
  //     }
  //   });
  //   return this.searchedItems;
  // }
}
