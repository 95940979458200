import { Deserializable } from './deserializable.model';

export class ProviderHoursOfOperations implements Deserializable {

    providerHoursOfOperationKey : String ;
    providerKey : String ;
    additionalAddressPK : String ;
    daysOfWeekKey : String ; 
    openTime : String ; 
    closeTime : String ; 
    closed : Boolean ; 
    systemDefault : Boolean ; 
    //InsertedTimestamp datetime(3) 
    onsertedUserKey : String ;
    //UpdatedTimestamp datetime(3) 
    updatedUserKey : String ;
    deleted : Boolean ;

    deserialize(input: any): any {
        Object.assign(this, input);
        return this;
    }

    prepareSave(data: any): any {
        this.deserialize(data);
        this.systemDefault = false;
        this.deleted = false;
        return this;
    }

}