<ngx-loading [show]="loading"></ngx-loading>
<form [formGroup]="providerProfileForm">
    <div class="row mb-3" *ngIf="!parent">
        <div class="col-12 offset-md-1 col-md-10 map-box text-center">
            <app-map [latitude]="latitude" [longitude]="longitude"></app-map>
        </div>
    </div>
    <div class="row" *ngIf="parent">
        <div class="col-12 col-md-4">
            <mat-form-field>
                <input type="text" matInput formControlName="companyName" placeholder="Company Name" required>
                <mat-error *ngIf="handleError('companyName', 'required')">
                    Company Name is required.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-12 col-md-4">
            <mat-form-field>
                <input type="text" matInput formControlName="ownerFirstName" placeholder="Primary Contact First Name" required>
                <mat-error *ngIf="handleError('ownerFirstName', 'required')">
                    Primary Contact First Name is required.
                </mat-error>
                <mat-error *ngIf="handleError('ownerFirstName', 'pattern')">
                    Primary Contact First name must contain alphabets and must not begin with space.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-12 col-md-4">
            <mat-form-field>
                <input type="text" matInput formControlName="ownerLastName" placeholder="Primary Contact Last Name" required>
                <mat-error *ngIf="handleError('ownerLastName', 'required')">
                    Primary Contact Last Name is required.
                </mat-error>
                <mat-error *ngIf="handleError('ownerLastName', 'pattern')">
                    Primary Contact Last name must contain alphabets and must not begin with space.
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="!parent">
        <div class="col-12 col-md-6">
            <mat-form-field>
                <input type="text" matInput formControlName="name" placeholder="Location Name" required>
                <mat-error *ngIf="handleError('name', 'required')">
                    Location Name is required.
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-4">
            <mat-form-field>
                <input type="text" matInput formControlName="address1" [placeholder]="addrLableText + ' Address 1'"
                    ngx-google-places-autocomplete (onAddressChange)="getDetailedAddress($event)" required>
                <mat-error *ngIf="handleError('address1', 'required')">
                    {{addrLableText}} Address 1 is required.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-12 col-md-4">
            <mat-form-field>
                <input type="text" matInput formControlName="address2" [placeholder]="addrLableText + ' Address 2'">
            </mat-form-field>
        </div>
        <div class="col-12 col-md-4">
            <mat-form-field>
                <input type="text" matInput formControlName="address3" [placeholder]="addrLableText + ' County'" required>
                <mat-error *ngIf="handleError('address3', 'required')">
                    {{addrLableText}} County is required.
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-4" [ngClass]="{'col-md-3': !parent}">
            <mat-form-field>
                <input type="text" matInput formControlName="city" [placeholder]="addrLableText + ' City'" required>
                <mat-error *ngIf="handleError('city', 'required')">
                    {{addrLableText}} City is required.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-12 col-md-4" [ngClass]="{'col-md-3' : !parent}">
            <mat-form-field>
                <mat-select matNativeControl formControlName="state" [placeholder]="addrLableText + ' State/Province'"
                    required>
                    <mat-option>
                        <ngx-mat-select-search [(ngModel)]="searchState" [placeholderLabel]="'Search State/Province'"
                            [noEntriesFoundLabel]="'Not found'" name="search state"
                            [ngModelOptions]="{standalone: true}">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let state of statesData | filter: searchState : 'StateDescription'"
                        [value]="state.StateKey">
                        {{state.StateDescription}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="handleError('state', 'required')">
                    {{addrLableText}} state/Providence is required.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-12 col-md-4" [ngClass]="{'col-md-3' : !parent}">
            <mat-form-field>
                <input type="text" matInput formControlName="postalCode" [placeholder]="addrLableText + ' Postal Code'"
                    required>
                <mat-error *ngIf="handleError('postalCode', 'required')">
                    {{addrLableText}} Postal Code is required.
                </mat-error>
                <mat-error *ngIf="handleError('postalCode', 'pattern')">
                    Enter valid {{addrLableText}} Postal Code.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-12 col-md-12" *ngIf="!parent">
            <!-- <mat-form-field>
                <mat-select matNativeControl formControlName="performsSameService"
                    placeholder="Does this location provide the same services?" required>
                    <mat-option [value]="true">Yes</mat-option>
                    <mat-option [value]="false">No</mat-option>
                </mat-select>
                <mat-error *ngIf="handleError('performsSameService', 'required')">
                    is performed same services is required.
                </mat-error>
            </mat-form-field> -->
            <label class="mt-0"> Does this location provide the same: </label>
            <mat-checkbox class="ml-2" formControlName="performsSameService">
                Services?
            </mat-checkbox>
            <mat-checkbox class="ml-2" formControlName="allLocationsSameHours">
                Hours?
            </mat-checkbox>
            <mat-checkbox class="ml-2" formControlName="isSameCapabilities">
                Capabilities?
            </mat-checkbox>
        </div>
    </div>
</form>