import { Deserializable } from './deserializable.model';

export class ProviderCreditCards implements Deserializable {

    authorizeNetsubscriptionId: string;
    deleted: boolean;
    expireDate: string;
    insertedTimestamp: string;
    insertedUserKey: string;
    lastFourDigitsOfCard: string;
    providerCreditCardKey: string;
    providerKey: string;
    systemDefault: boolean;
    updatedTimestamp: string;
    updatedUserKey: string;

    deserialize(input: any): any {
        Object.assign(this, input);
        return this;
    }
}
