<mat-dialog-content class="mat-typography">
    <h3> Are you associated with <span class="font-weight-bold">{{providerData.name}}</span>,
        {{providerData.physicalAddress1}},
        {{providerData.physicalCity}}, {{providerData.physicalStateKey}},{{providerData.physicalPostalCode}},
        {{providerData.businessHoursPhone | mask: '000-000-0000'}} <span *ngIf="parentData">Under Corporate Shop:
            <span class="font-weight-bold">{{parentData.name}}</span>?</span>
    </h3>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class='btn common-btn btn-sm m-1' mat-raised-button (click)="goToServiceProvider()">YES</button>
    <button class='btn common-btn btn-sm m-1' mat-raised-button (click)="close()">NO</button>
</mat-dialog-actions>
