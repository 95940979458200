import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'timezoneConvertDate'
})
export class TimezoneConvertDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value == null || value == "0000-00-00 00:00:00" || value == "-") {
      return value;
    }
    var formatDate = moment.tz(value, 'America/New_York');
    var userTimezone = sessionStorage.getItem('customerTimezone');
    if (userTimezone == '') {
      userTimezone = 'America/New_York';
    }
    return formatDate.tz(userTimezone).format('MM-DD-YYYY HH:mm');
  }

}
