import { Deserializable } from './deserializable.model';

export class SubscriptionAdvertisingLevelMatrix implements Deserializable {

  subscriptionAdvertisingLevelMatrixKey: string;
  advertisingLevelKey: string;
  serviceGroupKey: string;
  corporateDiscount: number;
  multipleLocationDiscount: number;
  annualSubscriptionDiscount: number;
  radiusOpportunity: number;
  zone1: number;
  zone2: number;
  zone3: number;
  systemDefault: boolean;
  insertedUserKey: string;
  updatedUserKey: string;
  deleted: boolean;

  deserialize(input: any): any {
    Object.assign(this, input);
    return this;
  }
}
