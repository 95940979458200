export const patterns = {
    namePattern: '^[A-Za-z0-9][A-Za-z0-9 _()-]*$',
    mobnumPattern: '^(?=.*?[1-9])[0-9()-]{10,15}$',
    passwordPattern: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
    lastNamePattern: '^[A-Za-z0-9][A-Za-z0-9 _!@#$&()^\\-`.+,\%"\'*-={}|<>?`~]*$',
    emailPattern: '([a-zA-Z0-9_.+-]+@[a-zA-Z0-9]+[.])([a-zA-Z])+(.[a-zA-Z]*)',
    cityPattern: '^[a-zA-Z][A-Za-z .,()-/]*$',
    zipCodePattern: '^([0-9]*)$',
    numberPattern: '^([0-9]*)$',
    yearPattern: '^[0-9]{4}$',
    onlyTextPattern: '^[A-Za-z][A-Za-z _]*$',
    textPattern: '^[A-Za-z0-9~`_!@#$&()^\\-`.+,\%"\'*-={}[\]|<>?][A-Za-z0-9 ~`_[\]!@#$&()^\\-`.+,\%"\'*-={}|<>?]*$',
    alphanumericZipCodePattern: '^([a-zA-Z0-9 ]*)$',
    url: '(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{1,})',
    instagramUrl: '^((?:http://)?|(?:https://)?)?(?:www\\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)',
    facebookUrl: '^((?:http://)?|(?:https://)?)?(?:www\\.)?facebook|fb\\.com/(?:profile.php\?id=)?([0-9]+)',
    twitterUrl: '^((?:http://)?|(?:https://)?)?(?:www\\.)?twitter\\.com/@?(?!home|share|privacy|tos)([A-z0-9_]+)\/?',
    linkedinURL: 'http(s)?:\/\/([w]{3}\.)?linkedin\.com\/in\/([a-zA-Z0-9-]{1,100})\/?',
    expirationDate: '^\d{4}\-\d{2}$'
};
