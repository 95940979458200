import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Capabilities } from '../models/capabilities.model';

@Injectable({
  providedIn: 'root'
})
export class CapabilitiesService {

  constructor(private http: HttpClient) { }

  getCapabilities(data: Capabilities): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.baseUrl}keyValuePairState/providerCapabilitiesSelect/get`, data).pipe(
      map((res: any) => res.body.map((capabilities: Capabilities) => new Capabilities().deserialize(capabilities))));
  }

  getProviderCapabilitiesByProvider(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}providerCapabilitiesController/getProviderCapabilitiesByProvider`, data);
  }

  // Add delete provider capabilities
  addDeleteProviderCapabilities(data: any = {}): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}providerCapabilitiesController/addDeleteProviderCapabilities`, data);
  }
}
