import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.scss']
})
export class AlertBoxComponent implements OnInit {

  message = '';
  navigateTOServiceProvider = environment.serviceProviderURL;

  constructor(
    protected dialogRef: MatDialogRef<AlertBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    if (!!this.data) {
      this.message = this.data.message;
    }
  }

  close() {
    this.dialogRef.close();
  }

  goToServiceProvider() {
    this.close();
    window.open(this.navigateTOServiceProvider, "_blank");
  }

}
