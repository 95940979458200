import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { KVPProviderCapabilities } from '../models/kvp-provider-capabilities.model';

@Injectable({
  providedIn: 'root'
})
export class KVPProviderCapabilitiesService {

  constructor(private http: HttpClient) { }

  getKVPProviderCapabilities(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.baseUrl}providerCapabilitiesController/providerCapabilitiesKVPSelect/get`, data).pipe(
      map((res: any) => res.body.map((capabilities: KVPProviderCapabilities) => new KVPProviderCapabilities().deserialize(capabilities))));
    return this.http.post<any>(`${environment.spApiUrl}keyvaluepairs/providercapabilities/get`, data).pipe(
      map((res: any) => res.body.map((capabilities: KVPProviderCapabilities) => new KVPProviderCapabilities().deserialize(capabilities))));
  }
}
