<ngx-loading [show]="loading"></ngx-loading>
<form [formGroup]="providerAdditionalInfoForm">
    <div class="row mt-2 px-5 mb-5">
        <div class="col">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label for="exampleFormControlFile1">Upload Brochure</label>
                        <label for="files" class="btn btn-info btn-sm text-white p-2 ml-2">
                            Please select an image or PDF file
                        </label>
                        <input id="files" #file class="d-none" type="file" accept=".pdf,image/*"
                            (change)="uploadBrochure(file.files, $event)">
                        <button class="col-12 col-md-8 btn btn-link border border-success ml-2 p-1 word-wrap"
                            [ngClass]="{'p-3': !brochureURL}">
                            <a href="{{providerAdditionalInfoForm.controls.brochureUploadURL.value}}" target="_blank">
                                {{brochureURL}}
                            </a>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="brochureURL">
                <div class="col-12 col-md-12 text-right mb-2">
                    <a href="{{providerAdditionalInfoForm.controls.brochureUploadURL.value}}" target="_blank"
                        class="btn btn-sm common-btn">
                        Download Brochure
                    </a>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-2">
                    <label>
                        Accepts Payment Methods
                    </label>
                </div>
                <div class="col-12 col-md-2">
                    <mat-checkbox formControlName="acceptsMasterCard">
                        <strong>Master Card</strong>
                    </mat-checkbox>
                </div>
                <div class="col-12 col-md-2">
                    <mat-checkbox formControlName="acceptsVisa">
                        <strong>Visa</strong>
                    </mat-checkbox>
                </div>
                <div class="col-12 col-md-2">
                    <mat-checkbox formControlName="acceptsAmEx">
                        <strong>AmEx</strong>
                    </mat-checkbox>
                </div>
                <div class="col-12 col-md-2">
                    <mat-checkbox formControlName="acceptsDiscover">
                        <strong>Discover</strong>
                    </mat-checkbox>
                </div>
                <div class="col-12 col-md-2">
                    <mat-checkbox formControlName="acceptsPersonalChecks">
                        <strong>Personal Checks</strong>
                    </mat-checkbox>
                </div>
                <div class="col-12 offset-md-2 col-md-2">
                    <mat-checkbox formControlName="acceptsBusinessChecks">
                        <strong>Business Checks</strong>
                    </mat-checkbox>
                </div>
                <div class="col-12 col-md-2">
                    <mat-checkbox formControlName="acceptsComCheck">
                        <strong>Com Checks</strong>
                    </mat-checkbox>
                </div>
                <div class="col-12 col-md-2">
                    <mat-checkbox formControlName="acceptsTCheck">
                        <strong>T-Checks</strong>
                    </mat-checkbox>
                </div>
            </div>
            <div class="row mt-4 px-2">
                <label class="col text-center">
                    <h5>Enter Website Links</h5>
                </label>
            </div>
            <div class="row mt-4 px-2">
                <label class="hint-div p-2 pr-3 mb-2 bg-secondary text-white">
                    <span class="icon-width">
                        <img src="./../../../../../../assets/img/lightbulb.svg">
                    </span>
                    Dont forget to include the https://www
                </label>
            </div>
            <div class="row mt-4 px-2 web-input">
                <div class="col-md-6 row form-inline mt-2">
                    <label class="text-success mb-4 col-md-3">Website:
                        <span class="icon-width">
                            <img src="./../../../../../../assets/img/globe.svg">
                        </span>
                    </label>
                    <div class="col-md-9">
                        <input type="url" formControlName="website" class="form-control width-100 m-0"
                            placeholder="Enter Website URL">
                        <small class="text-muted">ex: https://www.mywebsite.com</small>
                        <mat-error class="text-center text-danger error"
                            *ngIf="providerAdditionalInfoForm.controls.website.touched && handleError('website', 'pattern')">
                            Invalid Link
                        </mat-error>
                    </div>
                </div>
                <div class="col-md-6 row form-inline mt-2">
                    <label class="text-success mb-4 col-md-3">Facebook:
                        <span class="icon-width">
                            <img src="./../../../../../../assets/img/facebook.svg">
                        </span>
                    </label>
                    <div class="col-md-9">
                        <input type="url" formControlName="facebook" class="form-control width-100 m-0"
                            placeholder="Enter Facebook URL">
                        <small class="text-muted">ex: https://www.facebook.com/your_name</small>
                        <mat-error class="text-center text-danger error"
                            *ngIf="providerAdditionalInfoForm.controls.facebook.touched && handleError('facebook', 'pattern')">
                            Invalid Link
                        </mat-error>
                    </div>
                </div>
                <div class="col-md-6 row form-inline mt-2">
                    <label class="text-success mb-4 col-md-3">Twitter:
                        <span class="icon-width">
                            <img src="./../../../../../../assets/img/twitter.svg">
                        </span>
                    </label>
                    <div class="col-md-9">
                        <input type="url" formControlName="twitter" class="form-control width-100 m-0"
                            placeholder="Enter Twitter URL">
                        <small class="text-muted">ex: https://www.twitter.com/your_name</small>
                        <mat-error class="text-center text-danger error"
                            *ngIf="providerAdditionalInfoForm.controls.twitter.touched && handleError('twitter', 'pattern')">
                            Invalid Link
                        </mat-error>
                    </div>
                </div>
                <div class="col-md-6 row form-inline mt-2">
                    <label class="text-success mb-4 col-md-3">LinkedIn:
                        <span class="icon-width">
                            <img src="./../../../../../../assets/img/linkedin.svg">
                        </span>
                    </label>
                    <div class="col-md-9">
                        <input type="url" formControlName="linkedIn" class="form-control width-100 m-0"
                            placeholder="Enter LinkedIn URL">
                        <small class="text-muted">ex: https://www.linkedin.com/in/your_name</small>
                        <mat-error class="text-center text-danger error"
                            *ngIf="providerAdditionalInfoForm.controls.linkedIn.touched && handleError('linkedIn', 'pattern')">
                            Invalid Link
                        </mat-error>
                    </div>
                </div>
                <div class="col-md-6 row form-inline mt-2">
                    <label class="text-success mb-4 col-md-3">Instagram:
                        <span class="icon-width">
                            <img src="./../../../../../../assets/img/instagram.svg">
                        </span>
                    </label>
                    <div class="col-md-9">
                        <input type="url" formControlName="instagram" class="form-control width-100 m-0"
                            placeholder="Enter Instagram URL">
                        <small class="text-muted">ex: https://www.instagram.com/your_name</small>
                        <mat-error class="text-center text-danger error"
                            *ngIf="providerAdditionalInfoForm.controls.instagram.touched && handleError('instagram', 'pattern')">
                            Invalid Link
                        </mat-error>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>