import { Deserializable } from './deserializable.model';

export class States implements Deserializable {

    countryKey: string;
    deleted: boolean;
    insertedUserKey: string;
    stateDescription: string;
    stateKey: string;
    systemDefault: boolean;
    updatedUserKey: string;

    deserialize(input: any): any {
        Object.assign(this, input);
        return this;
    }
}
