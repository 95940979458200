import { Deserializable } from '@share';

export class ProviderElectronicDispatchEmail implements Deserializable {
    additionalAddressPK: string;
    deleted: boolean;
    insertedTimestamp: string;
    insertedUserKey: string;
    providerElectronicDispatchEmailKey: string;
    providerPK: string;
    systemDefault: boolean;
    updatedTimestamp: string;
    updatedUserKey: string;

    deserialize(input: any): any {
        Object.assign(this, input);
        return this;
    }
}
