import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProviderCapabilities } from '../models/provider-capabilities.model';

@Injectable({
  providedIn: 'root'
})
export class ProviderCapabilitiesService {

  constructor(private http: HttpClient) { }

  getProviderCapabilities(data): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.baseUrl}providerCapabilitiesController/providerCapabilitiesSelect/get`, data).pipe(
      map((res: any) => res.body.map(
        (providerCapabilities: ProviderCapabilities) => new ProviderCapabilities().deserialize(providerCapabilities))));
  }

  insertProviderCapabilities(data: ProviderCapabilities): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.baseUrl}providerCapabilitiesController/providerCapabilitiesInsert`, data).pipe(
      map((res: any) => res.body.map(
        (providerCapabilities: ProviderCapabilities) => new ProviderCapabilities().deserialize(providerCapabilities))));
  }

  updateProviderCapabilities(data: ProviderCapabilities): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.put<any>(`${environment.baseUrl}providerCapabilitiesController/providerCapabilitiesUpdate`, data);
  }

  deleteProviderCapabilities(filter): Observable<any> {
    return this.http.delete<any>(`${environment.baseUrl}providerCapabilitiesController/providerCapabilitiesDelete/${filter}`);
  }

  copyProviderCapabilitiesToLocation(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}providerCapabilitiesController/copyProviderCapabilitiesToLocation`, data);
  }
}
