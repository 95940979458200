import { Deserializable } from './deserializable.model';

export class Counties implements Deserializable {

  county: string;
  countyKey: string;
  deleted: boolean;
  insertedUserKey: string;
  stateKey: string;
  systemDefault: boolean;
  updatedUserKey: string;
  zoneKey: number;

  deserialize(input: any): any {
    Object.assign(this, input);
    return this;
  }
}
