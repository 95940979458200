import { Deserializable } from './deserializable.model';

export class Users implements Deserializable {

    address: string;
    associationKey: string;
    cellPhone: string;
    city: string;
    contactCenterUserRoleKey: string;
    contactPriority: number;
    deleted: boolean;
    departmentKey: string;
    email: string;
    emergencyProceedAuthorization: boolean;
    firstName: string;
    insertedUserKey: string;
    isApprover: boolean;
    isEmergencyApprover: boolean;
    lastName: string;
    officePhone: string;
    okToTextMsg: boolean;
    outOfOffice: boolean;
    outOfOfficeExpireTimestamp: string;
    password: string;
    profilePictureURL: string;
    stateKey: string;
    supervisor: string;
    title: string;
    updatedUserKey: string;
    userKey: string;
    userStatusKey: string;
    userTypeKey: string;

    deserialize(input: any): any {
        Object.assign(this, input);
        return this;
    }

    prepareUserData(data: any): Users {
        return this.deserialize(data);
    }
}
