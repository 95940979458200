import { Deserializable } from './deserializable.model';

export class KVPProviderCapabilities implements Deserializable {

    deleted: boolean;
    description: string;
    industryKey: string;
    insertedTimestamp: string;
    insertedUserKey: string;
    makeDependent: boolean;
    parentKey: string;
    providerCapabilityKey: string;
    quickReviewRequired: string;
    systemDefault: boolean;
    updatedTimestamp: string;
    updatedUserKey: string;

    deserialize(input: any): any {
        Object.assign(this, input);
        return this;
    }
}
