import { Deserializable } from './deserializable.model';

export class ProviderAdditionalAddresses implements Deserializable {

  additionalAddressPK: string;
  address1: string;
  address2: string;
  address3: string;
  billingEmail: string;
  billingPhone: string;
  city: string;
  deleted: boolean;
  dispatchEmail: string;
  dispatchFax: string;
  dispatchPhone: string;
  hoursClose: string;
  hoursOpen: string;
  insertedUserKey: string;
  name: string;
  performsSameService: boolean;
  postalCode: string;
  providerPK: string;
  state: string;
  systemDefault: boolean;
  updatedUserKey: string;
  countyKey: string;
  zoneKey: number;
  latitude: number;
  longitude: number;
  allLocationsSameHours: boolean;
  isSameCapabilities: boolean;
  tollFreePhone: string;
  businessPhone: string;
  afterHoursPhone: string;
  respondEmailServiceRequest: boolean;
  respondPhoneServiceRequest: boolean;

  deserialize(input: any): any {
    Object.assign(this, input);
    return this;
  }

  prepareSave(data: any): any {
    this.deserialize(data);
    this.systemDefault = false;
    this.deleted = false;
    return this;
  }
}
