import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { patterns } from 'src/assets/patterns';
import { Provider } from '../../models/providers.model';
import { UserInformation } from '../../models/user-Information.model';
import { ProvidersService } from '../../services/providers.service';
import { UserInformationService } from '../../services/user-information.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { UploadToS3Service } from '../../services/upload-to-s3.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-company-other-details',
  templateUrl: './company-other-details.component.html',
  styleUrls: ['./company-other-details.component.scss']
})
export class CompanyOtherDetailsComponent implements OnInit {

  @Output() saveComplete = new EventEmitter<any>();

  providerAdditionalInfoForm: UntypedFormGroup;
  provider: Provider = new Provider();
  userInformation: UserInformation = new UserInformation();
  loading = false;
  brochureURL: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private providersService: ProvidersService,
    private userInformationService: UserInformationService,
    private snackBarService: SnackBarService,
    private uploadToS3Service: UploadToS3Service,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.provider = this.providersService.provider;
    this.userInformation = this.userInformationService.userInformation;
    this.providerAdditionalInfoForm = this.formBuilder.group({
      providerPK: [this.provider.providerPK],
      brochureUploadURL: [this.provider.brochureUploadURL || ''],
      acceptsMasterCard: [this.provider.acceptsMasterCard || ''],
      acceptsVisa: [this.provider.acceptsVisa || ''],
      acceptsAmEx: [this.provider.acceptsAmEx || ''],
      acceptsDiscover: [this.provider.acceptsDiscover || ''],
      acceptsPersonalChecks: [this.provider.acceptsPersonalChecks || ''],
      acceptsBusinessChecks: [this.provider.acceptsBusinessChecks || ''],
      acceptsComCheck: [this.provider.acceptsComCheck || ''],
      acceptsTCheck: [this.provider.acceptsTCheck || ''],
      website: [(this.provider.website || ''), [Validators.pattern(patterns.url)]],
      facebook: [this.provider.facebook || '', [Validators.pattern(patterns.facebookUrl)]],
      twitter: [this.provider.twitter || '', [Validators.pattern(patterns.twitterUrl)]],
      linkedIn: [this.provider.linkedIn || '', [Validators.pattern(patterns.linkedinURL)]],
      instagram: [(this.provider.instagram || ''), [Validators.pattern(patterns.instagramUrl)]],
    });

    this.getProvider();
  }

  getProvider(): void {
    const data = {
      providerPK: this.provider.providerPK
    };
    this.loading = true;
    this.providersService.getProvider(data).subscribe((response) => {
      if (response.length > 0) {
        const brochureUploadURL = response[0].brochureUploadURL ? atob(response[0].brochureUploadURL) : '';
        this.brochureURL = brochureUploadURL ? brochureUploadURL.split('/').pop() : '';
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  uploadBrochure(files, event): boolean {
    const fileData: File = event.target.files[0] as File;
    if (files.length === 0) {
      return false;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null && mimeType.match('application/pdf') == null) {
      this.snackBarService.warning('Only image and PDF formats are supported.');
      return false;
    }

    const size = files[0].size;
    if (Math.round(size / 1024) > 5120) {
      this.snackBarService.warning('Size should not exceed 5MB.');
      return false;
    }

    const extention = fileData.name.split('.');
    const fileName = `Brochure_${this.datePipe.transform(new Date(), 'MM_dd_yyyy_HH_mm_ss')}_ProviderKey_${this.provider.providerPK}.${extention[extention.length - 1]}`;
    const path = 'OtherDocuments/ProviderDocuments/ProviderBroucher';

    const promise = new Promise((resolve) => {
      this.loading = true;
      this.uploadToS3Service.uploadFile(fileData, fileName, path, resolve);
    });

    promise.then((response: any) => {
      this.loading = false;
      if (response) {
        this.snackBarService.success('brochure uploaded successfully');
        this.providerAdditionalInfoForm.controls.brochureUploadURL.setValue(response.Location);
        const arrURL = response.Location.split('/');
        this.brochureURL = arrURL[arrURL.length - 1];
      } else {
        this.snackBarService.error('failed to upload brochure');
      }
    });
  }

  updateProviderDetails(): void {
    if (this.providerAdditionalInfoForm.invalid) {
      this.providerAdditionalInfoForm.markAllAsTouched();
      return;
    }

    this.provider = Object.assign(this.provider, new Provider().prepareSave(this.providerAdditionalInfoForm.value));
    this.provider.updatedUserKey = this.userInformation.userInformationID.toString();
    this.loading = true;
    this.providersService.updateProvider(this.provider).subscribe((result) => {
      this.loading = false;
      this.snackBarService.success(result.message);
      this.providersService.provider = this.provider;
      this.saveComplete.emit(true);
    }, (error) => {
      this.loading = false;
      this.saveComplete.emit(false);
    });
  }

  handleError = (control: string, error: string) => {
    return this.providerAdditionalInfoForm.controls[control].hasError(error);
  }
}
