<ngx-loading [show]="loading"></ngx-loading>
<div class="row" *ngIf="parent || (!parent && !providerAdditionalAddresses.allLocationsSameHours)">
    <div class="col-md-12 mt-2">
        <div class="row">
            <div class="col-md-12 mt-2 text-right">
                <button type="button" class="btn common-btn btn-sm"
                    (click)="updateTo24Hours('12:00 AM', '12:00 AM', 0)">
                    24 Hours Available
                </button>
                <button type="button" class="btn common-btn btn-sm ml-2"
                    (click)="updateTo24Hours('8:00 AM', '5:00 PM', 1)">
                    Closed Weekends
                </button>
            </div>
        </div>

        <div class="table-responsive mt-2">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th *ngFor="let day of Days">{{day}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td *ngFor="let data of weekData">
                            <div *ngIf="!(editDayFlag === data.DaysOfWeekKey)">
                                <div>
                                    <div class="d-inline-block m-w-60" *ngIf="(data.Closed == 0)">
                                        {{ data.OpenTime | differenceInHoursBetweenTwoTimes: data.CloseTime }}
                                    </div>
                                    <div class="d-inline-block m-w-60" *ngIf="(data.Closed == 1)">
                                        Closed
                                    </div>
                                    <button class="btn common-btn btn-sm float-right d-inline-block" nbTooltip="Edit"
                                        (click)="editDay(data)">
                                        <i class="fa fa-pencil"></i>
                                    </button>
                                </div>
                            </div>

                            <div *ngIf="editDayFlag === data.DaysOfWeekKey">
                                <mat-form-field>
                                    <input matInput placeholder="From" [(ngModel)]="openTime"
                                        [ngxTimepicker]="MFromPicker" [value]="data.OpenTime" readonly>
                                    <ngx-material-timepicker #MFromPicker></ngx-material-timepicker>
                                </mat-form-field>
                                <mat-form-field>
                                    <input matInput placeholder="To" [(ngModel)]="closeTime" [ngxTimepicker]="MToPicker"
                                        [value]="data.CloseTime" readonly>
                                    <ngx-material-timepicker #MToPicker></ngx-material-timepicker>
                                </mat-form-field>

                                <button class="btn btn-sm common-btn ml-1" (click)="resetHoursOfOperation()">
                                    24 Hours
                                </button>
                                <button class="btn common-btn btn-sm ml-2 mr-4 float-right"
                                    (click)="closeDayData(editDayFlag)" nbTooltip="Cancel">
                                    <i class="fa fa-times"></i>
                                </button>
                                <button class="btn common-btn btn-sm ml-1 float-right" nbTooltip="Save"
                                    (click)="updateHoursOfOperation(openTime, closeTime)">
                                    <i class="fa fa-check"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="col-12 text-center" *ngIf="(!parent && providerAdditionalAddresses.allLocationsSameHours)">
    <div class="alert alert-info" role="alert">
        Same Hours of Operation
    </div>
</div>