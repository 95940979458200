import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-redirect-user',
  templateUrl: './redirect-user.component.html',
  styleUrls: ['./redirect-user.component.scss']
})
export class RedirectUserComponent implements OnInit {

  constructor(protected ref: MatDialogRef<RedirectUserComponent>) { }

  ngOnInit(): void {
  }

  submit(val): void {
    this.ref.close(val);
  }

}
